import * as sup from 'superstruct';

import { createRouting, defineRoute } from './config/createRouting';

export const anonymousRoutes = createRouting({
  signIn: defineRoute({
    path: '/auth' as const,
  }),
  resetPassword: defineRoute({
    path: '/reset-password' as const,
    queryStruct: sup.object({
      verificationId: sup.string(),
      userId: sup.string(),
    }),
  }),
  customerIdNotFound: defineRoute({
    path: '/customer-id-not-found' as const,
  }),
});
