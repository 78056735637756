// import { useState } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { dark } from '@/modules/theme/utils/colors';

// import { NegotiationExcludedSuppliersDialog } from '../NegotiationSuppliersDialogs/NegotiationExcludedSuppliersDialog';
// import { NegotiationInvitedSuppliersDialog } from '../NegotiationSuppliersDialogs/NegotiationInvitedSuppliersDialog';

type NegotiationInvitedExcludedSuppliersProps = {
  suppliersCount: number;
  excludedCount: number;
  negotiationId: string;
};

const translationNamespace = 'modules/negotiations/NegotiationDataPanel';

export const NegotiationInvitedExcludedSuppliers = ({
  suppliersCount,
  excludedCount, // negotiationId,
}: NegotiationInvitedExcludedSuppliersProps) => {
  // const [isInvitedDialogOpen, setIsInvitedDialogOpen] = useState(false);
  // const [isExcludedDialogOpen, setIsExcludedDialogOpen] = useState(false);

  const invitedSuppliersCount = suppliersCount - excludedCount;

  return (
    <>
      <SuppliersButtonWrapperStyled>
        <Trans
          ns={translationNamespace}
          values={{
            invitedSuppliersCount,
            excludedSuppliersCount: excludedCount,
          }}
          i18nKey={
            '<InvitedSuppliersButton>{{invitedSuppliersCount}} invited</InvitedSuppliersButton> / <ExcludedSuppliersButton>{{excludedSuppliersCount}} excluded</ExcludedSuppliersButton>'
          }
          components={{
            InvitedSuppliersButton: (
              <SuppliersButtonStyled
              // onClick={() => setIsInvitedDialogOpen(true)}
              />
            ),
            ExcludedSuppliersButton: (
              <SuppliersButtonStyled
              // onClick={() => setIsExcludedDialogOpen(true)}
              />
            ),
          }}
        />
      </SuppliersButtonWrapperStyled>
      {/* <NegotiationInvitedSuppliersDialog
        negotiationId={negotiationId}
        isOpen={isInvitedDialogOpen}
        onClose={() => setIsInvitedDialogOpen(false)}
      />
      <NegotiationExcludedSuppliersDialog
        negotiationId={negotiationId}
        isOpen={isExcludedDialogOpen}
        onClose={() => setIsExcludedDialogOpen(false)}
      /> */}
    </>
  );
};

const SuppliersButtonWrapperStyled = styled('span')(
  ({ theme: { spacing } }) => ({
    display: 'inline-block',
    margin: spacing([0.25, 0, 0, 3]),
    color: dark[400],
  })
);

const SuppliersButtonStyled = styled('button')(({ theme: { typography } }) => ({
  outline: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: typography.fonts.body,
  fontSize: typography.fontSize.small,
  padding: 0,
  color: 'inherit',
}));
