import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { SuppliersFilters } from '@/modules/suppliers/components/SuppliersFilters';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { useSetSuppliersQueryParams } from './hooks/useSetSuppliersQueryParams';
import { PlannedNegotiationSuppliersTable } from './PlannedNegotiationSuppliersTable/PlannedNegotiationSuppliersTable';

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));

type SuppliersQueryParams = {
  page?: number;
  size?: number;
  sort?: SuppliersOrder;
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
};

type PlannedNegotiationSuppliersProps = {
  negotiationId: string;
  queryParams: SuppliersQueryParams;
};

export const PlannedNegotiationSuppliers = ({
  negotiationId,
  queryParams,
}: PlannedNegotiationSuppliersProps) => {
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    NegotiationSupplier[]
  >([]);
  const [selectedSupplierCount, setSelectedSupplierCount] = useState(0);

  const searchQuery = useMemo(
    () => queryParams.searchQuery || '',
    [queryParams.searchQuery]
  );
  const order = useMemo(
    () => queryParams.sort || defaultSuppliersQueryParams.sort,
    [queryParams.sort]
  );
  const categoriesFilter = useMemo(
    () => queryParams.category || [],
    [queryParams.category]
  );
  const pageNumber = useMemo(() => queryParams.page || 1, [queryParams.page]);
  const pageSize = useMemo(
    () => queryParams.size || defaultSuppliersQueryParams.size,
    [queryParams.size]
  );

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    isSuccess: isNegotiationSuccess,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });

  const {
    setCategories,
    setOrder,
    setPageNumber,
    setPageSize,
    setSearchQuery,
    debouncedSearchQuery,
  } = useSetSuppliersQueryParams({ searchQuery });

  useEffect(() => {
    if (negotiation && negotiation.attributes.negotiationSuppliers) {
      const suppliers: NegotiationSupplier[] = [];

      // filter suppliers by filterOptions
      negotiation.attributes.negotiationSuppliers
        .filter(
          (supplier) =>
            supplier.attributes.negotiationSupplierState !==
              NegotiationSupplierStateV2.EXCLUDED &&
            supplier.attributes.negotiationSupplierState !==
              NegotiationSupplierStateV2.REMOVED
        )
        .filter((supplier) => {
          if (
            debouncedSearchQuery &&
            !(
              (supplier.attributes.freezedSupplierName ||
                supplier.attributes.supplier?.name) ??
              ''
            )
              .toLowerCase()
              .includes(debouncedSearchQuery.toLocaleLowerCase())
          ) {
            return false;
          }

          if (
            categoriesFilter.length &&
            (!supplier.attributes.supplier?.category ||
              !categoriesFilter.includes(
                supplier.attributes.supplier?.category
              ))
          ) {
            return false;
          }

          suppliers.push(supplier);
        });

      // sort by order option
      suppliers.sort((a, b) => {
        const nameA =
          (a.attributes.freezedSupplierName || a.attributes.supplier?.name) ??
          '';
        const nameB =
          (b.attributes.freezedSupplierName || b.attributes.supplier?.name) ??
          '';
        const categoryA = a.attributes.supplier?.category ?? '';
        const categoryB = b.attributes.supplier?.category ?? '';

        switch (order) {
          case 'name':
            return (
              nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) ||
              0
            );
          case '-name':
            return (
              nameB.localeCompare(nameA, undefined, { sensitivity: 'base' }) ||
              0
            );
          case 'currentSpend':
            return (
              (a.attributes.supplier?.spend || 0) -
              (b.attributes.supplier?.spend || 0)
            );
          case '-currentSpend':
            return (
              (b.attributes.supplier?.spend || 0) -
              (a.attributes.supplier?.spend || 0)
            );
          case 'category.name':
            return categoryA.localeCompare(categoryB, undefined, {
              sensitivity: 'base',
            });
          case '-category.name':
            return categoryB.localeCompare(categoryA, undefined, {
              sensitivity: 'base',
            });

          default:
            return 0;
        }
      });

      setSelectedSupplierCount(suppliers.length);

      // get page of suppliers
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const suppliersPage = suppliers.slice(startIndex, endIndex);

      setSelectedSuppliers(suppliersPage);
    }
  }, [
    categoriesFilter,
    debouncedSearchQuery,
    negotiation,
    negotiation?.attributes.negotiationSuppliers,
    order,
    pageNumber,
    pageSize,
    setSelectedSuppliers,
  ]);

  const paginationProps = {
    perPage: pageSize,
    totalCount: selectedSupplierCount ?? 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  return (
    <Box margins={[5, 8, 7]}>
      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isNegotiationPending}
          searchQuery={searchQuery}
          categoriesFilter={categoriesFilter}
          order={order}
          onSearchQueryChange={setSearchQuery}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />

        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSize}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isNegotiationPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <PlannedNegotiationSuppliersTable
        suppliers={selectedSuppliers}
        negotiationId={negotiationId}
        isSuccess={isNegotiationSuccess}
        pageSize={pageSize}
        isLoading={isNegotiationPending}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isNegotiationPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
    </Box>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 100,
  marginBottom: 0,
});
