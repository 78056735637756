import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { SupplierCategoryFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierCategoryFilter';
import { SupplierSearchFilter } from '@/modules/suppliers/components/SuppliersFilters/SupplierSearchFilter';
import { SuppliersFiltersLayout } from '@/modules/suppliers/components/SuppliersFilters/SuppliersFiltersLayout';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';

type SuppliersFiltersProps = {
  isLoading: boolean;
  searchQuery: string;
  order: string;
  categoriesFilter: string[];
  onSearchQueryChange: (query: string) => void;
  onCategoryChange: (values: string[]) => void;
  onOrderChange: (order: SuppliersOrder) => void;
};

export const SuppliersFilters = ({
  searchQuery,
  order,
  categoriesFilter,
  onSearchQueryChange,
  onOrderChange,
  onCategoryChange,
}: SuppliersFiltersProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const orderOptions: { label: string; value: SuppliersOrder }[] = [
    {
      label: t('Name ascending'),
      value: 'name',
    },
    {
      label: t('Name descending'),
      value: '-name',
    },
    {
      label: t('Spend ascending'),
      value: 'currentSpend',
    },
    {
      label: t('Spend descending'),
      value: '-currentSpend',
    },
    {
      label: t('Category name ascending'),
      value: 'category.name',
    },
    {
      label: t('Category name descending'),
      value: '-category.name',
    },
    {
      label: t('Contact email ascending'),
      value: 'defaultContact.email',
    },
    {
      label: t('Contact email descending'),
      value: '-defaultContact.email',
    },
  ];

  return (
    <SuppliersFiltersLayout>
      <SupplierSearchFilter
        searchQuery={searchQuery}
        onChange={onSearchQueryChange}
      />
      <SelectInput
        name="sortBy"
        label={t('Sort by')}
        options={orderOptions}
        showErrorMessage={false}
        value={findSelectValue({
          currentValue: order,
          options: orderOptions,
        })}
        placeholder={t('Choose...')}
        onChange={(option) => onOrderChange(option?.value || 'name')}
      />
      <SupplierCategoryFilter
        filterValues={categoriesFilter}
        onChange={onCategoryChange}
      />
    </SuppliersFiltersLayout>
  );
};
