import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { PlannedNegotiationMetricsLoader } from '@/pages/PlannedNegotiationPage/components/PlannedNegotiationMetricsLoader';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationSetupMetrics } from './NegotiationSetupMetrics';

type NegotiationSetupDataPanelProps = {
  negotiation: NegotiationResourceTypeV2 | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const NegotiationSetupDataPanel = ({
  shouldDisplayLoader = false,
  negotiation,
}: NegotiationSetupDataPanelProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { customer } = useSessionContext();

  if (!negotiation) {
    return null;
  }

  const metrics = calculateNegotiationMetrics(negotiation);

  return (
    <NegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <PlannedNegotiationMetricsLoader />
          ) : (
            negotiation && (
              <NegotiationSetupMetrics
                metrics={metrics}
                negotiation={negotiation}
              />
            )
          )}
          <SignetStyled />
        </DataContainerStyled>
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          <BottomDataWrapperStyled>
            <NumberOfSuppliersStyled variant="h3" as="span">
              {t('{{count}} suppliers', {
                count: metrics.supplierCountWithExcluded,
              })}
            </NumberOfSuppliersStyled>
            <SpendStyled>
              {t('Spend {{spend}} {{currency}}', {
                spend: formatAmount(metrics.totalSpend ?? 0),
                currency: customer.currency,
              })}
            </SpendStyled>
          </BottomDataWrapperStyled>
        )}
      </PanelContainerStyled>
    </NegotiationDataPanelWrapperStyled>
  );
};
