import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { TextInput } from '@/modules/form/components/TextInput/TextInput';
import { useGetNegotiationKeywordsQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationKeywordsV2Query';
import { useUpdateNegotiationKeywordMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationKeywordMutationV2';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import {
  Heading,
  LabelStyled,
  Paragraph,
} from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { useDebouncedField } from '@/utils/useDebounced';

import { SaveStateIndicator } from './components/SaveStateIndicator';
import {
  EditorSkeletonStyled,
  EditorStyled,
  EditorWrapperStyled,
  FormWrapperStyled,
} from './InvitationLetter.styled';

const messageEditorId = 'message-editor';

const invitationLetterStruct = sup.object({
  invitationLetter: sup.string(),
  subject: sup.string(),
});

export const InvitationLetter = () => {
  const { negotiationId } =
    setupNegotiationRoutes.useGetRouteParams('invitationLetter');
  const { t } = useTranslation('pages/InvitationLetter');

  const [introKeywordId, setIntroKeywordId] = useState<string>();

  const [introSubjectKeyword, setIntroSubjectKeyword] = useState<{
    id: string;
    value: string;
  }>();

  const { data: negotiationKeywords, isPending } =
    useGetNegotiationKeywordsQueryV2({
      negotiationId,
    });

  const {
    mutate: updateNegotiationKeywordMutate,
    isPending: isUpdateNegotiationKeywordPending,
    isSuccess: isUpdateNegotiationKeywordSuccess,
  } = useUpdateNegotiationKeywordMutationV2();

  const formMethods = useForm({
    struct: invitationLetterStruct,
    defaultValues: {
      invitationLetter: '',
      subject: '',
    },
  });

  const { register, reset, watch } = formMethods;
  const currentContent = watch('invitationLetter');
  const currentSubjectContent = watch('subject');

  const onFieldSave = useCallback(
    (debouncedContent: string) => {
      updateNegotiationKeywordMutate({
        negotiationId,
        keywordId: introKeywordId ?? '',
        value: {
          id: introKeywordId ?? '',
          value: debouncedContent,
        },
      });
    },
    [negotiationId, introKeywordId, updateNegotiationKeywordMutate]
  );

  const onSubjectFieldSave = useCallback(
    (debouncedContent: string) => {
      if (
        introSubjectKeyword &&
        debouncedContent !== introSubjectKeyword?.value
      ) {
        updateNegotiationKeywordMutate({
          negotiationId,
          keywordId: introSubjectKeyword.id,
          value: {
            id: introSubjectKeyword.id,
            value: debouncedContent,
          },
        });
      }
    },
    [negotiationId, introSubjectKeyword, updateNegotiationKeywordMutate]
  );

  const { resetDebouncedField } = useDebouncedField({
    currentContent,
    options: { wait: 1000 },
    onFieldSave,
  });

  const { resetDebouncedField: resetSubjectDebouncedField } = useDebouncedField(
    {
      currentContent: currentSubjectContent,
      options: { wait: 1000 },
      onFieldSave: onSubjectFieldSave,
    }
  );

  useEffect(() => {
    if (negotiationKeywords) {
      const invitationLetter = negotiationKeywords.find(
        (keyword) => keyword.name === 'introduction-message'
      );
      const invitationLetterSubject = negotiationKeywords.find(
        (keyword) => keyword.name === 'introduction-message-subject'
      );

      if (invitationLetter) {
        setIntroKeywordId(invitationLetter.id);
        resetDebouncedField(invitationLetter.value);
      }

      if (invitationLetterSubject) {
        setIntroSubjectKeyword(invitationLetterSubject);
        resetSubjectDebouncedField(invitationLetterSubject.value);
      }

      if (invitationLetter || invitationLetterSubject) {
        reset({
          invitationLetter: invitationLetter?.value,
          subject: invitationLetterSubject?.value,
        });
      }
    }
  }, [
    negotiationKeywords,
    reset,
    resetDebouncedField,
    resetSubjectDebouncedField,
  ]);

  if (!introKeywordId) {
    return <>NO KEYWORD SET</>;
  }

  return (
    <>
      <FlexContainer direction="column" gap={3}>
        <Heading variant="h3" as="h2">
          {t('Write your invitation letter')}
        </Heading>
        <Paragraph color={dark[400]}>
          {t(
            "This is where you compose your invitation letter, welcoming your suppliers to the negotiation. You can choose to write it all from scratch or use one of our templates that you can either use as it is or edit it to your liking. When ever you feel satisfied make sure to select “Im done with my invitation letter” below to let the platform know that it's ready."
          )}
        </Paragraph>
        <Paragraph color={dark[400]}>
          {t(
            'When the negotiation has started you can find your invitation letter in the negotiation itself and it will generate premade email files with a fixed preset of supplier recipients. Depending on how many suppliers that are in your negotiation, the amount of email files generated will vary. These email files can then be downloaded and opened with your prefered email client.'
          )}
        </Paragraph>
      </FlexContainer>
      <Box margins={[7, 0, 22.5, 0]}>
        <FormWrapperStyled>
          <FormProvider {...formMethods}>
            <form noValidate>
              <TextInput
                label={t('Cpo letter subject')}
                {...register('subject')}
              />
              <LabelStyled htmlFor={messageEditorId}>
                {t('Message')}
              </LabelStyled>
              <EditorWrapperStyled>
                <EditorStyled
                  id={messageEditorId}
                  loading={isPending}
                  name={register('invitationLetter').name}
                  Skeleton={<EditorSkeletonStyled />}
                >
                  <SaveStateIndicator
                    isUpdating={isUpdateNegotiationKeywordPending}
                    isSaved={isUpdateNegotiationKeywordSuccess}
                  />
                </EditorStyled>
              </EditorWrapperStyled>
            </form>
          </FormProvider>
        </FormWrapperStyled>
      </Box>
    </>
  );
};
