import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { SuggestedNegotiations } from './components/SuggestedNegotiations/SuggestedNegotiations';
import { useGetNegotiationsQueryV2 } from './hooks/useGetNegotiationsV2Query';
import { MetricsWrapperStyled } from './SuggestedNegotiations.styled';

export const SuggestedNegotiationsPage = () => {
  const {
    customer: { id: customerId },
  } = useSessionContext();
  const { isError, isPending } = useGetNegotiationsQueryV2({
    page: 1,
    limit: 100,
    customerId,
    states: [
      NegotiationStateV2.SUGGESTED,
      NegotiationStateV2.DEMO,
      NegotiationStateV2.IN_SETUP,
      NegotiationStateV2.TO_BE_APPROVED,
    ],
  });

  if (isPending) {
    return (
      <Box as="main" pl={4} pr={4} mb={2} mt={16}>
        <MetricsWrapperStyled>
          <SkeletonLoader height={423} />
          <SkeletonLoader height={423} />
        </MetricsWrapperStyled>
      </Box>
    );
  }

  return <SuggestedNegotiations isLoading={isPending} isError={isError} />;
};
