import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationEmlFileQueryParamsV2 } from '../../hooks/useGetNegotiationEmlFileV2Query';
import { getNegotiationEmlFileResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

export const getNegotiationEmlFileQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationEmlFileQueryParamsV2
) => {
  return async () => {
    const result = await jsonApi.get({
      path: getNegotiationEmlFileResourcePathV2(queryParams.negotiationId),
      queryParams,
      responseStruct: getNegotiationEmlFileResponseStructV2,
      axiosConfig: {
        responseType: 'blob',
      },
    });

    return result.data as Blob;
  };
};

const getNegotiationEmlFileResponseStructV2 = sup.object({
  data: sup.any(),
});
