import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiClient } from '@/modules/api/utils/createJsonApiClient';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { exclusionReasonsChartResourceStruct } from './getExclusionReasonsChart';

export const useGetExclusionReasonsChartQuery = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetExclusionReasonsChartQuery');

  return useQuery({
    queryKey: [queryKeys.customer.useGetExclusionReasonsChartQuery],
    queryFn: getExclusionReasonsChart(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to see DigiProc area chart"),
        },
      },
    }),
  });
};

const getExclusionReasonsChartResponseStruct = createResponseStruct({
  data: exclusionReasonsChartResourceStruct,
});

const getExclusionReasonsChart = (jsonApi: JsonApiClient) => {
  return async () => {
    const { data } = await jsonApi.getOne({
      path: '/me/customer/exclusion_reasons_chart',
      responseStruct: getExclusionReasonsChartResponseStruct,
    });

    return data.attributes;
  };
};
