import { useMemo } from 'react';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import useFirebaseRemoteConfigValue from '@/modules/firebase/hooks/useFirebaseRemoteConfigValue';

import { AnalysisDynamicTabs } from './DynamicTabs.interface';

const useAnalysisDynamicTabs = (): AnalysisDynamicTabs | undefined => {
  const { customer } = useSessionContext();
  const analysisDynamicTabs = useFirebaseRemoteConfigValue<AnalysisDynamicTabs>(
    'analysis_offer_dynamic_tabs'
  );

  const isActiveForUser = useMemo(() => {
    if (!analysisDynamicTabs?.active) {
      return false;
    }

    // Enable for all users if enableForCustomerIds is empty
    if (!analysisDynamicTabs.enabledOnlyForSomeCustomers) return true;

    return customer?.id
      ? (analysisDynamicTabs.enabledCustomerIds || []).includes(customer.id)
      : false;
  }, [
    analysisDynamicTabs?.active,
    analysisDynamicTabs?.enabledOnlyForSomeCustomers,
    analysisDynamicTabs?.enabledCustomerIds,
    customer?.id,
  ]);

  return isActiveForUser ? analysisDynamicTabs : undefined;
};

export default useAnalysisDynamicTabs;
