import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { updateNegotiationSupplierResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';
import { UpdateNegotiationSupplierStruct } from '../resources/negotiation/negotiationResourceStructV2';

type UpdateNegotiationSupplierMutationPayload = {
  negotiationId: string;
  negotiationSupplierId: string;
  value: UpdateNegotiationSupplierStruct;
};

export function updateNegotiationSupplierMutationV2(jsonApi: JsonApiV2Client) {
  return async ({
    negotiationId,
    negotiationSupplierId,
    value,
  }: UpdateNegotiationSupplierMutationPayload) => {
    const response = await jsonApi.post({
      path: updateNegotiationSupplierResourcePathV2(negotiationId),
      attributes: {
        negotiationId,
        negotiationSupplierId,
        attributes: value,
      },
    });
    if (!response)
      throw new Error(
        'missing response from `updateNegotiationSupplierMutation`'
      );

    return sup.boolean().mask(response);
  };
}
