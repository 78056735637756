import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { ProgressOfNegotiationSkeleton } from '@/modules/negotiation/components/ProgressOfNegotiation/components/ProgressOfNegotiationSkeleton';
import {
  NegotiationPageQueryParams,
  StartedNegotiationSuppliersList,
} from '@/modules/negotiation/components/StartedNegotiationSuppliersList/StartedNegotiationSuppliersList';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import {
  HeaderButton,
  HeaderButtonLink,
} from '@/modules/theme/components/HeaderButton';
import { PageMeta } from '@/modules/theme/components/PageMeta';

import { ButtonLinksWrapperStyled } from './ActiveNegotiationPage.styled';
import { ActiveNegotiationDataPanel } from './components/ActiveNegotiationDataPanel';

const ProgressOfNegotiation = lazy(
  () =>
    import(
      '@/modules/negotiation/components/ProgressOfNegotiation/ProgressOfNegotiation'
    )
);

type ActiveNegotiationPageProps = {
  queryParams: NegotiationPageQueryParams;
  negotiationId: string;
};

export const ActiveNegotiationPage = ({
  queryParams,
  negotiationId,
}: ActiveNegotiationPageProps) => {
  const { t } = useTranslation('pages/ActiveNegotiationPage');
  const navigate = useNavigate();
  // const isEnBrowser = useDetectEnglishBrowser();

  const { data: negotiation, isPending: isNegotiationPending } =
    useGetNegotiationQueryV2(
      {
        negotiationId,
      },
      {
        refetchOnWindowFocus: true,
        staleTime: 5000,
        refetchInterval: 60_000,
      }
    );

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={negotiation?.attributes.state as NegotiationStateV2}
      isLoading={isNegotiationPending}
      buttonLink={
        <ButtonLinksWrapperStyled>
          {/* Removed for now (we don't want people to download the contactinfo without being a paying customer) */}
          {/* <SupplierExportButton negotiationId={negotiationId} /> */}
          {/* {parsedPreviewUrl && (
            <HeaderButtonLink
              to={parsedPreviewUrl}
              linkType={LinkType.External}
            >
              {t('Suppliers view')}
            </HeaderButtonLink>
          )} */}

          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>

          <HeaderButton onClick={() => navigate(-1)}>{t('Back')}</HeaderButton>
        </ButtonLinksWrapperStyled>
      }
    >
      <PageMeta
        title={t('Active negotiation')}
        description={t('Active negotiation')}
      />
      {negotiation && (
        <ActiveNegotiationDataPanel
          negotiation={negotiation}
          shouldDisplayLoader={isNegotiationPending}
        />
      )}
      <Box paddings={[0, 8, 7]}>
        <Suspense
          fallback={
            <ProgressOfNegotiationSkeleton
              title={t('Progress of the negotiation so far')}
            />
          }
        >
          <ProgressOfNegotiation negotiationId={negotiationId} />
        </Suspense>
        {negotiation && (
          <StartedNegotiationSuppliersList
            negotiation={negotiation}
            queryParams={queryParams}
            isLoading={isNegotiationPending}
            isNegotiationActive
          />
        )}
      </Box>
    </NegotiationLayout>
  );
};
