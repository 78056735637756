import { ReactNode } from 'react';
import SuperTokens from 'supertokens-auth-react';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';

import { appConfig } from '@/modules/app/appConfig';

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
    appName: 'DigiProc',
    apiDomain: appConfig.api.url,
    websiteDomain: appConfig.preview.appBaseUrl,
    apiBasePath: '/v2/auth',
  },
  disableAuthRoute: true,
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        signUpForm: {
          formFields: [
            {
              id: 'name',
              label: 'Full name',
              placeholder: 'First name and last name',
            },
            {
              id: 'token',
              label: 'Registration token',
              getDefaultValue: () => {
                const urlParams = new URLSearchParams(window.location.search);
                return urlParams.get('token') || '';
              },
              inputComponent: ({ value, name, onChange }) => (
                <div data-supertokens="inputContainer">
                  <div data-supertokens="inputWrapper">
                    <input
                      type="text"
                      className="supertokens-input"
                      data-supertokens="input input-token"
                      value={value}
                      name={name}
                      onChange={(e) => onChange(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
              ),
            },
          ],
        },
      },
    }),
    Session.init({
      tokenTransferMethod: 'header',
    }),
  ],
  style: `
    [data-supertokens~=container] {
      --palette-textTitle: 35, 75, 103;
      --palette-textLabel: 35, 75, 103;
      --palette-textPrimary: 35, 75, 103;
      --palette-error: 255, 76, 82;
      --palette-textLink: 27, 90, 124;
      --palette-primary: 220, 132, 10;
    }
    [data-supertokens~=container] {
      font-family: 'Inter';
      letter-spacing: 0px;
    }
    [data-supertokens~=input] {
      font-family: 'Inter';
      letter-spacing: 0px;
      padding-left: 8px;
    }
    [data-supertokens~=headerTitle] {
      font-family: 'Sora';
    }
    [data-supertokens~=superTokensBranding] {
      display: none;
    }
    [data-supertokens~=button] {
      min-height: 56px;
    }
  `,
});

export interface SuperTokensProvidersProps {
  children: ReactNode;
}

export function SuperTokensProviders({ children }: SuperTokensProvidersProps) {
  return <SuperTokensWrapper>{children}</SuperTokensWrapper>;
}
