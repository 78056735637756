import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationContractQueryV2 } from '@/modules/negotiation/api/queries/getNegotiationContractQueryV2';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export type GetNegotiationContractQueryParamsV2 = {
  negotiationId?: string;
};

export const useGetNegotiationContractV2Query = (
  params: GetNegotiationContractQueryParamsV2
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetNegotiationQuery');
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationContactV2,
      params.negotiationId,
    ],
    enabled: !!params.negotiationId,
    queryFn: getNegotiationContractQueryV2(jsonApi, params),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiation"),
        },
      },
    }),
  });
};
