import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { updateNegotiationKeywordMutationV2 } from '../api/mutations/updateNegotiationKeywordMutationV2';

export const useUpdateNegotiationKeywordMutationV2 = () => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useUpdateNegotiationMutation');

  return useMutation({
    mutationFn: updateNegotiationKeywordMutationV2(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to update this negotiation"),
        },
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator.'
          ),
        },
      },
    }),
  });
};
