import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import {
  NegotiationTermRole,
  NegotiationStateV2,
  NegotiationType,
} from '@/modules/negotiation/types/negotiationTypes';

import {
  applyVisitedAndRegisteredMetrics,
  negotiationCardTermMatrix,
} from './helpers';

// docs: https://docs.google.com/spreadsheets/d/1J5C81mDVt4Q5OHAckXWWO4y0DRIX_RVaHMr1J_X4zmA/edit#gid=730626659

const negotiationTypeToTermRoles = {
  [NegotiationType.IMPROVED_PRICES]: [NegotiationTermRole.OFFER_DISCOUNT],
  [NegotiationType.LOCKED_PRICES]: [NegotiationTermRole.LOCK_PRICE],
  [NegotiationType.EXTRA_PAYMENT_DAYS]: [
    NegotiationTermRole.EXTEND_INVOICE_DUE_DATE,
  ],
  [NegotiationType.STANDARD_AGREEMENT]: [NegotiationTermRole.ANSWER_QUESTION],
  [NegotiationType.CODE_OF_CONDUCT]: [NegotiationTermRole.SIGN_CODE_OF_CONDUCT],
  [NegotiationType.SUPPLIER_QUESTIONNAIRE]: [
    NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION,
  ],
  [NegotiationType.ISO]: [NegotiationTermRole.BE_ISO_CERTIFIED],
  [NegotiationType.SUPPLIER_REGISTRATION]: [NegotiationTermRole.NONE],
  [NegotiationType.PROLONG]: [NegotiationTermRole.EXTEND_CONTRACT_PERIOD],
};

function mapTermRolesToMetricsV2(types: NegotiationType[]) {
  const termRoles = types.flatMap((type) => negotiationTypeToTermRoles[type]);

  const calculationMetrics = termRoles.flatMap(
    (role) => negotiationCardTermMatrix[role].financialMetricsAfterStart
  );
  const termCounterMetrics = termRoles.flatMap(
    (role) => negotiationCardTermMatrix[role].termCounters
  );

  return new Set(
    applyVisitedAndRegisteredMetrics([
      ...calculationMetrics,
      ...termCounterMetrics,
    ])
  );
}

export const getNegotiationCardMetricsV2 = (
  negotiation: NegotiationResourceTypeV2
) => {
  const {
    attributes: { state, type },
  } = negotiation;

  const hasNegotiationStarted =
    state === NegotiationStateV2.COMPLETED ||
    state === NegotiationStateV2.IN_PROGRESS;

  // we only care about roles that are related to negotiation types
  const isStandAlone = type.length <= 1;

  const hasLockPrice = type.includes(NegotiationType.LOCKED_PRICES);
  const hasOfferDiscount = type.includes(NegotiationType.IMPROVED_PRICES);

  if (isStandAlone) {
    return mapTermRolesToMetricsV2(type as NegotiationType[]);
  }

  // is savings AND anti-inflation
  if (hasLockPrice && hasOfferDiscount) {
    const metrics = mapTermRolesToMetricsV2(type as NegotiationType[]);
    // edge-case: we don't want to show `Discounts` due to business needs
    metrics.delete(negotiationMetrics.discountCount);
    return metrics;
  }

  if (hasLockPrice) {
    return mapTermRolesToMetricsV2(type as NegotiationType[]);
  }

  if (hasOfferDiscount) {
    const metrics = mapTermRolesToMetricsV2(type as NegotiationType[]);
    // edge-case: we don't want to show `Discounts` due to business needs
    metrics.delete(negotiationMetrics.discountCount);
    return metrics;
  }

  const termRoles = type.flatMap(
    (type) => negotiationTypeToTermRoles[type as NegotiationType]
  );
  // multiple terms without savings or anti-inflation
  const termCounterMetrics = termRoles.flatMap(
    (role) => negotiationCardTermMatrix[role].termCounters
  );
  return hasNegotiationStarted
    ? new Set(applyVisitedAndRegisteredMetrics(termCounterMetrics))
    : new Set([negotiationMetrics.contactsCount]);
};
