import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { calculateDaysCountToGivenDate } from '@/modules/negotiation/utils/dateUtils';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { PageMeta } from '@/modules/theme/components/PageMeta';

import { PlannedNegotiationDataPanel } from './components/PlannedNegotiationDataPanel';
import { useTabItems } from './hooks/useTabItems';
import {
  ButtonLinksWrapperStyled,
  ParagraphStyled,
  ParagraphSkeletonLoaderStyled,
  TabsStyled,
  ParagraphSkeletonWrapperStyled,
} from './PlannedNegotiationPage.styled';

export const PlannedNegotiationPage = () => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  // const isEnBrowser = useDetectEnglishBrowser();

  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('plannedNegotiation');

  const { data: negotiation, isPending: isNegotiationPending } =
    useGetNegotiationQueryV2({
      negotiationId,
    });

  const tabs = useTabItems();

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={
        negotiation?.attributes.state as unknown as NegotiationStateV2
      }
      isLoading={isNegotiationPending}
      buttonLink={
        <ButtonLinksWrapperStyled>
          {/* Removed for now (we don't want people to download the contactinfo without being a paying customer) */}
          {/* <SupplierExportButton negotiationId={negotiationId} /> */}
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>
          {/* {parsedPreviewUrl && (
            <HeaderButtonLink
              to={parsedPreviewUrl}
              linkType={LinkType.External}
            >
              {t('Suppliers view')}
            </HeaderButtonLink>
          )} */}
          <HeaderButtonLink to={onboardedRoutes.buildUrl('dashboard')}>
            {t('Back')}
          </HeaderButtonLink>
        </ButtonLinksWrapperStyled>
      }
    >
      <>
        <PageMeta
          title={t('Planned negotiation')}
          description={t('Planned negotiation details page')}
        />
        {isNegotiationPending ? (
          <ParagraphSkeletonWrapperStyled>
            <ParagraphSkeletonLoaderStyled />
          </ParagraphSkeletonWrapperStyled>
        ) : (
          negotiation?.attributes.startDate && (
            <ParagraphStyled>
              {t('Starts in {{daysToStartCount}} days', {
                daysToStartCount: calculateDaysCountToGivenDate(
                  negotiation?.attributes.startDate
                ),
              })}
            </ParagraphStyled>
          )
        )}
        <PlannedNegotiationDataPanel
          negotiation={negotiation}
          shouldDisplayLoader={isNegotiationPending}
        />
        <TabsStyled tabs={tabs} />
        <Outlet />
      </>
    </NegotiationLayout>
  );
};
