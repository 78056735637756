import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';

import { getNegotiationTypeColor } from '../../utils/getNegotiationTypeColor';

import { BadgeStyled } from './NegotiationStatusBadge.styled';

type NegotiationStatusBadgeProps = {
  variant: NegotiationStateV2;
  children: string | number;
  width?: number | 'auto';
};

export const NegotiationStatusBadge = ({
  variant,
  children,
  width = 'auto',
}: NegotiationStatusBadgeProps) => {
  return (
    <BadgeStyled color={getNegotiationTypeColor(variant)} width={width}>
      {children}
    </BadgeStyled>
  );
};
