import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { NegotiationContractTerm } from '@/modules/negotiation/api/resources/negotiation/negotiationContractResourceStructV2';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';

import { NegotiationTerm } from './NegotiationTerm';

type NegotiationTermsProps = {
  isLoading: boolean;
  terms: NegotiationContractTerm[];
};

export const NegotiationTerms = ({
  isLoading,
  terms,
}: NegotiationTermsProps) => {
  const { t } = useTranslation('pages/Terms');

  if (isLoading) {
    return <SkeletonLoaderStyled height={88} lines={5} />;
  }

  if (terms?.length) {
    return (
      <>
        {terms.map((term) => (
          <NegotiationTerm term={term} key={term.term.id} />
        ))}
      </>
    );
  }

  return (
    <FlexContainer justify="center">
      <Heading variant="h3" color={dark[400]}>
        {t('There are no terms in this negotiation')}
      </Heading>
    </FlexContainer>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  marginBottom: 0,
});
