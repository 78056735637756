import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { formatAmount } from '@/utils/stringUtils';

import { PlannedNegotiationMetrics } from './PlannedNegotiationMetrics';
import { PlannedNegotiationMetricsLoader } from './PlannedNegotiationMetricsLoader';

type PlannedNegotiationDataPanelProps = {
  negotiation: NegotiationResourceTypeV2 | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const PlannedNegotiationDataPanel = ({
  negotiation,
  shouldDisplayLoader = false,
}: PlannedNegotiationDataPanelProps) => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  const { customer } = useSessionContext();

  const metrics = negotiation
    ? calculateNegotiationMetrics(negotiation)
    : undefined;

  return (
    <PlannedNegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <PlannedNegotiationMetricsLoader />
          ) : (
            metrics && <PlannedNegotiationMetrics metrics={metrics} />
          )}
          <SignetStyled />
        </DataContainerStyled>
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          <BottomDataWrapperStyled>
            <NumberOfSuppliersStyled variant="h3" as="span">
              {t('{{count}} suppliers', {
                count: metrics?.supplierCountWithExcluded,
              })}
            </NumberOfSuppliersStyled>
            <SpendStyled>
              {t('Spend {{spend}} {{currency}}', {
                spend: negotiation && formatAmount(metrics?.totalSpend || 0),
                currency: customer.currency,
              })}
            </SpendStyled>
          </BottomDataWrapperStyled>
        )}
      </PanelContainerStyled>
    </PlannedNegotiationDataPanelWrapperStyled>
  );
};

const PlannedNegotiationDataPanelWrapperStyled = styled(
  NegotiationDataPanelWrapperStyled
)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));
