import { useTranslation } from 'react-i18next';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

import { useGetNegotiationContractV2Query } from '../../hooks/useGetNegotiationContractV2Query';
import { useGetNegotiationQueryV2 } from '../../hooks/useGetNegotiationV2Query';

import { NegotiationTerm } from './components/NegotiationTerm';
import {
  NoTermsInfoHeadingStyled,
  SectionStyled,
  SkeletonLoaderStyled,
} from './NegotiationTerms.styled';

type NegotiationTermsProps = {
  negotiationId: string;
};

export const NegotiationTerms = ({ negotiationId }: NegotiationTermsProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationTerms');

  const { data: negotiation, isPending: isNegotiationLoading } =
    useGetNegotiationQueryV2({
      negotiationId,
    });

  const { data: contract, isPending: isPendingContract } =
    useGetNegotiationContractV2Query({
      negotiationId: negotiation?.id,
    });

  if (isNegotiationLoading || isPendingContract) {
    return (
      <>
        <SkeletonLoaderStyled />
        <SkeletonLoader lines={3} />
      </>
    );
  }

  if (!contract?.attributes.terms?.length) {
    return (
      <NoTermsInfoHeadingStyled variant="h3" as="h2">
        {t('There are no terms for this negotiation')}
      </NoTermsInfoHeadingStyled>
    );
  }

  return (
    <SectionStyled>
      <div>
        <Heading variant="h3" as="h2">
          {t('Terms')}
        </Heading>
        {contract?.attributes.terms.map((term) => (
          <NegotiationTerm term={term} key={term.term.id} />
        ))}
      </div>
    </SectionStyled>
  );
};
