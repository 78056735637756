import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { NegotiationTermMatrix } from '@/modules/negotiation/metrics/types';
import {
  NegotiationTermRole,
  ParsedNegotiationItem,
} from '@/modules/negotiation/types/negotiationTypes';

const emptyMetrics = {
  financialMetricsBeforeStart: [],
  financialMetricsAfterStart: [],
  termCounters: [],
};

export const negotiationCardTermMatrix: NegotiationTermMatrix = {
  [NegotiationTermRole.OFFER_DISCOUNT]: {
    financialMetricsBeforeStart: [
      negotiationMetrics.currentSpend,
      negotiationMetrics.potentialSavings,
    ],
    financialMetricsAfterStart: [
      negotiationMetrics.negotiatedSavings,
      negotiationMetrics.averageDiscount,
    ],
    termCounters: [negotiationMetrics.discountCount],
  },
  [NegotiationTermRole.SIGN_CODE_OF_CONDUCT]: {
    financialMetricsBeforeStart: [],
    financialMetricsAfterStart: [],
    termCounters: [negotiationMetrics.acceptedTermCounts.signCodeOfConduct],
  },
  [NegotiationTermRole.ANSWER_SUSTAINABILITY_QUESTION]: {
    financialMetricsBeforeStart: [],
    financialMetricsAfterStart: [],
    termCounters: [
      negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion,
    ],
  },
  [NegotiationTermRole.LOCK_PRICE]: {
    financialMetricsBeforeStart: [
      negotiationMetrics.currentSpend,
      negotiationMetrics.potentialCostAvoidance,
    ],
    financialMetricsAfterStart: [negotiationMetrics.negotiatedCostAvoidance],
    termCounters: [negotiationMetrics.acceptedTermCounts.lockPrice],
  },
  [NegotiationTermRole.EXTEND_INVOICE_DUE_DATE]: {
    financialMetricsBeforeStart: [negotiationMetrics.currentSpend],
    financialMetricsAfterStart: [],
    termCounters: [negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate],
  },
  [NegotiationTermRole.EXTEND_CONTRACT_PERIOD]: {
    financialMetricsBeforeStart: [
      negotiationMetrics.currentSpend,
      negotiationMetrics.potentialSavings,
    ],
    financialMetricsAfterStart: [],
    termCounters: [negotiationMetrics.acceptedTermCounts.extendContractPeriod],
  },
  [NegotiationTermRole.ACCEPT_CONTRACT_PERIOD]: emptyMetrics,
  [NegotiationTermRole.SELECT_DISCOUNT_IMPLEMENTATION]: emptyMetrics,
  [NegotiationTermRole.ANSWER_QUESTION]: emptyMetrics,
  [NegotiationTermRole.TEXT]: emptyMetrics,
  [NegotiationTermRole.SIGN_STANDARD_AGREEMENT]: emptyMetrics,
  [NegotiationTermRole.BE_ISO_CERTIFIED]: emptyMetrics,
  [NegotiationTermRole.NONE]: emptyMetrics,
} as const;

export const applyVisitedAndRegisteredMetrics = (metrics: string[]) => {
  if (metrics.length <= 2) {
    return [
      ...metrics,
      negotiationMetrics.registeredCount,
      negotiationMetrics.visitedCount,
    ];
  }
  if (metrics.length <= 3) {
    return [...metrics, negotiationMetrics.registeredCount];
  }
  return metrics;
};

export function mapTermRolesToMetrics({
  termRoles,
  hasNegotiationStarted,
}: {
  termRoles: ParsedNegotiationItem['termRoles'];
  hasNegotiationStarted: boolean;
}) {
  if (hasNegotiationStarted) {
    const calculationMetrics = termRoles.flatMap(
      (role) => negotiationCardTermMatrix[role].financialMetricsAfterStart
    );
    const termCounterMetrics = termRoles.flatMap(
      (role) => negotiationCardTermMatrix[role].termCounters
    );

    return new Set(
      applyVisitedAndRegisteredMetrics([
        ...calculationMetrics,
        ...termCounterMetrics,
      ])
    );
  }

  return new Set([
    ...termRoles.flatMap(
      (role) => negotiationCardTermMatrix[role].financialMetricsBeforeStart
    ),
    negotiationMetrics.contactsCount,
  ]);
}
