import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { ReactComponent as MinusIcon } from '@/assets/icons/minusIcon.svg';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import {
  ActionButton,
  ActionButtonLink,
} from '@/modules/theme/components/Table/ActionButton.styled';
import { ActionTableCell } from '@/modules/theme/components/Table/ActionTableCell.styled';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Heading, Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { RemoveSupplierDialog } from './components/RemoveSupplierDialog';

type PlannedNegotiationSuppliersTableProps = {
  suppliers: NegotiationSupplier[];
  isLoading: boolean;
  isSuccess: boolean;
  pageSize: number;
  negotiationId: string;
};

export const PlannedNegotiationSuppliersTable = ({
  suppliers,
  pageSize,
  isLoading,
  isSuccess,
  negotiationId,
}: PlannedNegotiationSuppliersTableProps) => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  const { customer } = useSessionContext();
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(
    null
  );

  const headers = {
    company: t('Company & spend'),
    email: t('Email'),
    category: t('Category'),
    emptyColumn: '',
  } as const;

  if (isLoading) {
    return (
      <Table headers={Object.values(headers)}>
        <TableSkeletonLoader rows={pageSize} height={84} />
      </Table>
    );
  }

  if (suppliers.length === 0 && isSuccess) {
    return (
      <NoSuppliersInfoStyled as="p">{t('No results')}</NoSuppliersInfoStyled>
    );
  }

  return (
    <>
      <Table headers={Object.values(headers)}>
        <>
          {suppliers.map(({ id, attributes }) => {
            if (!attributes || !attributes.supplier) {
              return null;
            }
            const {
              freezedSupplierName,
              freezedSupplierEmail,
              supplier: {
                id: supplierId,
                name,
                spend,
                category,
                defaultContact,
              },
            } = attributes;
            return (
              <TableRow key={id}>
                <TableCell mobileHeader={headers.company}>
                  <FlexContainer direction="column" gap={0.5}>
                    <Text bold>{freezedSupplierName || name}</Text>
                    <Text>
                      {formatAmount(spend || 0)}
                      {customer.currency}
                    </Text>
                  </FlexContainer>
                </TableCell>
                <TableCell mobileHeader={headers.email}>
                  <Text>{freezedSupplierEmail || defaultContact?.email}</Text>
                </TableCell>
                <TableCell mobileHeader={headers.category}>
                  <Text>{category}</Text>
                </TableCell>
                <ActionTableCell>
                  <ActionButtonLink
                    to={onboardedRoutes.buildUrl('editSupplier', {
                      params: {
                        supplierId,
                      },
                    })}
                  >
                    <EditIcon />
                  </ActionButtonLink>
                  <ActionButton onClick={() => setSelectedSupplierId(id)}>
                    <MinusIcon />
                  </ActionButton>
                </ActionTableCell>
              </TableRow>
            );
          })}
        </>
      </Table>
      {selectedSupplierId && (
        <RemoveSupplierDialog
          negotiationId={negotiationId}
          supplierId={selectedSupplierId}
          onClose={() => setSelectedSupplierId(null)}
        />
      )}
    </>
  );
};

const NoSuppliersInfoStyled = styled(Heading)(
  ({ theme: { palette, typography, spacing } }) => ({
    margin: `${spacing(3)} auto`,
    textAlign: 'center',
    color: palette.disabled.main,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSize.large,
  })
);
