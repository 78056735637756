import { ReactNode } from 'react';

import { AuthBackground } from '../common/AuthBackground';

import {
  CardWrapperStyled,
  HeroSectionStyled,
  LogoHorizontalWhiteStyled,
} from './AuthContentLayout.styled';
import { AuthContentLayoutMode } from './types';

type AuthContentLayoutProps = {
  children: ReactNode;
  title?: string;
  mode?: AuthContentLayoutMode;
};

export const AuthContentLayout = ({
  children,
  mode = 'default',
}: AuthContentLayoutProps) => {
  return (
    <AuthBackground>
      <HeroSectionStyled mode={mode}>
        <LogoHorizontalWhiteStyled />
        <CardWrapperStyled>{children}</CardWrapperStyled>
      </HeroSectionStyled>
    </AuthBackground>
  );
};
