import { useTranslation } from 'react-i18next';

import { Paragraph } from '@/modules/theme/components/Typography';
import { mint } from '@/modules/theme/utils/colors';

import { NegotiationType } from '../../types/negotiationTypes';

import {
  AddOnsListStyled,
  NegotiationTermsPanelWrapperStyled,
  AddOnStyled,
} from './NegotiationTermsPanel.styled';

type NegotiationTermsPanelProps = {
  negotiationType: NegotiationType[];
};

export const NegotiationTermsPanel = ({
  negotiationType,
}: NegotiationTermsPanelProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationDataPanel');

  const isCodeOfConductType = negotiationType?.includes(
    NegotiationType.CODE_OF_CONDUCT
  );

  const isSustainabilityType = negotiationType?.includes(
    NegotiationType.SUPPLIER_QUESTIONNAIRE
  );

  const isPaymentDaysType = negotiationType?.includes(
    NegotiationType.EXTRA_PAYMENT_DAYS
  );

  if (!isCodeOfConductType && !isSustainabilityType && !isPaymentDaysType) {
    return <></>;
  }

  return (
    <NegotiationTermsPanelWrapperStyled>
      <Paragraph variant="contentBold" as="h4">
        {t('Add ons +')}
      </Paragraph>
      <AddOnsListStyled>
        {isCodeOfConductType && (
          <AddOnStyled>{t('Code of Conduct')}</AddOnStyled>
        )}
        {isPaymentDaysType && <AddOnStyled>{t('Payment days')}</AddOnStyled>}
        {isSustainabilityType && (
          <AddOnStyled color={mint[800]}>
            {t('Supplier questionnaire')}
          </AddOnStyled>
        )}
      </AddOnsListStyled>
    </NegotiationTermsPanelWrapperStyled>
  );
};
