import { createContext } from 'react';

import { AuthContext } from './types';

export const authContext = createContext<AuthContext>({
  user: null,
  customer: null,
  isAuthenticating: false,
  signOut: notImplemented,
});

function notImplemented(): never {
  throw new Error('Missing AuthContextProvider');
}
