import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from '@/assets/icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/minusIcon.svg';
import { queryKeys } from '@/modules/api/queryKeys';
import { useUpdateNegotiationSupplierMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationSupplierMutationV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Loader } from '@/modules/theme/components/Loader';
import {
  ActionButton,
  ActionButtonLink,
} from '@/modules/theme/components/Table/ActionButton.styled';

import { ActionTableCellStyled } from './SuppliersActionTableCell.styled';
import { WarningTooltip } from './WarningTooltip';

type SuppliersActionTableCellProps = {
  negotiationId: string;
  displayWarning: boolean;
  supplierId: string;
  negotiationSupplierId: string;
};

export const SuppliersActionTableCell = ({
  negotiationId,
  displayWarning,
  supplierId,
  negotiationSupplierId,
}: SuppliersActionTableCellProps) => {
  const { t } = useTranslation('pages/SuppliersTab');
  const queryClient = useQueryClient();

  const { mutate: updateNegotiationSupplier, isPending } =
    useUpdateNegotiationSupplierMutationV2();

  return (
    <ActionTableCellStyled warning={displayWarning}>
      <ActionButtonLink
        disabled={isPending}
        to={onboardedRoutes.buildUrl('editSupplier', {
          params: {
            supplierId,
          },
        })}
      >
        <EditIcon />
      </ActionButtonLink>
      <ActionButton
        disabled={isPending}
        onClick={() => {
          updateNegotiationSupplier(
            {
              negotiationId,
              negotiationSupplierId,
              value: {
                negotiationSupplierState: NegotiationSupplierStateV2.REMOVED,
              },
            },
            {
              onSuccess: async () => {
                await queryClient.invalidateQueries({
                  queryKey: [
                    queryKeys.negotiations.getNegotiationV2,
                    negotiationId,
                  ],
                });
              },
            }
          );
        }}
      >
        {isPending ? <Loader size={24} /> : <DeleteIcon />}
      </ActionButton>
      {displayWarning && (
        <WarningTooltip
          content={t(
            'This supplier is included in another ongoing or planned negotiation.'
          )}
        />
      )}
    </ActionTableCellStyled>
  );
};
