import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { notify } from '@/modules/notify/notify';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';

import { useGetNegotiationsQueryV2 } from '../AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/hooks/useGetNegotiationsV2Query';

import { DashboardPage } from './DashboardPage';

const getNegotiationStateCounts = (
  negotiations?: NegotiationResourceTypeV2[]
): Record<NegotiationStateV2, number> | undefined => {
  if (!negotiations) {
    return undefined;
  }
  return negotiations
    .filter(
      (item) =>
        !(
          (item.attributes.state === NegotiationStateV2.DEMO ||
            item.attributes.state === NegotiationStateV2.SUGGESTED) &&
          (item.attributes.suggestedPeriod === undefined ||
            item.attributes.suggestedPeriod < 0)
        )
    )
    .reduce(
      (acc: Record<NegotiationStateV2, number>, negotiation) => {
        acc[negotiation.attributes.state as NegotiationStateV2] =
          (acc[negotiation.attributes.state as NegotiationStateV2] || 0) + 1;
        return acc;
      },
      {} as Record<NegotiationStateV2, number>
    );
};

export const DashboardScene = () => {
  const { t } = useTranslation('pages/DashboardPage');
  const { queryParams, queryParamsError } =
    onboardedRoutes.useGetQueryParams('dashboard');
  const {
    customer: { id: customerId },
  } = useSessionContext();

  const setSearchParams = onboardedRoutes.useSetQueryParams('dashboard');

  const setNegotiationState = (negotiationState: NegotiationStateV2[]) => {
    setSearchParams({
      state: negotiationState.length > 0 ? negotiationState : undefined,
    });
  };

  useEffect(() => {
    if (queryParamsError) {
      notify.alert.error(t('Invalid negotiation states'));
    }
  }, [queryParamsError, t]);

  const { data } = useGetNegotiationsQueryV2({
    page: 1,
    limit: 100,
    customerId,
  });

  if (queryParamsError) {
    return <Navigate to={onboardedRoutes.buildUrl('dashboard')} />;
  }

  return (
    <DashboardPage
      chosenNegotiationStates={queryParams.state ?? []}
      setNegotiationState={setNegotiationState}
      negotiationStateCounts={getNegotiationStateCounts(data?.data.items)}
    />
  );
};
