import { useTranslation } from 'react-i18next';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { getRoutingComponent } from 'supertokens-auth-react/ui';

import { AuthContentLayout } from '@/layouts/AuthContentLayout/AuthContentLayout';
import { PageMeta } from '@/modules/theme/components/PageMeta';

export function AuthPage() {
  const { t } = useTranslation('pages/SignInPage');

  return (
    <AuthContentLayout>
      <PageMeta title={t('Sign in')} description={t('Sign in page')} />
      {getRoutingComponent([EmailPasswordPreBuiltUI])}
    </AuthContentLayout>
  );
}
