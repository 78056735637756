import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { changePasswordMutation } from '@/modules/auth/api/mutations/changePasswordMutation';
import { notify } from '@/modules/notify/notify';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useChangePasswordMutation = () => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useChangePasswordMutation');

  return useMutation({
    mutationKey: [changePasswordMutation.name],
    mutationFn: changePasswordMutation(jsonApi),
    onSuccess: () => {
      notify.alert.success(t('Your password has been changed successfully'));
    },
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to change password"),
        },
        422: {
          INVALID_CURRENT_PASSWORD: t('Incorrect current password'),
        },
      },
    }),
  });
};
