import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { mutationKeys } from '@/modules/api/mutationKeys';
import { queryKeys } from '@/modules/api/queryKeys';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { Heading } from '@/modules/theme/components/Typography';

import { SelectedSuppliersMetrics } from './components/SelectedSuppliersMetrics/SelectedSuppliersMetrics';
import { SuppliersTable } from './components/SuppliersTable/SuppliersTable';

type SelectedSuppliersDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  negotiationId: string;
};

type InvalidationState = {
  shouldRevalidateDialogMetrics: boolean;
  shouldRevalidateNegotiationsList: boolean;
};

export const SelectedSuppliersDialog = ({
  negotiationId,
  isOpen,
  onClose,
}: SelectedSuppliersDialogProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const queryClient = useQueryClient();
  const [invalidationState, setInvalidationState] = useState<InvalidationState>(
    {
      shouldRevalidateDialogMetrics: false,
      shouldRevalidateNegotiationsList: false,
    }
  );

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    isError: isNegotiationError,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });

  const handleOnClose = () => {
    if (invalidationState.shouldRevalidateNegotiationsList) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getSuggestedNegotiationMetrics],
      });
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiations],
      });
    }
    onClose();
  };

  // React Query doesn't inform mutation hooks like query hooks
  // about pending requests, but they provide `useIsMutating`.
  const isAddingSupplier = Boolean(
    useIsMutating({
      mutationKey: [mutationKeys.suppliers.addSelectedSuppliersToNegotiation],
    })
  );
  const isRemovingSupplier = Boolean(
    useIsMutating({
      mutationKey: [
        mutationKeys.suppliers.removeSelectedSuppliersFromNegotiation,
      ],
    })
  );
  const areMutationsPending = isAddingSupplier || isRemovingSupplier;

  // const shouldDisplayMetricsLoader =
  //   isNegotiationPending ||
  //   areMutationsPending ||
  //   invalidationState.shouldRevalidateDialogMetrics;

  useEffect(() => {
    if (areMutationsPending) {
      setInvalidationState({
        shouldRevalidateNegotiationsList: true,
        shouldRevalidateDialogMetrics: true,
      });
    }
  }, [areMutationsPending]);

  useEffect(() => {
    const invalidateMetricsIfListIsDirty = async () => {
      if (
        !areMutationsPending &&
        invalidationState.shouldRevalidateDialogMetrics
      ) {
        await queryClient.invalidateQueries({
          queryKey: [
            queryKeys.negotiations.getNegotiationMetrics,
            negotiationId,
          ],
        });
        setInvalidationState({
          shouldRevalidateNegotiationsList: true,
          shouldRevalidateDialogMetrics: false,
        });
      }
    };
    void invalidateMetricsIfListIsDirty();
  }, [
    areMutationsPending,
    invalidationState.shouldRevalidateDialogMetrics,
    negotiationId,
    queryClient,
  ]);

  return (
    <DialogStyled isOpen={isOpen} onClose={handleOnClose} hasCloseIcon>
      <Heading variant="h2">{t('Selected suppliers')}</Heading>
      {negotiation && (
        <SelectedSuppliersMetrics
          negotiation={negotiation}
          isFetching={isNegotiationPending}
          isError={isNegotiationError}
        />
      )}
      <SuppliersTable
        negotiationId={negotiationId}
        isFetching={isNegotiationPending}
        suppliers={negotiation?.attributes.negotiationSuppliers ?? []}
        onFetchMore={() => null}
      />
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog)(({ theme: { spacing } }) => ({
  width: '95%',
  maxWidth: spacing(159),
  alignItems: 'flex-start',
  gap: spacing(2),
}));
