import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { PasswordInput } from '@/modules/form/components/PasswordInput/PasswordInput';
import {
  areEqual,
  notEmpty,
  passwordPattern,
} from '@/modules/form/utils/structs';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { recompose } from '@/utils/structs/recompose';

import {
  ButtonStyled,
  FormFieldsWrapperStyled,
  TextInputWrapperStyled,
} from './ChangePasswordDialog.styled';
import { useChangePasswordMutation } from './useChangePasswordMutation';

const changePasswordStruct = sup.object({
  currentPassword: recompose(sup.string(), notEmpty, passwordPattern),
  newPassword: recompose(sup.string(), notEmpty, passwordPattern),
  repeatNewPassword: recompose(
    sup.string(),
    notEmpty,
    passwordPattern,
    areEqual<sup.Describe<{ newPassword: string }>, string>('newPassword')
  ),
});

type ChangePasswordValues = sup.Infer<typeof changePasswordStruct>;

type ChangePasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChangePasswordDialog = ({
  isOpen,
  onClose,
}: ChangePasswordDialogProps) => {
  const { t } = useTranslation('pages/ProfilePage');

  const changePasswordMutation = useChangePasswordMutation();

  const errorMessages = {
    currentPassword: {
      'string.notEmpty': t('Current password is required'),
      'string.passwordPattern': t(
        'The password should be at least 6 characters long, have min. 1 uppercase letter and 1 digit.'
      ),
    },
    newPassword: {
      'string.notEmpty': t('New password is required'),
      'string.passwordPattern': t(
        'The password should be at least 6 characters long, have min. 1 uppercase letter and 1 digit.'
      ),
    },
    repeatedPassword: {
      'string.notEmpty': t('Password repetition is required'),
      'string.passwordPattern': t(
        'The password should be at least 6 characters long, have min. 1 uppercase letter and 1 digit.'
      ),
      'string.areEqual': t('Passwords should match'),
    },
  };

  const formProps = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    struct: changePasswordStruct,
  });
  const { register, reset, handleSubmit } = formProps;

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const onValidSubmit = ({
    currentPassword,
    newPassword,
  }: ChangePasswordValues) =>
    changePasswordMutation.mutate(
      { oldPassword: currentPassword, newPassword },
      { onSuccess: () => handleOnClose() }
    );

  return (
    <Dialog
      isOpen={isOpen}
      title={t('Change password')}
      onClose={handleOnClose}
      hasCloseIcon
    >
      <div>
        <FormProvider {...formProps}>
          <form onSubmit={handleSubmit(onValidSubmit)} noValidate>
            <FormFieldsWrapperStyled>
              <TextInputWrapperStyled>
                <PasswordInput
                  label={t('Current password')}
                  placeholder={t('Current password')}
                  autoComplete="current-password"
                  {...register('currentPassword')}
                  errorMessages={errorMessages.currentPassword}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <PasswordInput
                  label={t('New password')}
                  placeholder={t('New password')}
                  autoComplete="new-password"
                  {...register('newPassword')}
                  errorMessages={errorMessages.newPassword}
                />
              </TextInputWrapperStyled>
              <TextInputWrapperStyled>
                <PasswordInput
                  label={t('Repeat password')}
                  placeholder={t('Repeat password')}
                  autoComplete="new-password"
                  {...register('repeatNewPassword')}
                  errorMessages={errorMessages.repeatedPassword}
                />
              </TextInputWrapperStyled>
              <ButtonStyled
                type="submit"
                loading={changePasswordMutation.isPending}
              >
                {t('Save changes')}
              </ButtonStyled>
            </FormFieldsWrapperStyled>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
