import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import {
  NegotiationSupplierStateV2,
  NegotiationWithProgressSuppliersOrder,
} from '@/modules/negotiation/types/negotiationTypes';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

import {
  NegotiationResourceTypeV2,
  NegotiationSupplier,
} from '../../api/resources/negotiation/negotiationResourceStructV2';
import { defaultPrefixedSuppliersQueryParams } from '../../helpers/defaultPrefixedSuppliersQueryParams';

import { SuppliersFilters } from './components/SuppliersFilters/SuppliersFilters';
import { SuppliersTable } from './components/SuppliersTable/SuppliersTable';
import { useSetSuppliersQueryParams } from './hooks/useSetSuppliersQueryParams';

export type NegotiationPageQueryParams = {
  'suppliers.page'?: number;
  'suppliers.size'?: number;
  'suppliers.sort'?: NegotiationWithProgressSuppliersOrder;
  'suppliers.searchQuery'?: string;
  'suppliers.category'?: string[];
  'suppliers.progressStep'?: NegotiationSupplierStateV2;
};

type StartedNegotiationSuppliersListProps = {
  negotiation: NegotiationResourceTypeV2;
  queryParams: NegotiationPageQueryParams;
  isLoading: boolean;
  isNegotiationActive?: boolean;
};

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));

export const StartedNegotiationSuppliersList = ({
  negotiation,
  queryParams,
  isLoading,
  isNegotiationActive = false,
}: StartedNegotiationSuppliersListProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  const [selectedSuppliers, setSelectedSuppliers] = useState<
    NegotiationSupplier[]
  >([]);
  const [selectedSupplierCount, setSelectedSupplierCount] = useState(0);

  const suppliersSearchQuery = queryParams['suppliers.searchQuery'] || '';
  const searchQuery = useMemo(
    () => suppliersSearchQuery,
    [suppliersSearchQuery]
  );
  const suppliersOrder =
    queryParams['suppliers.sort'] ||
    defaultPrefixedSuppliersQueryParams['suppliers.sort'];

  const order = useMemo(() => suppliersOrder, [suppliersOrder]);
  const suppliersCategoriesFilter = queryParams['suppliers.category'];
  const categoriesFilter = useMemo(
    () => suppliersCategoriesFilter || [],
    [suppliersCategoriesFilter]
  );
  const suppliersPageNumber = queryParams['suppliers.page'];
  const pageNumber = useMemo(
    () => suppliersPageNumber || 1,
    [suppliersPageNumber]
  );
  const suppliersPageSize =
    queryParams['suppliers.size'] ||
    defaultPrefixedSuppliersQueryParams['suppliers.size'];
  const pageSize = useMemo(() => suppliersPageSize, [suppliersPageSize]);
  const suppliersProgressStep = queryParams['suppliers.progressStep'] || '';
  const progressStep = useMemo(
    () => suppliersProgressStep,
    [suppliersProgressStep]
  );

  const {
    setCategories,
    setOrder,
    setPageNumber,
    setPageSize,
    setProgressStep,
    setSearchQuery,
    debouncedSearchQuery,
  } = useSetSuppliersQueryParams({ searchQuery, isNegotiationActive });

  const paginationProps = {
    perPage: pageSize,
    totalCount: selectedSupplierCount ?? 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  useEffect(() => {
    if (negotiation && negotiation.attributes.negotiationSuppliers) {
      const suppliers: NegotiationSupplier[] = [];

      // filter suppliers by filterOptions
      negotiation.attributes.negotiationSuppliers
        .filter(
          (supplier) =>
            supplier.attributes.negotiationSupplierState !==
            NegotiationSupplierStateV2.REMOVED
        )
        .filter((supplier) => {
          if (
            debouncedSearchQuery &&
            !(
              (supplier.attributes.freezedSupplierName ||
                supplier.attributes.supplier?.name) ??
              ''
            )
              .toLowerCase()
              .includes(debouncedSearchQuery.toLocaleLowerCase())
          ) {
            return false;
          }

          if (
            categoriesFilter.length &&
            (!supplier.attributes.supplier?.category ||
              !categoriesFilter.includes(
                supplier.attributes.supplier?.category
              ))
          ) {
            return false;
          }

          if (
            progressStep &&
            supplier.attributes.negotiationSupplierState !== progressStep
          ) {
            return false;
          }

          suppliers.push(supplier);
        });

      // sort by order option
      suppliers.sort((a, b) => {
        const nameA =
          (a.attributes.freezedSupplierName || a.attributes.supplier?.name) ??
          '';
        const nameB =
          (b.attributes.freezedSupplierName || b.attributes.supplier?.name) ??
          '';
        const categoryA = a.attributes.supplier?.category ?? '';
        const categoryB = b.attributes.supplier?.category ?? '';

        switch (order) {
          case 'name':
            return (
              nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) ||
              0
            );
          case '-name':
            return (
              nameB.localeCompare(nameA, undefined, { sensitivity: 'base' }) ||
              0
            );
          case 'currentSpend':
            return (
              (a.attributes.supplier?.spend || 0) -
              (b.attributes.supplier?.spend || 0)
            );
          case '-currentSpend':
            return (
              (b.attributes.supplier?.spend || 0) -
              (a.attributes.supplier?.spend || 0)
            );
          case 'category.name':
            return categoryA.localeCompare(categoryB, undefined, {
              sensitivity: 'base',
            });
          case '-category.name':
            return categoryB.localeCompare(categoryA, undefined, {
              sensitivity: 'base',
            });

          default:
            return 0;
        }
      });

      setSelectedSupplierCount(suppliers.length);

      // get page of suppliers
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const suppliersPage = suppliers.slice(startIndex, endIndex);

      setSelectedSuppliers(suppliersPage);
    }
  }, [
    categoriesFilter,
    debouncedSearchQuery,
    negotiation,
    negotiation.attributes.negotiationSuppliers,
    order,
    pageNumber,
    pageSize,
    progressStep,
    setSelectedSuppliers,
  ]);

  return (
    <Box margins={[7, 0, 6]}>
      <Heading variant="h3" as="h2" mb={3}>
        {t('Suppliers in negotiation')}
      </Heading>

      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isLoading}
          searchQuery={searchQuery}
          categoriesFilter={categoriesFilter}
          order={order}
          progressStep={progressStep}
          onProgressStepChange={setProgressStep}
          onPageSizeChange={setPageSize}
          onSearchQueryChange={setSearchQuery}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />

        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSize}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <SuppliersTable
        suppliers={selectedSuppliers || []}
        isSuccess
        pageSize={pageSize}
        isLoading={isLoading}
        isNegotiationActive={isNegotiationActive}
        negotiationId={negotiation.id}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isLoading ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
    </Box>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 24,
  width: 100,
  marginBottom: 0,
});
