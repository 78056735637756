import { useTranslation } from 'react-i18next';

import { ReactComponent as CodeOfConductIcon } from '@/assets/icons/termIcons/codeOfConductIcon.svg';
import { ReactComponent as ExtraPaymentDaysIcon } from '@/assets/icons/termIcons/extraPaymentDaysIcon.svg';
import { ReactComponent as IsoIcon } from '@/assets/icons/termIcons/isoIcon.svg';
import { ReactComponent as StandardAgreementIcon } from '@/assets/icons/termIcons/standardAgreementIcon.svg';
import { ReactComponent as SustainabilityQuestionnaireIcon } from '@/assets/icons/termIcons/sustainabilityQuestionnaireIcon.svg';
import { NegotiationMetricsV2 } from '@/modules/customer/api/resources/metrics/negotiationMetricsResourceStructV2';

import { AnalysisTermCounter } from './AnalysisTermCounter/AnalysisTermCounter';
import { AnalysisTermCountersWrapperStyled } from './AnalysisTermCounters.styled';

type AnalysisTermCountersProps = {
  terms: NegotiationMetricsV2['uniqueSuppliersPerNegotiationType'];
};
export const AnalysisTermCounters = ({ terms }: AnalysisTermCountersProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const {
    EXTRA_PAYMENT_DAYS,
    STANDARD_AGREEMENT,
    CODE_OF_CONDUCT,
    SUPPLIER_QUESTIONNAIRE,
    ISO,
  } = terms;

  return (
    <AnalysisTermCountersWrapperStyled>
      <AnalysisTermCounter
        Icon={CodeOfConductIcon}
        name={t('Code of Conduct')}
        count={CODE_OF_CONDUCT || 0}
      />
      <AnalysisTermCounter
        Icon={StandardAgreementIcon}
        name={t('Standard agreement')}
        count={STANDARD_AGREEMENT || 0}
      />
      <AnalysisTermCounter
        Icon={SustainabilityQuestionnaireIcon}
        name={t('Supplier questionnaire')}
        count={SUPPLIER_QUESTIONNAIRE || 0}
      />
      <AnalysisTermCounter
        Icon={ExtraPaymentDaysIcon}
        name={t('Extra payment days')}
        count={EXTRA_PAYMENT_DAYS || 0}
      />
      <AnalysisTermCounter Icon={IsoIcon} name={t('ISO')} count={ISO || 0} />
    </AnalysisTermCountersWrapperStyled>
  );
};
