import { Routes as DigiProcRoutes } from '@/modules/router/components/Routes';

import { Providers } from './Providers';
import { SuperTokensProviders } from './SuperTokensProviders';

function App() {
  return (
    <SuperTokensProviders>
      <Providers>
        <DigiProcRoutes />
      </Providers>
    </SuperTokensProviders>
  );
}

export default App;
