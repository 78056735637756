import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { getNegotiationSupplierResponsePDFQueryV2 } from '../api/queries/getNegotiationSupplierResponsePDFQueryV2';

export type GetNegotiationSupplierResponsePDFQueryParams = {
  negotiationId: string;
  negotiationSupplierId: string;
};

export const useGetNegotiationSupplierResponsePDFQuery = (
  params: GetNegotiationSupplierResponsePDFQueryParams
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation(
    'apiMessages/useGetNegotiationSupplierResponsePDFQuery'
  );
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationEmlFile,
      params.negotiationId,
      params.negotiationSupplierId,
    ],
    enabled: !!params.negotiationSupplierId,
    queryFn: getNegotiationSupplierResponsePDFQueryV2(jsonApi, params),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiation"),
        },
      },
    }),
  });
};
