import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as LockedPricesIcon } from '@/assets/icons/termIcons/lockedPricesIcon.svg';
import { ReactComponent as SavingsIcon } from '@/assets/icons/termIcons/savingsIcon.svg';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading } from '@/modules/theme/components/Typography';
import { dark, graphite } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

import { AnalysisMetric } from '../AnalysisMetric/AnalysisMetric';
import { AnalysisNegotiationMetricErrorError } from '../AnalysisNegotiationMetricError/AnalysisNegotiationMetricError';
import { AnalysisNegotiationMetricSkeleton } from '../AnalysisNegotiationMetricSkeleton/AnalysisNegotiationMetricSkeleton';
import {
  BottomMetricsText,
  IconWrapperStyled,
  MetricsCardWrapper,
  MetricsContainer,
  TermDescriptionStyled,
} from '../common.styled';
import { AnalysisMetricsSectionProps } from '../types';

type TotalPotentialMetricsProps = AnalysisMetricsSectionProps;

export const TotalPotentialMetrics = ({
  metrics,
  isFetching,
  isError,
}: TotalPotentialMetricsProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const { customer } = useSessionContext();

  if (isFetching) return <AnalysisNegotiationMetricSkeleton />;
  if (isError || !metrics) return <AnalysisNegotiationMetricErrorError />;

  const {
    currentSpend,
    suppliersCount,
    potentialCostAvoidance,
    potentialSavings,
  } = metrics;
  return (
    <MetricsCardWrapper>
      <MetricsContainer>
        <FlexContainer gap={9} wrap="wrap">
          <AnalysisMetric
            data={potentialSavings}
            heading={t('Total potential savings ({{currency}})', {
              currency: customer.currency,
            })}
            variant="total"
          >
            <TermDescriptionStyled>
              <IconWrapperStyled>
                <SavingsIcon />
              </IconWrapperStyled>
              {t('Improved prices')}
            </TermDescriptionStyled>
          </AnalysisMetric>
          <AnalysisMetric
            data={potentialCostAvoidance}
            heading={t('Total potential cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            variant="total"
          >
            <TermDescriptionStyled>
              <IconWrapperStyled>
                <LockedPricesIcon />
              </IconWrapperStyled>
              {t('Locked prices')}
            </TermDescriptionStyled>
          </AnalysisMetric>
        </FlexContainer>
        <FlexContainer direction="column" mt={5}>
          <BottomMetricsText color={graphite}>
            {t('Spend ({{currency}}) {{amount}}', {
              currency: customer.currency,
              amount: formatAmount(currentSpend),
            })}
          </BottomMetricsText>
          <BottomMetricsText color={graphite}>
            {t('No. of suppliers {{amount}}', {
              amount: formatAmount(suppliersCount),
            })}
          </BottomMetricsText>
        </FlexContainer>
      </MetricsContainer>
      <Box pt={2}>
        <TotalPotentialTextContainerStyled>
          <Heading variant="h3" as="span" color={dark[100]}>
            {t('Total DigiProc potential')}
          </Heading>
        </TotalPotentialTextContainerStyled>
      </Box>
    </MetricsCardWrapper>
  );
};

const TotalPotentialTextContainerStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    maxWidth: 170,
    margin: `${spacing(1)} auto 0`,
    textAlign: 'center',
  })
);
