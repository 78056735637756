import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Heading } from '@/modules/theme/components/Typography';

export const TitleStyled = styled(Heading)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(4),
}));

export const EditorWrapperStyled = styled('div')(
  ({ theme: { breakpoints } }) => ({
    width: 728,
    [breakpoints.down.lg]: {
      flex: 1,
      width: 'auto',
    },
  })
);

export const ContainerStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(15.5),
    padding: spacing([7, 3, 10.25, 7.5]),
    [breakpoints.down.lg]: {
      gap: spacing(8),
    },
    [breakpoints.down.md]: {
      flexDirection: 'column',
      padding: spacing([7, 3, 10.25, 7.5]),
    },
  })
);

export const DownloadEmailSectionStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: spacing(40),
  })
);

export const DownloadEmailLinksSectionStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    gap: spacing(2),
    paddingTop: spacing(4),
    paddingBottom: spacing(2),
  })
);

export const ButtonsSectionStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  maxWidth: spacing(37.8),
}));

export const DownloadButtonStyled = styled(Button)(
  ({ theme: { typography, spacing, palette }, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),
    color: palette.primary.main,
    textDecoration: 'none',
    fontWeight: typography.fontWeight.bold,
    '&:hover, &:focus': {
      color: palette.primary.light,
    },
    ...(disabled && {
      '&:hover, &:focus': {
        cursor: 'not-allowed',
        color: palette.disabled.main,
      },
      color: palette.disabled.main,
    }),
  })
);

export const InfoWrapperContainer = styled('div')(({ theme: { spacing } }) => ({
  minHeight: spacing(6),
}));
