import { signOut as supertokensUserSignOut } from 'supertokens-auth-react/recipe/session';

import { useSessionContext } from './useSessionContext';

export const useSignOutMutation = () => {
  const { signOut } = useSessionContext();

  return {
    signOut: async () => {
      await supertokensUserSignOut();
      return signOut();
    },
  };
};
