import * as sup from 'superstruct';

type ContractTermNode = {
  term: NegotiatedTermItem;
  children: ContractTermNode[];
};

const translationsStruct = sup.record(sup.string(), sup.nullable(sup.string()));

/** An item that represents a text template, e.g. for email content */
const templateStruct = sup.object({
  id: sup.number(),
  name: sup.optional(sup.nullable(sup.string())),
  title: sup.optional(sup.nullable(sup.string())),
  content: sup.optional(sup.nullable(sup.string())),
  titleTranslations: sup.optional(sup.nullable(translationsStruct)),
  contentTranslations: sup.optional(sup.nullable(translationsStruct)),
});

function negotiatedTermItemStruct() {
  return sup.type({
    id: sup.string(),
    type: sup.optional(sup.nullable(sup.string())),
    position: sup.optional(sup.nullable(sup.string())),
    template: sup.optional(sup.nullable(templateStruct)),
  });
}

type NegotiatedTermItem = sup.Infer<
  ReturnType<typeof negotiatedTermItemStruct>
>;

const termInContractStruct = sup.type({
  term: negotiatedTermItemStruct(),
  children: sup.array(sup.lazy(() => termInContractStruct)),
}) as sup.Describe<ContractTermNode>;

export const negotiationContractResourceStructV2 = sup.object({
  id: sup.string(),
  attributes: sup.object({
    title: sup.string(),
    terms: sup.array(termInContractStruct),
  }),
});

export type NegotiationContractResourceTypeV2 = sup.Infer<
  typeof negotiationContractResourceStructV2
>;

export type NegotiationContractTerm =
  NegotiationContractResourceTypeV2['attributes']['terms'][number];
