import * as sup from 'superstruct';

import { supplierLanguageStruct } from '@/modules/language/supplierLanguage/supplierLanguageConsts';

export const negotiationKeywordStruct = sup.object({
  id: sup.string(),
  name: sup.string(),
  value: sup.string(),
  valueTranslations: sup.record(
    sup.nonempty(supplierLanguageStruct),
    sup.optional(sup.string())
  ),
});

export const negotiationKeywordsStruct = sup.array(negotiationKeywordStruct);

export const updateNegotiationKeywordStruct = sup.object({
  id: sup.string(),
  value: sup.optional(sup.string()),
  valueTranslations: sup.optional(
    sup.record(sup.nonempty(supplierLanguageStruct), sup.optional(sup.string()))
  ),
});

export type UpdateNegotiationKeywordStruct = sup.Infer<
  typeof updateNegotiationKeywordStruct
>;
