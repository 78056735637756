import styled from 'styled-components';

import { ReactComponent as LogoHorizontal } from '@/assets/logoHorizontal.svg';
import { Card } from '@/modules/theme/components/Card';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { white } from '@/modules/theme/utils/colors';

import { AuthContentLayoutMode } from './types';

type HeroSectionStyledProps = {
  mode: AuthContentLayoutMode;
};

export const HeroSectionStyled = styled('div')<HeroSectionStyledProps>(
  ({ theme: { spacing, breakpoints }, mode }) => ({
    position: 'relative',
    height: mode === 'signUp' ? spacing(95) : spacing(79),

    [breakpoints.down.md]: {
      height: mode === 'signUp' ? spacing(122) : spacing(79),
    },
  })
);

export const CardWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints, zIndexes } }) => ({
    position: 'absolute',
    right: spacing(18),
    bottom: spacing(-9),
    zIndex: zIndexes.authCard,
    [breakpoints.down.lg]: {
      right: spacing(12),
    },
    [breakpoints.down.md]: {
      left: '50%',
      transform: 'translate(-50%)',
      bottom: spacing(-3),
    },
  })
);

export const LogoHorizontalWhiteStyled = styled(LogoHorizontal)(
  ({ theme: { spacing, breakpoints } }) => ({
    position: 'absolute',
    top: spacing(34.6),
    left: spacing(25),
    color: white,
    width: 423,
    [breakpoints.down.lg]: {
      left: spacing(12),
    },
    [breakpoints.down.md]: {
      left: '50%',
      transform: 'translate(-50%)',
      top: spacing(4.5),
    },
  })
);

export const CardStyled = styled(Card)(({ theme: { spacing } }) => ({
  padding: spacing([5, 3]),
}));

export const FlexContainerStyled = styled(FlexContainer)(
  ({ theme: { spacing } }) => ({
    paddingBottom: spacing(5),
  })
);
