import * as sup from 'superstruct';

const userRelationType = sup.enums(['DIGIPROC', 'SUPPLIER', 'CUSTOMER']);

export const userResourceStruct = sup.object({
  id: sup.string(),
  type: sup.literal('USER'),
  attributes: sup.object({
    name: sup.string(),
    relationType: userRelationType,
    relationId: sup.optional(sup.string()),
  }),
});
