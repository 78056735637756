import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { NegotiationTermsPanel } from '@/modules/negotiation/components/NegotiationTermsPanel/NegotiationTermsPanel';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';
import { NegotiationMetrics } from '@/utils/calculate-negotiation-metrics';

type NegotiationSetupMetricsProps = {
  metrics: NegotiationMetrics;
  negotiation: NegotiationResourceTypeV2;
};

export const NegotiationSetupMetrics = ({
  negotiation,
  metrics,
}: NegotiationSetupMetricsProps) => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { customer } = useSessionContext();

  return (
    <NegotiationSetupMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metrics.potentialSavings && (
          <NegotiationMetric
            heading={t('Potential savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialSavings}
          />
        )}

        {metrics.potentialCostAvoidance && (
          <NegotiationMetric
            heading={t('Potential cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialCostAvoidance}
          />
        )}
      </FinancialMetricsWrapperStyled>

      {metrics.suppliersCount && (
        <NegotiationMetric
          variant="counter"
          heading={t('Nr of suppliers')}
          data={metrics.suppliersCount}
        />
      )}
      <NegotiationTermsPanel
        negotiationType={negotiation.attributes.type as NegotiationType[]}
      />
    </NegotiationSetupMetricsWrapperStyled>
  );
};

const NegotiationSetupMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(6),
  })
);

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    '&:empty': {
      marginRight: spacing(-6),
    },
  })
);
