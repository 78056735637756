import { NegotiationContractTerm } from '@/modules/negotiation/api/resources/negotiation/negotiationContractResourceStructV2';
import { RichContentTerm } from '@/modules/negotiation/negotiationTerms/NegotiationTerms/components/RichContentTerm';

import { NegotiationTermCard } from './NegotiationTermCard';

type NegotiationTermProps = {
  term: NegotiationContractTerm;
};

export const NegotiationTerm = ({ term }: NegotiationTermProps) => {
  if (!term.term.template?.content) return null;

  switch (term.term.position) {
    // case NegotiationTermTypes.signCodeOfConduct:
    //   return <CodeOfConduct term={term} />;

    // case NegotiationTermTypes.acceptContractPeriod:
    //   return <NegotiationContractPeriod term={term} />;

    // case NegotiationTermTypes.selectDiscountImplementation:
    //   return <ImplementationTypes term={term} />;

    default:
      return (
        <NegotiationTermCard>
          <RichContentTerm text={term.term.template.content} />
        </NegotiationTermCard>
      );
  }
};
