import Session from 'supertokens-auth-react/recipe/session';
import { AccessDeniedScreen } from 'supertokens-auth-react/recipe/session/prebuiltui';
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';

import { AnonymousRoutes } from '@/modules/router/routings/anonymousRouting';
import { AuthenticatedRoutes } from '@/modules/router/routings/authenticatedRouting';
import { PageLoader } from '@/modules/theme/components/PageLoader';

export function Routes() {
  const sessionContext = Session.useSessionContext();

  if (sessionContext.loading) {
    return <PageLoader />;
  }

  if (sessionContext.doesSessionExist) {
    // We wrap this with <SessionAuth /> so that
    // all claims are validated before showing the logged in UI.
    // For example, if email verification is switched on, and
    // the user's email is not verified, then <SessionAuth />
    // will redirect to the email verification page.
    return (
      <Session.SessionAuth
        accessDeniedScreen={AccessDeniedScreen}
        overrideGlobalClaimValidators={(globalValidators) => [
          ...globalValidators,
          UserRoleClaim.validators.includesAny(['admin', 'customer']),
        ]}
      >
        <AuthenticatedRoutes />
      </Session.SessionAuth>
    );
  } else {
    return <AnonymousRoutes />;
  }
}
