import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { appConfig } from '@/modules/app/appConfig';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { useUpdateNegotiationMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationMutationV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { basicErrorResponseValidator } from '@/modules/reactQuery/basicApiErrorValidator';
import { getApiErrorStatusCode } from '@/modules/reactQuery/validateApiError';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { LinkType } from '@/modules/theme/components/ButtonLink';
import { HeaderButtonLink } from '@/modules/theme/components/HeaderButton';
import { Tabs } from '@/modules/theme/components/Tabs/Tabs';
import { useDetectEnglishBrowser } from '@/utils/useDetectEnglishBrowser';

import { AuthenticatedNotFoundPage } from '../AuthenticatedNotFoundPage';

import { ActivateNegotiationSection } from './components/ActivateNegotiationSection/ActivateNegotiationSection';
import { NegotiationSetupDataPanel } from './components/NegotiationSetupDataPanel/NegotiationSetupDataPanel';
import { useTabItems } from './hooks/useTabItems';

export const NegotiationSetupPage = () => {
  const { t } = useTranslation('pages/NegotiationSetupPage');
  const { negotiationId } =
    onboardedRoutes.useGetRouteParams('negotiationSetup');
  const tabs = useTabItems();
  const isEnBrowser = useDetectEnglishBrowser();

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    error: negotiationError,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });
  const {
    mutate: updateNegotiationMutate,
    isPending: updateNegotiationMutationIsPending,
  } = useUpdateNegotiationMutationV2();

  const parsedPreviewUrl = isEnBrowser
    ? `${
        appConfig.preview.supplierBaseUrl
      }/en/negotiation/welcome?negotiationId=${
        negotiation?.id ?? ''
      }&preview=true`
    : `${
        appConfig.preview.supplierBaseUrl
      }/sv/negotiation/welcome?negotiationId=${
        negotiation?.id ?? ''
      }&preview=true`;

  useEffect(() => {
    if (negotiation?.attributes.state === NegotiationStateV2.SUGGESTED) {
      updateNegotiationMutate({
        id: negotiation?.id || '',
        attributes: {
          state: NegotiationStateV2.IN_SETUP,
        },
      });
    }
  }, [negotiation?.attributes.state, negotiation?.id, updateNegotiationMutate]);

  const errorCode = getApiErrorStatusCode(
    negotiationError,
    basicErrorResponseValidator
  );

  if (errorCode === 404) {
    return <AuthenticatedNotFoundPage />;
  }

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={negotiation?.attributes.state as NegotiationStateV2}
      isLoading={isNegotiationPending}
      buttonLink={
        <HeaderButtonLink
          to={onboardedRoutes.buildUrl('dashboard')}
          linkType={LinkType.Internal}
        >
          {t('Back')}
        </HeaderButtonLink>
      }
    >
      <>
        <NegotiationSetupDataPanel
          negotiation={negotiation}
          shouldDisplayLoader={isNegotiationPending}
        />
        <ActivateNegotiationSection
          negotiation={negotiation}
          isLoading={isNegotiationPending || updateNegotiationMutationIsPending}
          supplierPreviewUrl={parsedPreviewUrl}
        />
        <Tabs tabs={tabs} variant="xSmall" />
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </>
    </NegotiationLayout>
  );
};

const ContentWrapper = styled('div')(({ theme: { spacing, palette } }) => ({
  padding: spacing([2, 10, 7]),
  minHeight: 600,
  backgroundColor: palette.default.background,
}));
