import { useTranslation } from 'react-i18next';

import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Text } from '@/modules/theme/components/Typography';

import {
  ProgressBarStyled,
  ProgressBarWrapperStyled,
} from './SupplierProgress.styled';

type SupplierProgressProps = {
  progress: NegotiationSupplierStateV2;
};

const progressValuesMap = {
  [NegotiationSupplierStateV2.EXCLUDED]: 0,
  [NegotiationSupplierStateV2.SELECTED]: 0,
  [NegotiationSupplierStateV2.REMOVED]: 0,
  [NegotiationSupplierStateV2.INVITED]: 0,
  [NegotiationSupplierStateV2.NOT_DELIVERED]: 0,
  [NegotiationSupplierStateV2.OPENED_EMAIL]: 25,
  [NegotiationSupplierStateV2.VISITED]: 50,
  [NegotiationSupplierStateV2.NEGOTIATION_STARTED]: 75,
  [NegotiationSupplierStateV2.NEGOTIATION_COMPLETED]: 100,
};

export const SupplierProgress = ({ progress }: SupplierProgressProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');

  if (progress === NegotiationSupplierStateV2.EXCLUDED) {
    return <Text bold>{t('Excluded')}</Text>;
  }

  return (
    <ProgressBarWrapperStyled>
      <ProgressBarStyled
        progress={progress}
        max={100}
        value={progressValuesMap[progress]}
      />
      <Text variant="small">{progressValuesMap[progress]}%</Text>
    </ProgressBarWrapperStyled>
  );
};
