import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { FlexLayout } from '@/modules/theme/components/FlexLayout';
import { Text } from '@/modules/theme/components/Typography/Text';

import { useSignOutMutation } from '../hooks/useSignOutMutation';

export function SignOutDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation('layouts/AuthenticatedLayout');

  const signOutMutation = useSignOutMutation();

  return (
    <Dialog isOpen={isOpen} hasCloseIcon onClose={onClose}>
      <FlexLayout direction="column">
        <FlexContainer justify="center" mb={3}>
          <Text variant="small">{t('Are you sure you want to sign out?')}</Text>
        </FlexContainer>
        <FlexContainer justify="center">
          <DialogButtonStyled onClick={() => signOutMutation.signOut()}>
            {t('Sign me out')}
          </DialogButtonStyled>
        </FlexContainer>
      </FlexLayout>
    </Dialog>
  );
}

const DialogButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
}));
