import { useQueryClient } from '@tanstack/react-query';
import { nanoid } from 'nanoid';

import { queryKeys } from '@/modules/api/queryKeys';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { useUpdateNegotiationSupplierMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationSupplierMutationV2';
import {
  NegotiationStateV2,
  NegotiationSupplierStateV2,
} from '@/modules/negotiation/types/negotiationTypes';
import { Loader } from '@/modules/theme/components/Loader';

type ToggleSupplierCheckboxProps = {
  selected: boolean;
  supplierId: string;
  negotiationId: string;
};

export const ToggleSupplierCheckbox = ({
  selected,
  negotiationId,
  supplierId,
}: ToggleSupplierCheckboxProps) => {
  const checkboxId = nanoid();
  const queryClient = useQueryClient();
  const {
    customer: { id: customerId },
  } = useSessionContext();

  const { mutate: updateNegotiationSupplier, isPending } =
    useUpdateNegotiationSupplierMutationV2();

  const handleOnChange = async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.negotiations.getNegotiationMetrics],
    });
    if (selected) {
      updateNegotiationSupplier(
        {
          negotiationId,
          negotiationSupplierId: supplierId,
          value: {
            negotiationSupplierState: NegotiationSupplierStateV2.REMOVED,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getNegotiationV2,
                negotiationId,
              ],
            });
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getNegotiationsV2,
                1,
                100,
                [
                  NegotiationStateV2.SUGGESTED,
                  NegotiationStateV2.DEMO,
                  NegotiationStateV2.IN_SETUP,
                  NegotiationStateV2.TO_BE_APPROVED,
                ],
              ],
            });
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getSuggestedNegotiationMetricsV2,
                customerId,
              ],
            });
          },
        }
      );
    } else {
      updateNegotiationSupplier(
        {
          negotiationId,
          negotiationSupplierId: supplierId,
          value: {
            negotiationSupplierState: NegotiationSupplierStateV2.SELECTED,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getNegotiationV2,
                negotiationId,
              ],
            });
          },
        }
      );
    }
  };

  if (isPending) {
    return <Loader size={20} />;
  }

  return (
    <DefaultCheckbox
      checked={selected}
      id={checkboxId}
      onChange={handleOnChange}
    />
  );
};
