import styled, { CSSObject } from 'styled-components';

import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { dark, mint } from '@/modules/theme/utils/colors';

export const ProgressBarWrapperStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 142,
});

type ProgressBarStyledProps = {
  progress: NegotiationSupplierStateV2;
};

export const ProgressBarStyled = styled('progress')<ProgressBarStyledProps>(({
  theme: { borderRadius },
  progress,
}) => {
  const progressBarStyles: CSSObject = {
    borderRadius: borderRadius.large,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ...(progress === NegotiationSupplierStateV2.OPENED_EMAIL && {
      backgroundColor: mint[900],
    }),
    ...(progress === NegotiationSupplierStateV2.VISITED && {
      backgroundColor: mint[800],
    }),
    ...(progress === NegotiationSupplierStateV2.NEGOTIATION_STARTED && {
      backgroundColor: mint[500],
    }),
    ...(progress === NegotiationSupplierStateV2.NEGOTIATION_COMPLETED && {
      backgroundColor: mint[400],
      borderTopRightRadius: borderRadius.large,
      borderBottomRightRadius: borderRadius.large,
    }),
  };

  return {
    width: 100,
    height: 8,
    appearance: 'none',

    // styles for Firefox
    borderRadius: borderRadius.large,
    border: 'none',
    backgroundColor: dark[100],

    '&::-moz-progress-bar': progressBarStyles,

    // styles for WebKit browsers
    '&::-webkit-progress-bar': {
      borderRadius: borderRadius.large,
      backgroundColor: dark[100],
    },

    '&::-webkit-progress-value': progressBarStyles,
  };
});
