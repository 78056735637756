import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import { getCustomerResourcePath } from '../resources/customer/customerResourceConsts';
import { customerResourceStruct } from '../resources/customer/customerResourceStruct';

type GetCustomerQueryPayload = {
  customerId: string;
};

export const getCustomerQuery = (
  jsonApi: JsonApiV2Client,
  { customerId }: GetCustomerQueryPayload
) => {
  return () =>
    jsonApi.get({
      path: getCustomerResourcePath(customerId),
      responseStruct: getCustomerResponseStruct,
    });
};

const getCustomerResponseStruct = createResponseStruct({
  data: customerResourceStruct,
});

export type GetCustomerResponse = sup.Infer<typeof getCustomerResponseStruct>;
