import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationCardLayout } from '../NegotiationCardLayout/NegotiationCardLayout';

import { DataWrapperStyled, NumberStyled } from './NegotiationCard.styled';

type NegotiationBeforeStartCardProps = {
  negotiation: NegotiationResourceTypeV2;
  link?: string;
};

export const NegotiationBeforeStartCard = ({
  negotiation,
  link,
}: NegotiationBeforeStartCardProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { currency },
  } = useSessionContext();

  const metrics = calculateNegotiationMetrics(negotiation);

  return (
    <NegotiationCardLayout
      link={link}
      negotiation={negotiation}
      metrics={metrics}
    >
      <FlexContainer wrap="wrap" columnGap={15}>
        {metrics.totalSpend > 0 && (
          <DataWrapperStyled>
            <NumberStyled>{formatAmount(metrics.totalSpend)}</NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiable spend ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metrics.potentialSavings && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics.potentialSavings)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Potential savings ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metrics.potentialCostAvoidance && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(metrics.potentialCostAvoidance)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Potential Cost Avoidance ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metrics.emailCount > 0 && (
          <DataWrapperStyled>
            <NumberStyled>{metrics.emailCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Email contacts')}</Paragraph>
          </DataWrapperStyled>
        )}
      </FlexContainer>
    </NegotiationCardLayout>
  );
};
