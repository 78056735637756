import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { NegotiationCard } from '../NegotiationCard/NegotiationCard';
import { DnDNegotiation } from '../types';

import { Draggable } from './components/Draggable';
import { DragPlaceholder } from './components/DragPlaceholder';
import { Droppable } from './components/Droppable';
import { RefreshOverlay } from './components/RefreshOverlay';
import {
  periods,
  DEFAULT_DROPPABLE,
  periodNotSelectedState,
} from './constants';
import {
  PeriodsStyled,
  DndContainerStyled,
  DraggableColumnStyled,
  DefaultGridStyled,
  DefaultGridPlaceholderStyled,
  DndWrapperStyled,
  PeriodStyled,
  TimelineArrowStyled,
} from './NegotiationsDnd.styled';

export type UpdatePeriodIndexParams = {
  negotiationId: string;
  destinationIndex?: number;
  originIndex?: number;
  periodOrder?: number;
};

type NegotiationsDnDProps = {
  items: DnDNegotiation[];
  isRefetching: boolean;
  isLoading: boolean;
  updatePeriodIndex: (params: UpdatePeriodIndexParams) => void;
  setItems: (value: SetStateAction<DnDNegotiation[]>) => void;
  onNegotiationCardClick: (negotiation: DnDNegotiation) => void;
};

export const NegotiationsDnD = ({
  items,
  isRefetching,
  isLoading,
  updatePeriodIndex,
  setItems,
  onNegotiationCardClick,
}: NegotiationsDnDProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const hasItems = (parentIndex: number) => {
    return items.some(({ periodIndex }) => periodIndex === parentIndex);
  };

  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    const currentDraggable = items.find(({ id }) => id === active.id);

    if (!currentDraggable || !over || !items) return;

    if (currentDraggable.periodIndex === Number(over.id)) return;

    const overId = Number(over.id);
    const itemsFromDestinationPeriod = getNegotiationsForPeriod({
      negotiations: items,
      periodId: overId,
    });

    const destinationPeriodOrder =
      (itemsFromDestinationPeriod.at(-1)?.periodOrder || 0) + 1;

    const updateDraggable = {
      ...currentDraggable,
      periodIndex: overId === DEFAULT_DROPPABLE ? -1 : overId,
      periodOrder: overId === DEFAULT_DROPPABLE ? -1 : destinationPeriodOrder,
    };

    updatePeriodIndex({
      negotiationId: updateDraggable.id,
      destinationIndex: updateDraggable.periodIndex,
      originIndex: currentDraggable.periodIndex,
      periodOrder: updateDraggable.periodOrder,
    });
    setItems([...items.filter(({ id }) => id !== active.id), updateDraggable]);
  };

  const itemsWithoutPeriod = items.filter(({ periodIndex }) =>
    periodIndex ? periodNotSelectedState.includes(periodIndex) : false
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor),
    useSensor(TouchSensor)
  );

  return (
    <>
      <DndWrapperStyled>
        {isRefetching && <RefreshOverlay />}
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          modifiers={[restrictToWindowEdges]}
          autoScroll={false}
        >
          <div>
            <PeriodsStyled>
              {periods.map(({ periodId }, index) => {
                const isLast = index === periods.length - 1;
                return (
                  <PeriodStyled key={periodId}>
                    {periodId + 1}
                    {isLast && <TimelineArrowStyled />}
                  </PeriodStyled>
                );
              })}
            </PeriodsStyled>

            <DndContainerStyled>
              {periods.map(({ periodId }) => (
                <Fragment key={periodId}>
                  {isLoading && (
                    <DraggableColumnStyled>
                      <SkeletonLoader width={320} lines={3} />
                      <DragPlaceholder variant="none" />
                    </DraggableColumnStyled>
                  )}
                  {!isLoading && (
                    <Droppable id={String(periodId)} key={periodId}>
                      {hasItems(periodId) && (
                        <DraggableColumnStyled>
                          {getNegotiationsForPeriod({
                            negotiations: items,
                            periodId,
                          }).map((negotiation) => (
                            <Draggable id={negotiation.id} key={negotiation.id}>
                              <NegotiationCard
                                negotiation={negotiation}
                                onAction={() => {
                                  onNegotiationCardClick(negotiation);
                                }}
                              />
                            </Draggable>
                          ))}
                        </DraggableColumnStyled>
                      )}
                      {!hasItems(periodId) && (
                        <DragPlaceholder variant="border" />
                      )}
                      {hasItems(periodId) && <DragPlaceholder variant="none" />}
                    </Droppable>
                  )}
                </Fragment>
              ))}
            </DndContainerStyled>
          </div>

          <Droppable id={String(DEFAULT_DROPPABLE)}>
            {itemsWithoutPeriod?.length > 0 && (
              <DefaultGridStyled>
                {itemsWithoutPeriod
                  .sort(
                    (first, second) =>
                      second.createdAt.getTime() - first.createdAt.getTime()
                  )
                  .map((negotiation) => (
                    <Draggable key={negotiation.id} id={negotiation.id}>
                      <NegotiationCard
                        negotiation={negotiation}
                        onAction={() => {
                          onNegotiationCardClick(negotiation);
                        }}
                      />
                    </Draggable>
                  ))}
              </DefaultGridStyled>
            )}
            {itemsWithoutPeriod?.length === 0 && (
              <DefaultGridPlaceholderStyled>
                <h4>{t("You don't have any estimated negotiations")} +</h4>
                <h4>({t('drag & drop')})</h4>
              </DefaultGridPlaceholderStyled>
            )}
          </Droppable>
        </DndContext>
      </DndWrapperStyled>
    </>
  );
};

const getNegotiationsForPeriod = ({
  negotiations,
  periodId,
}: {
  negotiations: DnDNegotiation[];
  periodId: number;
}) => {
  return negotiations
    .filter(({ periodIndex }) => periodIndex === periodId)
    .sort(
      (first, second) => Number(first.periodOrder) - Number(second.periodOrder)
    );
};
