import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { defaultPrefixedSuppliersQueryParams } from '@/modules/negotiation/helpers/defaultPrefixedSuppliersQueryParams';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import {
  onboardedRoutes,
  plannedNegotiationRoutes,
  setupNegotiationRoutes,
} from '@/modules/router/onboardedRoutes';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';

export const getNegotiationDetailsPath = (
  negotiation: NegotiationResourceTypeV2
) => {
  const {
    attributes: { state },
    id,
  } = negotiation;
  if (
    state === NegotiationStateV2.SUGGESTED ||
    state === NegotiationStateV2.IN_SETUP ||
    state === NegotiationStateV2.TO_BE_APPROVED
  ) {
    return setupNegotiationRoutes.buildUrl('startDate', {
      params: { negotiationId: id },
    });
  }
  if (state === NegotiationStateV2.PLANNED) {
    return plannedNegotiationRoutes.buildUrl('suppliers', {
      params: { negotiationId: id },
      query: defaultSuppliersQueryParams,
    });
  }
  if (state === NegotiationStateV2.IN_PROGRESS) {
    return onboardedRoutes.buildUrl('activeNegotiation', {
      params: { negotiationId: id },
      query: defaultPrefixedSuppliersQueryParams,
    });
  }
  if (state === NegotiationStateV2.COMPLETED) {
    return onboardedRoutes.buildUrl('completedNegotiation', {
      params: { negotiationId: id },
      query: defaultPrefixedSuppliersQueryParams,
    });
  }
};
