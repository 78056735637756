import * as sup from 'superstruct';

import {
  createIdentifierStruct,
  createResourceStruct,
} from '@/modules/api/utils/jsonApiStructs';
import { stringToNumber } from '@/utils/structs/structs';

const exclusionreasonsChartType = 'exclusion_reasons_chart';

const exclusionReasonsChartIdentifierStruct = createIdentifierStruct(
  exclusionreasonsChartType
);

export enum ExclusionReason {
  ASSUMED_STRATEGIC = 'assumedStrategic',
  NON_DIGIPROC_FIT = 'nonDigiprocFit',
  TOO_LOW_SPEND = 'tooLowSpend',
  NON_FIT_COUNTRIES = 'nonFitCountries',
  NON_FIT_SUPPLIERS = 'nonFitSuppliers',
  NON_FIT_CATEGORIES = 'nonFitCategories',
  ALTOR_FRAME_AGREEMENT = 'altorFrameAgreement',
  UE = 'ue',
  OTHER = 'other',
}

// https://dev.api.digiproc.com/documentation/static/index.html#/supplier/get_me_customer_suppliers
// Comma-separated list of exclusion reasons, pass "null" (literal string) to include recommended suppliers
export type ExclusionReasonFilter = ExclusionReason | 'null';

export const exclusionReasonsChartResourceStruct = createResourceStruct({
  identifier: exclusionReasonsChartIdentifierStruct,
  attributes: sup.object({
    points: sup.array(
      sup.object({
        exclusionReason: sup.nullable(
          sup.enums(Object.values(ExclusionReason))
        ),
        count: sup.integer(),
        spend: stringToNumber,
      })
    ),
    total: sup.object({
      count: sup.integer(),
      spend: stringToNumber,
    }),
  }),
});
