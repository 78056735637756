import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

type UseResetSupplierMutationPayload = {
  negotiationId: string;
};

export const useResetSupplierMutation = ({
  negotiationId,
}: UseResetSupplierMutationPayload) => {
  const queryClient = useQueryClient();
  const jsonApi = useJsonApiV2();

  return useMutation({
    mutationFn: resetSupplier({ jsonApi, negotiationId }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeys.negotiations.getNegotiationV2, negotiationId],
        }),
      ]);
    },
  });
};
type ResetSupplierPayload = {
  jsonApi: JsonApiV2Client;
  negotiationId: string;
};

type ResetSupplierMutatePayload = {
  negotiationSupplierId: string | undefined;
};

const resetSupplier = ({ jsonApi, negotiationId }: ResetSupplierPayload) => {
  return ({ negotiationSupplierId }: ResetSupplierMutatePayload) => {
    if (!negotiationSupplierId) {
      throw new Error('Missing negotiation supplier id');
    }

    return jsonApi.post({
      path: `/v2/negotiation-supplier/negotiations/${negotiationId}/reset-progress`,
      attributes: {
        negotiationId,
        negotiationSupplierId,
      },
    });
  };
};
