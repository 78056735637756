import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { queryKeys } from '@/modules/api/queryKeys';
import { DefaultCheckbox } from '@/modules/form/components/Checkbox/DefaultCheckbox';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useUpdateNegotiationSupplierMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationSupplierMutationV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Button } from '@/modules/theme/components/Button/Button';
import { Dialog } from '@/modules/theme/components/Dialog/Dialog';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph, Text } from '@/modules/theme/components/Typography';

type RemoveSupplierDialogProps = {
  negotiationId: string;
  supplier?: NegotiationSupplier;
  isOpen: boolean;
  onClose: () => void;
};

export const RemoveSupplierDialog = ({
  negotiationId,
  supplier,
  isOpen,
  onClose,
}: RemoveSupplierDialogProps) => {
  const { t } = useTranslation('modules/negotiations/NegotiationSuppliersList');
  const queryClient = useQueryClient();

  const [isRemoveConfirmed, setIsRemoveConfirmed] = useState(false);

  const { mutate: updateNegotiationSupplier, isPending } =
    useUpdateNegotiationSupplierMutationV2();

  const onDialogClose = () => {
    setIsRemoveConfirmed(false);
    onClose();
  };

  return (
    <Dialog
      hasCloseIcon
      onClose={onDialogClose}
      isOpen={isOpen}
      title={t('Remove supplier')}
      variant="large"
    >
      <ContentWrapperStyled>
        <Paragraph alignment="center" margins={[2, 0, 5]}>
          {t(
            'Are you sure you want to remove this supplier from this negotiation?'
          )}
        </Paragraph>
        <DefaultCheckbox
          id="isRemoveConfirmed"
          checked={isRemoveConfirmed}
          onChange={() => setIsRemoveConfirmed((prevState) => !prevState)}
        >
          <Text bold>{t('Yes I want to remove this supplier')}</Text>
        </DefaultCheckbox>
        <FlexContainer direction="column" align="center" mt={5} gap={2}>
          <Button variant="outline" onClick={onDialogClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!isRemoveConfirmed}
            loading={isPending}
            onClick={() => {
              supplier
                ? updateNegotiationSupplier(
                    {
                      negotiationId,
                      negotiationSupplierId: supplier.id,
                      value: {
                        negotiationSupplierState:
                          NegotiationSupplierStateV2.EXCLUDED,
                      },
                    },
                    {
                      onSuccess: async () => {
                        await queryClient.invalidateQueries({
                          queryKey: [
                            queryKeys.negotiations.getNegotiationV2,
                            negotiationId,
                          ],
                        });
                        onDialogClose();
                      },
                    }
                  )
                : onDialogClose();
            }}
          >
            {t('Remove supplier')}
          </Button>
        </FlexContainer>
      </ContentWrapperStyled>
    </Dialog>
  );
};

const ContentWrapperStyled = styled('div')({
  maxWidth: 370,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
