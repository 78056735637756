import * as sup from 'superstruct';

export const negotiationMetricsResourceStructV2 = sup.object({
  suggestedSuppliers: sup.number(),
  suggestedSpend: sup.number(),
  suggestedPotentialSavings: sup.number(),
  suggestedPotentialCostAvoidance: sup.number(),
  uniqueSuppliersPerNegotiationType: sup.object({
    IMPROVED_PRICES: sup.optional(sup.number()),
    LOCKED_PRICES: sup.optional(sup.number()),
    EXTRA_PAYMENT_DAYS: sup.optional(sup.number()),
    STANDARD_AGREEMENT: sup.optional(sup.number()),
    CODE_OF_CONDUCT: sup.optional(sup.number()),
    SUPPLIER_QUESTIONNAIRE: sup.optional(sup.number()),
    ISO: sup.optional(sup.number()),
    PROLONG: sup.optional(sup.number()),
    SUPPLIER_REGISTRATION: sup.optional(sup.number()),
  }),
});

export type NegotiationMetricsResourceTypeV2 = sup.Infer<
  typeof negotiationMetricsResourceStructV2
>;

export type NegotiationMetricsV2 = NegotiationMetricsResourceTypeV2;
