import { useQuery } from '@tanstack/react-query';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getCustomerQuery } from '@/modules/customer/api/queries/getCustomerQuery';
import { parseGetCustomerResponse } from '@/modules/customer/api/resources/customer/parseGetCustomerResponse';

export const useGetCustomerDataQuery = ({
  customerId,
}: {
  customerId?: string;
}) => {
  const jsonApi = useJsonApiV2();

  return useQuery({
    queryKey: [queryKeys.user.getCustomer, customerId],
    enabled: !!customerId,
    retry: false,
    queryFn: customerId
      ? getCustomerQuery(jsonApi, { customerId })
      : () => Promise.resolve(null),
    select: (response) => parseGetCustomerResponse(response),
  });
};
