import styled from 'styled-components';

import { Link } from '@/modules/theme/components/Link/Link';
import { Text } from '@/modules/theme/components/Typography';
import { dark, graphite } from '@/modules/theme/utils/colors';

export const NegotiationCardContainerLinkStyled = styled(Link.Internal)(
  ({ theme: { boxShadows, spacing, borderRadius, palette } }) => ({
    display: 'flex',
    margin: spacing([2, 0]),
    padding: spacing([3, 5, 3, 0]),
    textDecoration: 'none',
    backgroundColor: palette.default.background,
    borderRadius: borderRadius.default,
    marginBottom: spacing(2),
    boxShadow: boxShadows.tile,
    minHeight: 260,
  })
);

export const NumberOfSuppliersWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    padding: spacing([0, 5]),

    '&:after': {
      content: "''",
      position: 'absolute',
      right: 0,
      top: spacing(-1.5),
      bottom: spacing(-1.5),
      width: 1,
      backgroundColor: dark[70],
    },

    [breakpoints.down.md]: {
      padding: spacing([0, 2]),
    },
  })
);

export const NegotiationCardContentWrapper = styled('div')(
  ({ theme: { spacing } }) => ({
    flex: 1,
    paddingLeft: spacing(9),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);

export const NegotiationCardHeadingStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: spacing(2),
    [breakpoints.down.md]: {
      flexWrap: 'wrap-reverse',
    },
  })
);

export const NegotiationDatesStyled = styled(Text)({
  color: graphite,
  whiteSpace: 'nowrap',
});

export const SuppliersLabelStyled = styled(Text)(
  ({ theme: { typography } }) => ({
    fontWeight: typography.fontWeight.bold,
  })
);

export const NegotiationStatusBadgeWrapperStyled = styled('div')(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacing(3),
    [breakpoints.up.md]: {
      minWidth: 360,
    },
  })
);
