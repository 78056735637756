import { useTranslation } from 'react-i18next';

import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';

import {
  termRoleImportanceOrder,
  termRoleToIconMapper,
} from '../helpers/consts';

export const useParseTermRoles = (termRoles: NegotiationType[]) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  const termRoleToTextMapper = {
    [NegotiationType.EXTRA_PAYMENT_DAYS]: t('Extra payment days'),
    [NegotiationType.IMPROVED_PRICES]: t('Improved prices'),
    [NegotiationType.LOCKED_PRICES]: t('Locked prices'),
    [NegotiationType.CODE_OF_CONDUCT]: t('Code of Conduct'),
    [NegotiationType.SUPPLIER_QUESTIONNAIRE]: t('Supplier questionnaire'),
    [NegotiationType.STANDARD_AGREEMENT]: t('Standard agreement'),
    [NegotiationType.ISO]: t('ISO'),
    [NegotiationType.PROLONG]: t('Prolong'),
    [NegotiationType.SUPPLIER_REGISTRATION]: t('Supplier registration'),
  };

  return termRoles
    .map((termRole) => {
      const Icon = termRoleToIconMapper[termRole];
      const termRoleText = termRoleToTextMapper[termRole];
      return !!Icon && termRoleText ? { Icon, termRoleText, termRole } : null;
    })
    .filter(Boolean)
    .sort(
      (first, second) =>
        termRoleImportanceOrder[first.termRole] -
        termRoleImportanceOrder[second.termRole]
    );
};
