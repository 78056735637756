import axios from 'axios';

import { appConfig } from '@/modules/app/appConfig';

export const jsonApiClient = axios.create({
  withCredentials: true,
  timeout: Number(appConfig.api.requestTimeout),
  baseURL: appConfig.api.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const jsonApiV2Client = axios.create({
  withCredentials: true,
  timeout: Number(appConfig.api.requestTimeout),
  baseURL: appConfig.api.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});
