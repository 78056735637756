import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { useGetExclusionReasonsChartQuery } from '@/modules/customer/api/charts/useGetExclusionReasonsChartQuery';
import { Box } from '@/modules/theme/components/Box';
import { Heading } from '@/modules/theme/components/Typography';

import { DigiProcFitPoint } from '../../../AnalysisPage/components/DigiProcAreaSpread/types';
import { useGetNegotiationMetricsQueryV2 } from '../../hooks/useGetNegotiationMetricsQueryV2';
import { MetricsWrapperStyled } from '../../SuggestedNegotiations.styled';
import { SuggestedNegotiationMetrics } from '../SuggestedNegotiationMetrics/SuggestedNegotiationMetrics';
import { TotalPotentialMetrics } from '../TotalPotentialMetrics/TotalPotentialMetrics';

import { SuggestedNegotiationsDnD } from './components/SuggestedNegotiationsDnD/SuggestedNegotiationsDnD';

type SuggestedNegotiationsProps = {
  isLoading: boolean;
  isError: boolean;
};

export const SuggestedNegotiations = ({
  isLoading,
  isError,
}: SuggestedNegotiationsProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const {
    customer: { id: customerId },
  } = useSessionContext();

  const {
    data: suggestedMetricsV2,
    isFetching: isSuggestedMetricsFetchingV2,
    isError: isSuggestedMetricsErrorV2,
  } = useGetNegotiationMetricsQueryV2({
    customerId,
  });

  const {
    data: totalPotentialMetrics,
    isPending: isTotalPotentialMetricsPending,
    isError: isTotalPotentialMetricsError,
  } = useGetExclusionReasonsChartQuery();
  const digiProcFit = totalPotentialMetrics?.points.find(
    (point): point is DigiProcFitPoint => point.exclusionReason === null
  );

  return (
    <Box as="main" pl={4} pr={4} mb={2}>
      <Heading variant="h2" ml={3}>
        {t('DigiProc potential')}
      </Heading>
      <MetricsWrapperStyled>
        <SuggestedNegotiationMetrics
          metrics={suggestedMetricsV2}
          isFetching={isSuggestedMetricsFetchingV2 || isLoading}
          isError={isSuggestedMetricsErrorV2 || isError}
        />
        <TotalPotentialMetrics
          metrics={{
            currentSpend: digiProcFit?.spend.toString() || '0',
            suppliersCount: digiProcFit?.count || 0,
            potentialCostAvoidance: (
              ((digiProcFit?.spend || 0) / 0.95) *
              0.05
            ).toString(),
            potentialSavings: ((digiProcFit?.spend || 0) * 0.03).toString(),
          }}
          isFetching={isTotalPotentialMetricsPending || isLoading}
          isError={isTotalPotentialMetricsError || isError}
        />
      </MetricsWrapperStyled>
      <SuggestedNegotiationsDnD />
    </Box>
  );
};
