import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import {
  BottomDataWrapperStyled,
  DataContainerStyled,
  NegotiationDataPanelWrapperStyled,
  NumberOfSuppliersStyled,
  PanelContainerStyled,
  SignetStyled,
  SpendStyled,
} from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationDataPanel.styled';
import { NegotiationInvitedExcludedSuppliers } from '@/modules/negotiation/components/NegotiationDataPanel/NegotiationInvitedExcludedSuppliers';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { formatPercentage } from '@/modules/negotiation/utils/formatPercentage';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { getNegotiationCardMetricsV2 } from '@/pages/DashboardPage/components/NegotiationCards/NegotiationCard/getNegotiationCardMetricsV2';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { formatAmount } from '@/utils/stringUtils';

import { CompletedNegotiationMetrics } from './CompletedNegotiationMetrics';
import { CompletedNegotiationMetricsLoader } from './CompletedNegotiationMetricsLoader';

type CompletedNegotiationDataPanelProps = {
  negotiation: NegotiationResourceTypeV2 | undefined;
  shouldDisplayLoader: boolean | undefined;
};

export const CompletedNegotiationDataPanel = ({
  negotiation,
  shouldDisplayLoader = false,
}: CompletedNegotiationDataPanelProps) => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');

  const { customer } = useSessionContext();

  if (!negotiation) {
    return (
      <CompletedNegotiationDataPanelWrapperStyled>
        <PanelContainerStyled>
          <DataContainerStyled>
            <CompletedNegotiationMetricsLoader />
          </DataContainerStyled>
          <SkeletonLoader height={33} />
        </PanelContainerStyled>
      </CompletedNegotiationDataPanelWrapperStyled>
    );
  }

  const suggestedMetrics = calculateNegotiationMetrics(negotiation);
  const activeMetrics = negotiation.attributes.negotiationMetrics;
  const metricsToDisplay = getNegotiationCardMetricsV2(negotiation);

  return (
    <CompletedNegotiationDataPanelWrapperStyled>
      <PanelContainerStyled>
        <DataContainerStyled>
          {shouldDisplayLoader ? (
            <CompletedNegotiationMetricsLoader />
          ) : (
            negotiation &&
            negotiation.attributes.negotiationMetrics && (
              <CompletedNegotiationMetrics negotiation={negotiation} />
            )
          )}
          <SignetStyled />
        </DataContainerStyled>
        {metricsToDisplay.has(negotiationMetrics.averageDiscount) && (
          <Paragraph variant="contentBold" color={dark[400]} alignment="right">
            {t('Average discount {{percentage}}%', {
              percentage: formatPercentage(activeMetrics?.averageDiscount ?? 0),
            })}
          </Paragraph>
        )}
        {shouldDisplayLoader ? (
          <SkeletonLoader height={33} />
        ) : (
          negotiation && (
            <BottomDataWrapperStyled>
              <div>
                <NumberOfSuppliersStyled variant="h3" as="span">
                  {t('{{count}} suppliers', {
                    count: suggestedMetrics.supplierCountWithExcluded,
                  })}
                </NumberOfSuppliersStyled>
                <NegotiationInvitedExcludedSuppliers
                  suppliersCount={suggestedMetrics.supplierCountWithExcluded}
                  excludedCount={
                    activeMetrics?.supplierStatesCount?.EXCLUDED ?? 0
                  }
                  negotiationId={negotiation.id}
                />
              </div>
              <SpendStyled>
                {t('Spend {{spend}} {{currency}}', {
                  spend: formatAmount(suggestedMetrics.totalSpend),
                  currency: customer.currency,
                })}
              </SpendStyled>
            </BottomDataWrapperStyled>
          )
        )}
      </PanelContainerStyled>
    </CompletedNegotiationDataPanelWrapperStyled>
  );
};

export const CompletedNegotiationDataPanelWrapperStyled = styled(
  NegotiationDataPanelWrapperStyled
)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));
