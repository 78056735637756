import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationQueryParamsV2 } from '../../hooks/useGetNegotiationV2Query';
import { getNegotiationResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStructV2 } from '../resources/negotiation/negotiationResourceStructV2';

export const getNegotiationQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationQueryParamsV2
) => {
  return async () => {
    const result = await jsonApi.get({
      path: getNegotiationResourcePathV2(queryParams.negotiationId),
      queryParams,
      responseStruct: getNegotiationResponseStructV2,
    });
    return negotiationResourceStructV2.mask(result.data);
  };
};

export type GetNegotiationResponseTypeV2 = sup.Infer<
  typeof negotiationResourceStructV2
>;

const getNegotiationResponseStructV2 = sup.object({
  data: negotiationResourceStructV2,
});
