import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { negotiationMetricsResourceStructV2 } from '../resources/metrics/negotiationMetricsResourceStructV2';

type GetCustomerMetricsQueryPayload = {
  customerId?: string;
};
export const getNegotiationMetricsQueryV2 = (
  jsonApi: JsonApiV2Client,
  payload?: GetCustomerMetricsQueryPayload
) => {
  return async () => {
    const queryParams = {
      customerId: payload?.customerId,
    };
    const response = await jsonApi.get({
      path: `/v2/negotiations/metrics`,
      queryParams,
      responseStruct: negotiationMetricsResourceResponseStructV2,
    });
    const validatedResponse = negotiationMetricsResourceStructV2.mask(
      response.data
    );
    return validatedResponse;
  };
};

export type GetCustomerMetricsResponseTypeV2 = sup.Infer<
  typeof negotiationMetricsResourceStructV2
>;

export const negotiationMetricsResourceResponseStructV2 = sup.object({
  data: negotiationMetricsResourceStructV2,
});
