import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { NegotiationMetrics } from '@/utils/calculate-negotiation-metrics';

type PlannedNegotiationMetricsProps = {
  metrics: NegotiationMetrics;
};

export const PlannedNegotiationMetrics = ({
  metrics,
}: PlannedNegotiationMetricsProps) => {
  const { t } = useTranslation('pages/PlannedNegotiationPage');
  const { customer } = useSessionContext();

  return (
    <PlannedNegotiationMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metrics.potentialSavings && (
          <NegotiationMetric
            heading={t('Potential savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialSavings}
          />
        )}

        {metrics.potentialCostAvoidance && (
          <NegotiationMetric
            heading={t('Potential cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.potentialCostAvoidance}
          />
        )}
      </FinancialMetricsWrapperStyled>

      {metrics.suppliersCount && (
        <NegotiationMetric
          variant="counter"
          heading={t('Nr of suppliers')}
          data={metrics.suppliersCount}
        />
      )}
      {/* <NegotiationTermsPanel termRoles={termRoles} /> */}
    </PlannedNegotiationMetricsWrapperStyled>
  );
};

const PlannedNegotiationMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(6),
  })
);

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    '&:empty': {
      marginRight: spacing(-6),
    },
  })
);
