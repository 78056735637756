import { NegotiationStateV2 } from '../types/negotiationTypes';

export const START_DATE_WEEK_DAY_INDEX = 3;
export const DAYS_TO_NEGOTIATION_END_DATE = 9;

/** There are also

DRAFT = 'draft'
ESTIMATED = 'estimated'

negotiation states, but we don't want to include them
on dashboard as they're only valuable on Analysis & Offer page
*/
export const launchedNegotiationStateFilters = [
  NegotiationStateV2.SUGGESTED,
  NegotiationStateV2.IN_SETUP,
  NegotiationStateV2.TO_BE_APPROVED,
  NegotiationStateV2.PLANNED,
  NegotiationStateV2.IN_PROGRESS,
  NegotiationStateV2.COMPLETED,
];

export const negotiationWithProgressSuppliersOrders = [
  'name',
  '-name',
  'currentSpend',
  '-currentSpend',
  'negotiationProgress.state',
  '-negotiationProgress.state',
  'category.name',
  '-category.name',
  'defaultContact.email',
  '-defaultContact.email',
] as const;
