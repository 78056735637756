import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { parseGetUserDetailsResponse } from '@/modules/auth/api/helpers/parseGetUserDetailsResponse';
import { getUserQuery } from '@/modules/auth/api/queries/getUserQuery';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useGetUserDataQuery = () => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetUserDataQuery');

  return useQuery({
    queryKey: [queryKeys.user.getUserDetails],
    enabled: false,
    queryFn: getUserQuery(jsonApi),
    select: (response) => parseGetUserDetailsResponse(response),
    retry: false,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session expired. Please sign in'),
        },
      },
    }),
  });
};
