import { useTranslation } from 'react-i18next';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { formatPercentage } from '@/modules/negotiation/utils/formatPercentage';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Paragraph } from '@/modules/theme/components/Typography';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { formatAmount } from '@/utils/stringUtils';

import { NegotiationCardLayout } from '../NegotiationCardLayout/NegotiationCardLayout';

import { getNegotiationCardMetricsV2 } from './getNegotiationCardMetricsV2';
import { DataWrapperStyled, NumberStyled } from './NegotiationCard.styled';

type NegotiationAfterStartCardProps = {
  negotiation: NegotiationResourceTypeV2;
  link?: string;
};

export const NegotiationAfterStartCard = ({
  negotiation,
  link,
}: NegotiationAfterStartCardProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { currency },
  } = useSessionContext();

  const suggestedMetrics = calculateNegotiationMetrics(negotiation);
  const activeMetrics = negotiation.attributes.negotiationMetrics;
  const metricsToDisplay = getNegotiationCardMetricsV2(negotiation);

  const registeredCount =
    (activeMetrics?.supplierStatesCount?.NEGOTIATION_STARTED || 0) +
    (activeMetrics?.supplierStatesCount?.NEGOTIATION_COMPLETED || 0);

  const visitedCount =
    registeredCount + (activeMetrics?.supplierStatesCount?.VISITED || 0);

  return (
    <NegotiationCardLayout
      link={link}
      negotiation={negotiation}
      metrics={suggestedMetrics}
    >
      <FlexContainer wrap="wrap" columnGap={15}>
        {metricsToDisplay.has(negotiationMetrics.negotiatedSpend) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(activeMetrics?.negotiatedSpend || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiated spend ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedCostAvoidance) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(activeMetrics?.savedByLockedPrices || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiated cost avoidance ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.discountCount) && (
          <DataWrapperStyled>
            <NumberStyled>{activeMetrics?.counts.discount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Discounts')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedSavings) && (
          <DataWrapperStyled>
            <NumberStyled>
              {formatAmount(activeMetrics?.savedAmountTotal || 0)}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Negotiated savings ({{currency}})', {
                currency,
              })}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.averageDiscount) && (
          <DataWrapperStyled>
            <NumberStyled>
              {t('{{averageDiscount}}%', {
                averageDiscount: formatPercentage(
                  activeMetrics?.averageDiscount || 0
                ),
              })}
            </NumberStyled>
            <Paragraph variant="contentBold">{t('Avg. discount')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.signCodeOfConduct
        ) && (
          <DataWrapperStyled>
            <NumberStyled>{activeMetrics?.counts.acceptedCoc}</NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted code of conduct')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.lockPrice
        ) && (
          <DataWrapperStyled>
            <NumberStyled>{activeMetrics?.counts.lockedPrice}</NumberStyled>
            <Paragraph variant="contentBold">{t('Locked prices')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {activeMetrics?.counts.answeredQuestionnaire}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Answered questionnaires')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {activeMetrics?.counts.acceptedExtraPaymentDays}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted payment days')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendContractPeriod
        ) && (
          <DataWrapperStyled>
            <NumberStyled>
              {activeMetrics?.counts.acceptedExtraPaymentDays}
            </NumberStyled>
            <Paragraph variant="contentBold">
              {t('Accepted prolongation')}
            </Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.registeredCount) && (
          <DataWrapperStyled>
            <NumberStyled>{registeredCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Registered')}</Paragraph>
          </DataWrapperStyled>
        )}

        {metricsToDisplay.has(negotiationMetrics.visitedCount) && (
          <DataWrapperStyled>
            <NumberStyled>{visitedCount}</NumberStyled>
            <Paragraph variant="contentBold">{t('Visited')}</Paragraph>
          </DataWrapperStyled>
        )}
      </FlexContainer>
    </NegotiationCardLayout>
  );
};
