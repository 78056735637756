import { GetCustomerResponse } from '../../queries/getCustomerQuery';

export function parseGetCustomerResponse(response: GetCustomerResponse | null) {
  if (!response) {
    return null;
  }

  const {
    data: { id, attributes },
  } = response;
  return { id, ...attributes };
}
