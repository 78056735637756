import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationSupplierResponsePDFQueryParams } from '../../hooks/useGetNegotiationSupplierResponsePDFQuery';
import { getNegotiationSupplierResponsePDFResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

export const getNegotiationSupplierResponsePDFQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationSupplierResponsePDFQueryParams
) => {
  return async () => {
    const result = await jsonApi.get({
      path: getNegotiationSupplierResponsePDFResourcePathV2(),
      queryParams,
      responseStruct: getNegotiationSupplierResponsePDFResponseStructV2,
      axiosConfig: {
        responseType: 'blob',
      },
    });

    return result.data as Blob;
  };
};

const getNegotiationSupplierResponsePDFResponseStructV2 = sup.object({
  data: sup.any(),
});
