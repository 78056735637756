import styled from 'styled-components';

import { ReactComponent as CheckRoundIcon } from '@/assets/icons/checkRoundIcon.svg';
import { BulletList } from '@/modules/theme/components/BulletList';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

export const CheckRoundIconStyled = styled(CheckRoundIcon)(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(0.5),
    marginRight: spacing(3),
  })
);

export const SectionStyled = styled('div')(({ theme: { spacing } }) => ({
  margin: spacing([2, 0, 7]),
}));

export const BulletListStyled = styled(BulletList)(
  ({ theme: { spacing, typography } }) => ({
    margin: spacing(2),
    fontWeight: typography.fontWeight.bold,
    lineHeight: typography.fontSize.large,
    letterSpacing: typography.letterSpacing.default,
  })
);

export const SkeletonLoaderStyled = styled(SkeletonLoader)({
  height: 30,
  width: 130,
});

export const NoTermsInfoHeadingStyled = styled(Heading)({
  textAlign: 'center',
});
