import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { AnonymousLayout } from '@/layouts/AnonymousLayout/AnonymousLayout';
import { AuthPage } from '@/pages/AuthPage/AuthPage';

import { anonymousRoutes } from '../anonymousRoutes';

import { anonymousFallthroughRoutes } from './utils/fallthroughRoutes';

export const AnonymousRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route element={<AnonymousLayout />}>
        <Route
          path={anonymousRoutes.config.signIn.path}
          element={<AuthPage />}
        />
        <Route path="/auth/reset-password" element={<AuthPage />} />
      </Route>
      <Route
        path="*"
        element={
          <Navigate
            to={anonymousRoutes.config.signIn.path}
            replace
            state={{ redirectTo: location }}
          />
        }
      />
      {anonymousFallthroughRoutes(anonymousRoutes.config.signIn.path)}
    </Routes>
  );
};
