import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApi } from '@/modules/api/hooks/useJsonApi';
import { queryKeys } from '@/modules/api/queryKeys';
import { getCustomerMetricsQuery } from '@/modules/customer/api/queries/getCustomerMetricsQuery';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export const useGetCustomerMetricsQuery = () => {
  const jsonApi = useJsonApi();
  const { t } = useTranslation('apiMessages/useGetCustomerMetricsQuery');

  return useQuery({
    queryKey: [queryKeys.customer.getCustomerMetrics],
    queryFn: getCustomerMetricsQuery(jsonApi),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view metrics"),
        },
      },
    }),
  });
};
