import { useTranslation } from 'react-i18next';

import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';

import { useNegotiationStatusLabels } from '../../utils/useNegotiationStatusLabels';

import {
  ContainerStyled,
  FilterNegotiationsInfoStyled,
  FiltersContainerStyled,
} from './NegotiationStateFilter.styled';
import { NegotiationStateFilterItem } from './NegotiationStateFilterItem/NegotiationStateFilterItem';

type NegotiationStateFilterProps = {
  negotiationStateCounts: Record<NegotiationStateV2, number> | undefined;
  isLoading: boolean;
  chosenStates: NegotiationStateV2[];
  onStateToggle: (filter: NegotiationStateV2) => void;
};

export const NegotiationStateFilter = ({
  negotiationStateCounts,
  isLoading,
  chosenStates,
  onStateToggle,
}: NegotiationStateFilterProps) => {
  const { t } = useTranslation('pages/DashboardPage');

  const getNegotiationLabel = useNegotiationStatusLabels();

  const states = [
    {
      count: negotiationStateCounts?.SUGGESTED,
      variant: NegotiationStateV2.SUGGESTED,
    },
    {
      count: negotiationStateCounts?.IN_SETUP,
      variant: NegotiationStateV2.IN_SETUP,
    },
    {
      count: negotiationStateCounts?.TO_BE_APPROVED,
      variant: NegotiationStateV2.TO_BE_APPROVED,
    },
    {
      count: negotiationStateCounts?.PLANNED,
      variant: NegotiationStateV2.PLANNED,
    },
    {
      count: negotiationStateCounts?.IN_PROGRESS,
      variant: NegotiationStateV2.IN_PROGRESS,
    },
    {
      count: negotiationStateCounts?.COMPLETED,
      variant: NegotiationStateV2.COMPLETED,
    },
  ];

  return (
    <ContainerStyled>
      <FilterNegotiationsInfoStyled>
        {t('Filter negotiations')}
      </FilterNegotiationsInfoStyled>
      <FiltersContainerStyled>
        {states.map(({ count, variant }) => (
          <NegotiationStateFilterItem
            id={`filter-${variant}`}
            name="negotiationFilter"
            key={variant}
            count={count || 0}
            variant={variant}
            label={getNegotiationLabel(variant)}
            defaultChecked={chosenStates.includes(variant)}
            onChange={onStateToggle}
            isLoading={isLoading}
          />
        ))}
      </FiltersContainerStyled>
    </ContainerStyled>
  );
};
