import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';
import { createResponseStruct } from '@/modules/api/utils/jsonApiStructs';

import { userResourcePath } from '../resources/user/userResourceConsts';
import { userResourceStruct } from '../resources/user/userResourceStruct';

export const getUserQuery = (jsonApi: JsonApiV2Client) => {
  return () =>
    jsonApi.get({
      path: userResourcePath,
      responseStruct: getUserResponseStruct,
    });
};

const getUserResponseStruct = createResponseStruct({
  data: userResourceStruct,
});

export type GetUserResponse = sup.Infer<typeof getUserResponseStruct>;
