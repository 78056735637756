import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NegotiationLayout } from '@/layouts/NegotiationLayout/NegotiationLayout';
import { ProgressOfNegotiationSkeleton } from '@/modules/negotiation/components/ProgressOfNegotiation/components/ProgressOfNegotiationSkeleton';
import {
  NegotiationPageQueryParams,
  StartedNegotiationSuppliersList,
} from '@/modules/negotiation/components/StartedNegotiationSuppliersList/StartedNegotiationSuppliersList';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import {
  HeaderButton,
  HeaderButtonLink,
} from '@/modules/theme/components/HeaderButton';
import { PageMeta } from '@/modules/theme/components/PageMeta';

import { AuthenticatedNotFoundPage } from '../AuthenticatedNotFoundPage';

import {
  ButtonLinksWrapperStyled,
  ParagraphStyled,
} from './CompletedNegotiationPage.styled';
import { CompletedNegotiationDataPanel } from './components/CompletedNegotiationDataPanel';

const ProgressOfNegotiation = lazy(
  () =>
    import(
      '@/modules/negotiation/components/ProgressOfNegotiation/ProgressOfNegotiation'
    )
);

type CompletedNegotiationPageProps = {
  queryParams: NegotiationPageQueryParams;
  negotiationId: string;
};

export const CompletedNegotiationPage = ({
  queryParams,
  negotiationId,
}: CompletedNegotiationPageProps) => {
  const { t } = useTranslation('pages/CompletedNegotiationPage');
  const navigate = useNavigate();

  // eslint-disable-next-line no-console
  console.log('queryParams', queryParams);

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    isError,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });

  if (isError) {
    return <AuthenticatedNotFoundPage />;
  }

  return (
    <NegotiationLayout
      negotiationName={negotiation?.attributes.name}
      negotiationState={
        negotiation?.attributes.state as unknown as NegotiationStateV2
      }
      isLoading={isNegotiationPending}
      buttonLink={
        <ButtonLinksWrapperStyled>
          {/* Removed for now (we don't want people to download the contactinfo without being a paying customer) */}
          {/* <SupplierExportButton negotiationId={negotiationId} /> */}
          <HeaderButtonLink
            to={onboardedRoutes.buildUrl('cpoLetter', {
              params: { negotiationId },
            })}
          >
            {t('Invitation letter')}
          </HeaderButtonLink>

          <HeaderButton onClick={() => navigate(-1)}>{t('Back')}</HeaderButton>
        </ButtonLinksWrapperStyled>
      }
    >
      <>
        <PageMeta
          title={t('Completed negotiation')}
          description={t('Completed negotiation')}
        />
        {negotiation?.attributes && (
          <ParagraphStyled>
            {t('Started {{startDate}} - Ended {{endDate}}', {
              startDate: negotiation.attributes.startDate?.toLocaleDateString(),
              endDate: negotiation.attributes.endDate?.toLocaleDateString(),
            })}
          </ParagraphStyled>
        )}

        <CompletedNegotiationDataPanel
          negotiation={negotiation}
          shouldDisplayLoader={isNegotiationPending}
        />

        <Box paddings={[0, 8, 7]}>
          <Suspense
            fallback={
              <ProgressOfNegotiationSkeleton
                title={t('Results of the negotiation')}
              />
            }
          >
            <ProgressOfNegotiation negotiationId={negotiationId} />
          </Suspense>

          {negotiation && (
            <StartedNegotiationSuppliersList
              negotiation={negotiation}
              queryParams={queryParams}
              isLoading={isNegotiationPending}
              isNegotiationActive
            />
          )}
        </Box>
      </>
    </NegotiationLayout>
  );
};
