import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticatedLayout } from '@/layouts/AuthenticatedLayout/AuthenticatedLayout';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { launchedNegotiationStateFilters } from '@/modules/negotiation/utils/consts';
import { Box } from '@/modules/theme/components/Box';
import { PageHeader } from '@/modules/theme/components/PageHeader/PageHeader';
import { PageMeta } from '@/modules/theme/components/PageMeta';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';

import { useGetNegotiationsQueryV2 } from '../AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/hooks/useGetNegotiationsV2Query';

import { CustomerMetricsPanel } from './components/CustomerMetricsPanel/CustomerMetricsPanel';
import { NegotiationAfterStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationAfterStartCard';
import { NegotiationBeforeStartCard } from './components/NegotiationCards/NegotiationCard/NegotiationBeforeStartCard';
import { NegotiationStateFilter } from './components/NegotiationStateFilter/NegotiationStateFilter';
import { ContentWrapperStyled, LogoStyled } from './DashboardPage.styled';
import { getNegotiationDetailsPath } from './utils/getNegotiationDetailsPath';
import { calculateNegotiationsMetrics } from './utils/sum-negotiations-metrics';

type DashboardPageProps = {
  chosenNegotiationStates: NegotiationStateV2[];
  setNegotiationState: (negotiationState: NegotiationStateV2[]) => void;
  negotiationStateCounts: Record<NegotiationStateV2, number> | undefined;
};

export const DashboardPage = ({
  chosenNegotiationStates,
  setNegotiationState,
  negotiationStateCounts,
}: DashboardPageProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const {
    customer: { id: customerId },
  } = useSessionContext();

  const [metrics, setMetrics] = useState<
    ActiveNegotiationMetricsType | undefined
  >(undefined);

  const toggleState = (pickedState: NegotiationStateV2) => {
    const wasPicked = chosenNegotiationStates.includes(pickedState);

    const updatedNegotiationState = wasPicked
      ? chosenNegotiationStates.filter((filter) => filter !== pickedState)
      : [...chosenNegotiationStates, pickedState];

    setNegotiationState(updatedNegotiationState);
  };

  const { data, isPending, isPlaceholderData } = useGetNegotiationsQueryV2(
    {
      page: 1,
      limit: 100,
      customerId,
      includeNegotiationMetrics: true,
      states: chosenNegotiationStates.length
        ? chosenNegotiationStates
        : launchedNegotiationStateFilters,
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 5000,
      refetchInterval: 60_000,
    }
  );

  useEffect(() => {
    if (data && !isPending) {
      const negotiationMetrics = data.data.items
        .filter(
          (item) =>
            !(
              (item.attributes.state === NegotiationStateV2.DEMO ||
                item.attributes.state === NegotiationStateV2.SUGGESTED) &&
              (item.attributes.suggestedPeriod === undefined ||
                item.attributes.suggestedPeriod < 0)
            )
        )
        .map((item) => item.attributes.negotiationMetrics)
        .filter((item) => item !== undefined);

      const metrics = calculateNegotiationsMetrics(
        negotiationMetrics as ActiveNegotiationMetricsType[]
      );

      setMetrics(metrics);
    }
  }, [data, isPending]);

  return (
    <AuthenticatedLayout>
      <PageMeta title={t('Dashboard')} description={t('Dashboard')} />
      <PageHeader text={t('Dashboard')} />
      <ContentWrapperStyled>
        <LogoStyled />
        <CustomerMetricsPanel metrics={metrics} isLoading={isPending} />
        <NegotiationStateFilter
          negotiationStateCounts={negotiationStateCounts}
          isLoading={isPending}
          onStateToggle={toggleState}
          chosenStates={chosenNegotiationStates}
        />
        {isPending || isPlaceholderData ? (
          <SkeletonLoader lines={3} height={260} />
        ) : (
          data && (
            <Box mt={6}>
              {data.data.items
                .filter(
                  (item) =>
                    !(
                      (item.attributes.state === NegotiationStateV2.DEMO ||
                        item.attributes.state ===
                          NegotiationStateV2.SUGGESTED) &&
                      (item.attributes.suggestedPeriod === undefined ||
                        item.attributes.suggestedPeriod < 0)
                    )
                )
                .map((negotiation) => {
                  const hasNegotiationStarted =
                    negotiation.attributes.state ===
                      NegotiationStateV2.COMPLETED ||
                    negotiation.attributes.state ===
                      NegotiationStateV2.IN_PROGRESS;
                  return hasNegotiationStarted ? (
                    <NegotiationAfterStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation)}
                      key={negotiation.id}
                    />
                  ) : (
                    <NegotiationBeforeStartCard
                      negotiation={negotiation}
                      link={getNegotiationDetailsPath(negotiation)}
                      key={negotiation.id}
                    />
                  );
                })}
            </Box>
          )
        )}
      </ContentWrapperStyled>
    </AuthenticatedLayout>
  );
};
