import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { SelectInput } from '@/modules/form/components/Select/SelectInput/SelectInput';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';

type SupplierProgressSteppFilterProps = {
  progressStep: string;
  onChange: (value: NegotiationSupplierStateV2 | 'null' | undefined) => void;
};

export const SupplierProgressStepFilter = ({
  progressStep,
  onChange,
}: SupplierProgressSteppFilterProps) => {
  const { t } = useTranslation('modules/suppliers/SuppliersFilters');

  const options: {
    label: string;
    value: NegotiationSupplierStateV2 | 'null';
  }[] = [
    { label: t('All'), value: 'null' },
    {
      label: t('Completed process'),
      value: NegotiationSupplierStateV2.NEGOTIATION_COMPLETED,
    },
    {
      label: t('Registered'),
      value: NegotiationSupplierStateV2.NEGOTIATION_STARTED,
    },
    {
      label: t('Visited'),
      value: NegotiationSupplierStateV2.VISITED,
    },
    {
      label: t('Opened email'),
      value: NegotiationSupplierStateV2.OPENED_EMAIL,
    },
    {
      label: t('Email not Delivered'),
      value: NegotiationSupplierStateV2.NOT_DELIVERED,
    },
    {
      label: t('Not opened email'),
      value: NegotiationSupplierStateV2.INVITED,
    },
    {
      label: t('Excluded'),
      value: NegotiationSupplierStateV2.EXCLUDED,
    },
  ];

  return (
    <SelectInput
      name="progressStep"
      label={t('Progress step')}
      options={options}
      showErrorMessage={false}
      value={findSelectValue({
        currentValue: progressStep,
        options,
      })}
      placeholder={t('Choose...')}
      onChange={(option) => onChange(option?.value)}
    />
  );
};
