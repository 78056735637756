import styled from 'styled-components';

import { ReactComponent as Signet } from '@/assets/signet.svg';
import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Paragraph } from '@/modules/theme/components/Typography';

export const ContentWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing([4, 6, 9]),
  position: 'relative',
}));

export const SignetStyled = styled(Signet)(({ theme: { zIndexes } }) => ({
  position: 'absolute',
  right: 22,
  top: 0,
  zIndex: zIndexes.background,
  width: 450,
}));

export const ParagraphStyled = styled(Paragraph)(({ theme: { spacing } }) => ({
  maxWidth: 730,
  marginBottom: spacing(4),
}));

export const ParagraphMtStyled = styled(Paragraph)(
  ({ theme: { spacing } }) => ({
    maxWidth: 730,
    marginTop: spacing(4),
  })
);

export const ButtonsWrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(2),
}));

export const ButtonsSkeletonStyled = styled(SkeletonLoader)(
  ({ theme: { breakpoints } }) => ({
    height: 56,
    width: 650,
    marginBottom: 0,
    [breakpoints.down.md]: {
      width: '100%',
    },
  })
);
