import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findSelectValue } from '@/modules/form/components/Select/findSelectValue';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { SuppliersFilters } from '@/modules/suppliers/components/SuppliersFilters';
import { defaultSuppliersQueryParams } from '@/modules/suppliers/helpers/defaultSuppliersQueryParams';
import { SuppliersOrder } from '@/modules/suppliers/utils/suppliersOrders';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Pagination } from '@/modules/theme/components/Pagination/Pagination';
import { ShowPerPageDropdown } from '@/modules/theme/components/ShowPerPageDropdown/ShowPerPageDropdown';
import { Heading } from '@/modules/theme/components/Typography';
import { useDebouncedState } from '@/utils/useDebounced';
import { useDialogState } from '@/utils/useDialogState';

import { AddSupplierDialog } from './components/AddSupplierDialog/AddSupplierDialog';
import { SuppliersTable } from './components/SuppliersTable';
import {
  AddSupplierButtonStyled,
  SkeletonLoaderStyled,
} from './Suppliers.styled';

type SuppliersQueryParams = {
  page?: number;
  size?: number;
  sort?: SuppliersOrder;
  searchQuery?: string;
  businessUnit?: string[];
  category?: string[];
};

type SuppliersProps = {
  queryParams: SuppliersQueryParams;
  negotiationId: string;
};

export const Suppliers = ({ queryParams, negotiationId }: SuppliersProps) => {
  const { t } = useTranslation('pages/SuppliersTab');

  const addSupplierDialog = useDialogState();

  const setQueryParams = setupNegotiationRoutes.useSetQueryParams('suppliers');

  const [selectedSuppliers, setSelectedSuppliers] = useState<
    NegotiationSupplier[]
  >([]);
  const [selectedSupplierCount, setSelectedSupplierCount] = useState(0);

  const searchQuery = useMemo(
    () => queryParams.searchQuery || '',
    [queryParams.searchQuery]
  );
  const order = useMemo(
    () => queryParams.sort || defaultSuppliersQueryParams.sort,
    [queryParams.sort]
  );
  const categoriesFilter = useMemo(
    () => queryParams.category || [],
    [queryParams.category]
  );
  const pageNumber = useMemo(() => queryParams.page || 1, [queryParams.page]);
  const pageSize = useMemo(
    () => queryParams.size || defaultSuppliersQueryParams.size,
    [queryParams.size]
  );

  const {
    data: negotiation,
    isPending: isNegotiationPending,
    isSuccess: isNegotiationSuccess,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebouncedState(
    searchQuery,
    { wait: 1000 }
  );

  useEffect(() => {
    if (!queryParams.page || !queryParams.size || !queryParams.sort) {
      setQueryParams({
        page: pageNumber,
        size: pageSize,
        sort: order,
      });
    }
  });

  useEffect(() => {
    if (negotiation && negotiation.attributes.negotiationSuppliers) {
      const suppliers: NegotiationSupplier[] = [];

      // filter suppliers by filterOptions
      negotiation.attributes.negotiationSuppliers
        .filter(
          (supplier) =>
            supplier.attributes.negotiationSupplierState !==
              NegotiationSupplierStateV2.EXCLUDED &&
            supplier.attributes.negotiationSupplierState !==
              NegotiationSupplierStateV2.REMOVED
        )
        .filter((supplier) => {
          if (
            debouncedSearchQuery &&
            !(
              (supplier.attributes.freezedSupplierName ||
                supplier.attributes.supplier?.name) ??
              ''
            )
              .toLowerCase()
              .includes(debouncedSearchQuery.toLocaleLowerCase())
          ) {
            return false;
          }
          if (
            categoriesFilter.length &&
            (!supplier.attributes.supplier?.category ||
              !categoriesFilter.includes(
                supplier.attributes.supplier?.category
              ))
          ) {
            return false;
          }

          suppliers.push(supplier);
        });

      // sort by order option
      suppliers.sort((a, b) => {
        const nameA =
          (a.attributes.freezedSupplierName || a.attributes.supplier?.name) ??
          '';
        const nameB =
          (b.attributes.freezedSupplierName || b.attributes.supplier?.name) ??
          '';
        const categoryA = a.attributes.supplier?.category ?? '';
        const categoryB = b.attributes.supplier?.category ?? '';

        switch (order) {
          case 'name':
            return (
              nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) ||
              0
            );
          case '-name':
            return (
              nameB.localeCompare(nameA, undefined, { sensitivity: 'base' }) ||
              0
            );
          case 'currentSpend':
            return (
              (a.attributes.supplier?.spend || 0) -
              (b.attributes.supplier?.spend || 0)
            );
          case '-currentSpend':
            return (
              (b.attributes.supplier?.spend || 0) -
              (a.attributes.supplier?.spend || 0)
            );
          case 'category.name':
            return categoryA.localeCompare(categoryB, undefined, {
              sensitivity: 'base',
            });
          case '-category.name':
            return categoryB.localeCompare(categoryA, undefined, {
              sensitivity: 'base',
            });

          default:
            return 0;
        }
      });

      setSelectedSupplierCount(suppliers.length);

      // get page of suppliers
      const startIndex = (pageNumber - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const suppliersPage = suppliers.slice(startIndex, endIndex);

      setSelectedSuppliers(suppliersPage);
    }
  }, [
    categoriesFilter,
    debouncedSearchQuery,
    negotiation,
    negotiation?.attributes.negotiationSuppliers,
    order,
    pageNumber,
    pageSize,
    setSelectedSuppliers,
  ]);

  const paginationProps = {
    perPage: pageSize,
    totalCount: selectedSupplierCount ?? 0,
    onPageChange: (selectedPage: number) => setPageNumber(selectedPage + 1),
    forcePage: pageNumber - 1,
  };

  const setPageNumber = useCallback(
    (pageNumber: number) =>
      setQueryParams({
        page: pageNumber,
      }),
    [setQueryParams]
  );

  const setPageSizeChange = useCallback(
    (pageSize: number) => {
      setQueryParams({
        size: pageSize,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setSearchQuery = useCallback(
    (query: string) => {
      setQueryParams({
        searchQuery: query || undefined,
        page: 1,
      });
      setDebouncedSearchQuery(query);
    },
    [setQueryParams, setDebouncedSearchQuery]
  );

  const setCategories = useCallback(
    (categories: string[]) => {
      setQueryParams({
        category: categories?.length ? categories : undefined,
        page: 1,
      });
    },
    [setQueryParams]
  );

  const setOrder = useCallback(
    (sortRule: SuppliersOrder | undefined) => {
      setQueryParams({ sort: sortRule });
    },
    [setQueryParams]
  );

  return (
    <>
      <Heading variant="h3" as="h2" mb={3}>
        {t('Choose which suppliers you would like to negotiate with')}
      </Heading>
      <FlexContainer justify="space-between">
        <SuppliersFilters
          isLoading={isNegotiationPending}
          searchQuery={searchQuery}
          categoriesFilter={categoriesFilter}
          order={order}
          onSearchQueryChange={setSearchQuery}
          onOrderChange={setOrder}
          onCategoryChange={setCategories}
        />
        <FlexContainer direction="column" justify="space-between">
          <Box mb={2}>
            <ShowPerPageDropdown
              options={pageSizeOptions}
              selectedPageSize={pageSize}
              onPageSizeChange={setPageSizeChange}
              defaultValue={findSelectValue({
                currentValue: pageSize,
                options: pageSizeOptions,
              })}
            />
          </Box>
          <AddSupplierButtonStyled
            variant="outline"
            size="small"
            onClick={addSupplierDialog.open}
          >
            {t('Add supplier +')}
          </AddSupplierButtonStyled>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer justify="center" margins={[4, 0, 3]}>
        {isNegotiationPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>

      <SuppliersTable
        suppliers={selectedSuppliers}
        isSuccess={isNegotiationSuccess}
        pageSize={pageSize}
        isLoading={isNegotiationPending}
        negotiationId={negotiationId}
      />

      <FlexContainer justify="center" margins={[5, 0]}>
        {isNegotiationPending ? (
          <SkeletonLoaderStyled />
        ) : paginationProps.totalCount ? (
          <Pagination {...paginationProps} />
        ) : null}
      </FlexContainer>
      {negotiation && (
        <AddSupplierDialog
          negotiation={negotiation}
          isOpen={addSupplierDialog.isOpen}
          onClose={addSupplierDialog.close}
        />
      )}
    </>
  );
};

const pageSizeOptions = [20, 50, 100].map((value) => ({
  value,
  label: value.toString(),
}));
