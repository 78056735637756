import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationEmlFileQueryV2 } from '@/modules/negotiation/api/queries/getNegotiationEmlFileQueryV2';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export type GetNegotiationEmlFileQueryParamsV2 = {
  negotiationId: string;
};

export const useGetNegotiationEmlFileV2Query = (
  params: GetNegotiationEmlFileQueryParamsV2
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetNegotiationEmlFileQuery');
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationEmlFile,
      params.negotiationId,
    ],
    queryFn: getNegotiationEmlFileQueryV2(jsonApi, params),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiation"),
        },
      },
    }),
  });
};
