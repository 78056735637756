import { GetUserResponse } from '../queries/getUserQuery';

export function parseGetUserDetailsResponse(response: GetUserResponse) {
  const {
    data: {
      id,
      attributes: { name, relationId, relationType },
    },
  } = response;

  return { userId: id, name, relationId, relationType };
}
