import { createRouting, defineRoute, nestRoute } from './config/createRouting';

export const analysisRoutes = createRouting({
  analysisAndOffer: defineRoute({
    path: '/analysis-and-offer' as const,
  }),
});

export const analysisAndOfferNestedRoutes = createRouting({
  intro: nestRoute(
    analysisRoutes.config.analysisAndOffer,
    defineRoute({
      path: 'intro' as const,
    })
  ),
  analysis: nestRoute(
    analysisRoutes.config.analysisAndOffer,
    defineRoute({
      path: 'analysis' as const,
    })
  ),
  suggestedNegotiations: nestRoute(
    analysisRoutes.config.analysisAndOffer,
    defineRoute({
      path: 'suggested-negotiations' as const,
    })
  ),
  dynamic: nestRoute(
    analysisRoutes.config.analysisAndOffer,
    defineRoute({
      path: ':dynamicTab' as const,
    })
  ),
});
