import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationMetricsQueryV2 } from '@/modules/customer/api/queries/getNegotiationMetricsQueryV2';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseGetNegotiationMetricsQueryPayload = {
  customerId?: string;
};
export const useGetNegotiationMetricsQueryV2 = (
  payload: UseGetNegotiationMetricsQueryPayload
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetAnalysisMetricsQuery');
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getSuggestedNegotiationMetricsV2,
      payload.customerId,
    ],
    staleTime: 5000,
    queryFn: getNegotiationMetricsQueryV2(jsonApi, payload),
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view metrics"),
        },
      },
    }),
  });
};
