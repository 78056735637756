import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

type UseForwardToNewContactMutationPayload = {
  negotiationId: string;
};

export const useForwardToNewContactMutationV2 = ({
  negotiationId,
}: UseForwardToNewContactMutationPayload) => {
  const jsonApi = useJsonApiV2();
  const queryClient = useQueryClient();
  const { t } = useTranslation('apiMessages/useForwardToNewContactMutation');

  return useMutation({
    mutationFn: forwardToNewContact(jsonApi),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.negotiations.getNegotiationV2, negotiationId],
      });
    },
    meta: constructMeta({
      errorMessages: {
        404: {
          NEGOTIATION_NOT_FOUND: t(
            'Negotiation not found. Please refresh the page or contact the administrator'
          ),
          PROGRESS_NOT_FOUND: t(
            'Negotiation progress not found. Please refresh the page or contact the administrator'
          ),
        },
        422: {
          INVALID_NEGOTIATION_STATE: t('Negotiation is not in active state'),
        },
      },
    }),
  });
};

type ForwardToNewContactPayload = {
  negotiationId: string;
  negotiationSupplierId: string;
  email: string;
  firstName: string;
  lastName: string;
};

const forwardToNewContact = (jsonApi: JsonApiV2Client) => {
  return ({
    negotiationId,
    negotiationSupplierId,
    email,
    firstName,
    lastName,
  }: ForwardToNewContactPayload) => {
    return jsonApi.post({
      path: `/v2/negotiation-supplier/negotiations/${negotiationId}/forward`,
      attributes: {
        negotiationId,
        negotiationSupplierId,
        email,
        firstName,
        lastName,
        fromCustomer: true,
      },
    });
  };
};
