import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { changePasswordResourcePath } from '../resources/changePassword/changePasswordConsts';

type ChangePasswordMutationPayload = {
  oldPassword: string;
  newPassword: string;
};

export function changePasswordMutation(jsonApi: JsonApiV2Client) {
  return (attributes: ChangePasswordMutationPayload) =>
    jsonApi.post({
      path: changePasswordResourcePath,
      attributes,
    });
}
