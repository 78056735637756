import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { blue, dark, mint, orange, red } from '@/modules/theme/utils/colors';

const negotiationColors = {
  [NegotiationStateV2.SUGGESTED]: blue[800],
  [NegotiationStateV2.IN_SETUP]: red[800],
  [NegotiationStateV2.TO_BE_APPROVED]: blue[400],
  [NegotiationStateV2.PLANNED]: mint[800],
  [NegotiationStateV2.IN_PROGRESS]: orange[800],
  [NegotiationStateV2.COMPLETED]: dark[400],
};

export const getNegotiationTypeColor = (state: NegotiationStateV2) => {
  const notLaunched = state === NegotiationStateV2.DEMO;
  if (notLaunched) throw new Error(`unsupported negotiation type: ${state}`);

  return negotiationColors[state];
};
