import { useTranslation } from 'react-i18next';

import { useAuthContext } from '@/modules/auth/hooks/useAuthContext';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';
import { financialCompactFormatter } from '@/utils/financialCompactFormatter';

import { periodNotSelectedState } from '../NegotiationsDnD/constants';
import { DnDNegotiation } from '../types';

import { getCardVariant } from './helpers/getCardVariant';
import { ReactComponent as HamburgerIcon } from './icons/hamburgerIcon.svg';
import {
  TitleStyled,
  CardWrapperStyled,
  CardContentStyled,
  CardContentWrapperStyled,
  SuppliersMetric,
  CardHeaderStyled,
  WrapperButtonStyled,
} from './NegotiationCard.styled';
import { NegotiationMetric } from './NegotiationMetric/NegotiationMetric';
import { TermIcons } from './TermIcons/TermIcons';

type NegotiationCardProps = {
  negotiation: DnDNegotiation;
  onAction?: () => void;
  isPending?: boolean;
};

export const NegotiationCard = ({
  negotiation,
  onAction,
  isPending = false,
}: NegotiationCardProps) => {
  const { customer } = useAuthContext();
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');

  if (!negotiation.metrics || !customer)
    throw new Error(
      `Negotiation doesn't have negotiation metrics or customer doesn't exist`
    );

  const { potentialSavings, potentialCostAvoidance, suppliersCount } =
    negotiation.metrics;
  const { negotiationTypes } = negotiation;

  const isSavings = negotiationTypes.includes(NegotiationType.IMPROVED_PRICES);
  const isAntiInflation = negotiationTypes.includes(
    NegotiationType.LOCKED_PRICES
  );
  const isFinancialMetricsCard = isSavings || isAntiInflation;

  const savingsAmount = `${financialCompactFormatter.format(
    typeof potentialSavings === 'number'
      ? potentialSavings
      : parseInt(potentialSavings || '0')
  )} ${customer.currency}`;

  const costAvoidanceAmount = `${financialCompactFormatter.format(
    typeof potentialCostAvoidance === 'number'
      ? potentialCostAvoidance
      : parseInt(potentialCostAvoidance || '0')
  )} ${customer.currency}`;

  const cardVariant = getCardVariant({
    isAssignedToPeriod: !(
      negotiation.periodIndex === undefined && negotiation.periodIndex !== null
    )
      ? !periodNotSelectedState.includes(negotiation.periodIndex)
      : false,
    isFinancialMetricsCard,
    isPending,
  });

  return (
    <WrapperButtonStyled
      onClick={onAction}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onAction) onAction();
      }}
    >
      <CardWrapperStyled variant={cardVariant}>
        <CardHeaderStyled title={negotiation.title}>
          <TitleStyled>{negotiation.title}</TitleStyled>
          <HamburgerIcon />
        </CardHeaderStyled>
        <CardContentWrapperStyled>
          <CardContentStyled>
            {isSavings && (
              <NegotiationMetric amount={savingsAmount} label={t('Savings')} />
            )}
            {isAntiInflation && (
              <NegotiationMetric
                amount={costAvoidanceAmount}
                label={t('Cost avoidance')}
              />
            )}
            <SuppliersMetric
              amount={suppliersCount}
              label={t('Suppliers')}
              isStandaloneMetric={!isFinancialMetricsCard}
            />
            <TermIcons termRoles={negotiation.negotiationTypes} />
          </CardContentStyled>
        </CardContentWrapperStyled>
      </CardWrapperStyled>
    </WrapperButtonStyled>
  );
};
