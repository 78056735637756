import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationResponseTypeV2 } from '../queries/getNegotiationsQueryV2';
import { getUpdateNegotiationResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStructV2 } from '../resources/negotiation/negotiationResourceStructV2';

type UpdateNegotiationMutationPayload = {
  id: string;
  attributes: Partial<GetNegotiationResponseTypeV2['attributes']>;
};

export function updateNegotiationMutationV2(jsonApi: JsonApiV2Client) {
  return async ({ id, attributes }: UpdateNegotiationMutationPayload) => {
    const response = await jsonApi.update({
      path: getUpdateNegotiationResourcePathV2(id),
      updateData: { id, attributes },
    });
    if (!response)
      throw new Error('missing response from `updateNegotiationMutation`');

    return negotiationResourceStructV2.mask(response);
  };
}

export type UpdateNegotiationResponseV2 = sup.Infer<
  typeof negotiationResourceStructV2
>;
