import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Scrollbar } from '@/modules/theme/components/Scrollbar';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { TableSkeletonLoader } from '@/modules/theme/components/TableSkeletonLoader/TableSkeletonLoader';
import { Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

import { ToggleSupplierCheckbox } from '../ToggleSupplierCheckbox/ToggleSupplierCheckbox';

type SuppliersTableProps = {
  negotiationId: string;
  suppliers: NegotiationSupplier[];
  onFetchMore: () => void;
  isFetching: boolean;
};

export const SuppliersTable = ({
  negotiationId,
  suppliers,
  isFetching,
  onFetchMore,
}: SuppliersTableProps) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const { customer } = useSessionContext();

  const headers = {
    company: t('Company'),
    category: t('Category'),
    spend: t('Spend'),
    selected: t('Selected'),
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && !isFetching) {
      onFetchMore();
    }
  }, [onFetchMore, inView, isFetching]);

  if (!suppliers.length && !isFetching) {
    return (
      <WrapperStyled>
        <NoSuppliersInfoStyled>
          {t('There are no suppliers to show at this moment')}
        </NoSuppliersInfoStyled>
      </WrapperStyled>
    );
  }

  return (
    <WrapperStyled>
      <Scrollbar>
        <Table headers={Object.values(headers)}>
          {suppliers.map(
            ({
              id,
              attributes: {
                supplier,
                freezedSupplierName,
                negotiationSupplierState,
              },
            }) => {
              const selected =
                negotiationSupplierState !==
                  NegotiationSupplierStateV2.REMOVED &&
                negotiationSupplierState !==
                  NegotiationSupplierStateV2.EXCLUDED;

              return (
                <TableRow
                  key={id}
                  variant={selected ? 'default' : 'transparent'}
                >
                  <TableCell mobileHeader={headers.company}>
                    <Text bold>
                      {freezedSupplierName
                        ? freezedSupplierName
                        : supplier?.name}
                    </Text>
                  </TableCell>
                  <TableCell mobileHeader={headers.category}>
                    <Text>{supplier?.category}</Text>
                  </TableCell>
                  <TableCell mobileHeader={headers.spend}>
                    <Text>
                      {formatAmount(supplier?.spend || 0)} {customer.currency}
                    </Text>
                  </TableCell>
                  <TableCell mobileHeader={headers.selected}>
                    <ToggleSupplierCheckbox
                      selected={selected}
                      negotiationId={negotiationId}
                      supplierId={id}
                    />
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </Table>
        {isFetching && <TableSkeletonLoader rows={20} />}
        <VisibilitySentinelStyled ref={ref} />
      </Scrollbar>
    </WrapperStyled>
  );
};

const VisibilitySentinelStyled = styled('div')({
  // HACK: sometimes on 13" displays, intersection observer isn't triggered
  // because dialog is too big (especially when it's a financial negotiation,
  // that has extra metrics on top) and visibility sentinel seems not to fit
  // into the frame. This height should make enough space for visibilty sentinel
  // to be able to trigger intersection observer.
  // It might be possible to adjust `rootMargin` of intersection observer,
  // but it seems to be a bit tricky inside scrollable area & custom scrollbar.
  height: 50,
});

const WrapperStyled = styled('div')({
  height: 440,
  width: '100%',
});

export const NoSuppliersInfoStyled = styled('div')(
  ({ theme: { palette, typography } }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 280,
    margin: '0 auto',
    textAlign: 'center',
    color: palette.disabled.main,
    fontWeight: typography.fontWeight.bold,
    fontSize: typography.fontSize.large,
  })
);
