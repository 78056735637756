import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import {
  NegotiationSupplierStateV2,
  NegotiationType,
} from '@/modules/negotiation/types/negotiationTypes';

export const calculateNegotiationMetrics = (
  negotiation: NegotiationResourceTypeV2
) => {
  const { attributes } = negotiation;
  const {
    type,
    negotiationSuppliers,
    demoSupplierNumber,
    totalSpend,
    demoSpend,
    improvedPricesEstimate,
    lockedPricesEstimate,
  } = attributes;

  const isDemoNegotiation = (totalSpend === 0 && (demoSpend || 0) > 0) ?? false;

  const potentialSavings =
    (totalSpend || demoSpend) &&
    improvedPricesEstimate &&
    type.includes(NegotiationType.IMPROVED_PRICES)
      ? (improvedPricesEstimate / 100) * ((totalSpend || demoSpend) ?? 0)
      : undefined;

  const potentialCostAvoidance =
    (totalSpend || demoSpend) &&
    lockedPricesEstimate &&
    type.includes(NegotiationType.LOCKED_PRICES)
      ? (((totalSpend || demoSpend) ?? 0) / (1 - lockedPricesEstimate / 100)) *
        (lockedPricesEstimate / 100)
      : undefined;

  return {
    totalSpend: !isDemoNegotiation ? totalSpend ?? 0 : demoSpend ?? 0,
    suppliersCount:
      negotiationSuppliers && negotiationSuppliers?.length > 0
        ? negotiationSuppliers.filter(
            (supplier) =>
              supplier.attributes.negotiationSupplierState !==
                NegotiationSupplierStateV2.EXCLUDED &&
              supplier.attributes.negotiationSupplierState !==
                NegotiationSupplierStateV2.REMOVED
          ).length
        : demoSupplierNumber ?? 0,
    supplierCountWithExcluded:
      negotiationSuppliers && negotiationSuppliers?.length > 0
        ? negotiationSuppliers.filter(
            (supplier) =>
              supplier.attributes.negotiationSupplierState !==
              NegotiationSupplierStateV2.REMOVED
          ).length
        : 0,
    emailCount: !isDemoNegotiation ? negotiation.attributes.emailCount ?? 0 : 0,
    potentialSavings,
    potentialCostAvoidance,
    estimatedDiscount: improvedPricesEstimate,
    estimatedInflation: lockedPricesEstimate,
  };
};

export type NegotiationMetrics = ReturnType<typeof calculateNegotiationMetrics>;
