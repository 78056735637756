import { useTranslation } from 'react-i18next';

import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';

export const useNegotiationStatusLabels = () => {
  const { t } = useTranslation('pages/DashboardPage');

  const labels = {
    [NegotiationStateV2.SUGGESTED]: t('Suggested'),
    [NegotiationStateV2.IN_SETUP]: t('In setup'),
    [NegotiationStateV2.TO_BE_APPROVED]: t('To be approved'),
    [NegotiationStateV2.PLANNED]: t('Planned'),
    [NegotiationStateV2.IN_PROGRESS]: t('In progress'),
    [NegotiationStateV2.COMPLETED]: t('Completed'),
  };

  return (state: NegotiationStateV2) => {
    const notLaunched = state === NegotiationStateV2.DEMO;
    if (notLaunched) throw new Error(`unsupported negotiation type: ${state}`);

    return labels[state];
  };
};
