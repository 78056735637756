import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import {
  NegotiationStateV2,
  NegotiationType,
} from '@/modules/negotiation/types/negotiationTypes';
import { onboardedRoutes } from '@/modules/router/onboardedRoutes';
import { Heading } from '@/modules/theme/components/Typography';
import { NegotiationMetrics } from '@/utils/calculate-negotiation-metrics';

import { useNegotiationStatusLabels } from '../../../utils/useNegotiationStatusLabels';
import { NegotiationStatusBadge } from '../../NegotiationStatusBadge/NegotiationStatusBadge';
import { NegotiationTypeLabels } from '../NegotiationTypeLabels/NegotiationTypeLabels';

import {
  NegotiationCardContainerLinkStyled,
  NegotiationCardContentWrapper,
  NegotiationCardHeadingStyled,
  NegotiationDatesStyled,
  NegotiationStatusBadgeWrapperStyled,
  NumberOfSuppliersWrapperStyled,
  SuppliersLabelStyled,
} from './NegotiationCardLayout.styled';

type NegotiationCardLayoutProps = {
  negotiation: NegotiationResourceTypeV2;
  metrics: NegotiationMetrics;
  link?: string;
  children: ReactNode;
};

export const NegotiationCardLayout = ({
  negotiation,
  link,
  children,
  metrics,
}: NegotiationCardLayoutProps) => {
  const { t } = useTranslation('pages/DashboardPage');
  const getNegotiationLabel = useNegotiationStatusLabels();

  const {
    attributes: { name, state, startDate, endDate },
  } = negotiation;

  return (
    <NegotiationCardContainerLinkStyled
      to={link ?? onboardedRoutes.buildUrl('dashboard')}
      disabled={!link}
    >
      <NumberOfSuppliersWrapperStyled>
        <Heading variant="h3" as="p">
          {metrics?.suppliersCount}
        </Heading>
        <SuppliersLabelStyled>{t('Suppliers')}</SuppliersLabelStyled>
      </NumberOfSuppliersWrapperStyled>
      <NegotiationCardContentWrapper>
        <NegotiationCardHeadingStyled>
          <Heading variant="h3">{name}</Heading>
          <NegotiationStatusBadgeWrapperStyled>
            <NegotiationStatusBadge
              variant={state as NegotiationStateV2}
              width={150}
            >
              {getNegotiationLabel(state as NegotiationStateV2)}
            </NegotiationStatusBadge>
            {startDate && endDate && (
              <NegotiationDatesStyled variant="normal">
                {t('{{startDate}} - {{endDate}}', {
                  startDate: startDate.toLocaleDateString(),
                  endDate: endDate.toLocaleDateString(),
                })}
              </NegotiationDatesStyled>
            )}
          </NegotiationStatusBadgeWrapperStyled>
        </NegotiationCardHeadingStyled>
        {children}
        <NegotiationTypeLabels
          negotiationTermRoles={
            negotiation.attributes.type as NegotiationType[]
          }
        />
      </NegotiationCardContentWrapper>
    </NegotiationCardContainerLinkStyled>
  );
};
