import { useTranslation } from 'react-i18next';

import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';

import { NegotiationTypeLabelStyled } from './NegotiationTypeLabels.styled';

type NegotiationTypeLabelsProps = {
  negotiationTermRoles: NegotiationType[];
};

export const NegotiationTypeLabels = ({
  negotiationTermRoles,
}: NegotiationTypeLabelsProps) => {
  const { t } = useTranslation('pages/DashboardPage');

  const getLabel = (negotiationTermRole: NegotiationType) => {
    switch (negotiationTermRole) {
      case NegotiationType.LOCKED_PRICES:
        return t('Locked prices');
      case NegotiationType.CODE_OF_CONDUCT:
        return t('Code of Conduct');
      case NegotiationType.IMPROVED_PRICES:
        return t('Improved prices');
      case NegotiationType.SUPPLIER_QUESTIONNAIRE:
        return t('Supplier questionnaire');
      case NegotiationType.EXTRA_PAYMENT_DAYS:
        return t('Payment days');
      case NegotiationType.ISO:
        return t('ISO');
      case NegotiationType.STANDARD_AGREEMENT:
        return t('Standard agreement');
      case NegotiationType.PROLONG:
        return t('Prolong');
      case NegotiationType.SUPPLIER_REGISTRATION:
        return t('Supplier registration');
      default:
        return '';
    }
  };

  return (
    <FlexContainer wrap="wrap" gap={2}>
      {negotiationTermRoles.map((role) => {
        const label = getLabel(role);
        return (
          label && (
            <NegotiationTypeLabelStyled key={role}>
              {label}
            </NegotiationTypeLabelStyled>
          )
        );
      })}
    </FlexContainer>
  );
};
