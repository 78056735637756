import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationKeywordsQueryParamsV2 } from '../../hooks/useGetNegotiationKeywordsV2Query';
import { negotiationKeywordsStruct } from '../resources/negotiation/negotiationKeywordsResourceStructV2';
import { getNegotiationKeywordsResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

export const getNegotiationKeywordsQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationKeywordsQueryParamsV2
) => {
  return async () => {
    const result = await jsonApi.get({
      path: getNegotiationKeywordsResourcePathV2(queryParams.negotiationId),
      queryParams,
      responseStruct: getNegotiationKeywordsResponseStructV2,
    });
    return negotiationKeywordsStruct.mask(result.data);
  };
};

const getNegotiationKeywordsResponseStructV2 = sup.object({
  data: negotiationKeywordsStruct,
});
