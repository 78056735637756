import { ReactComponent as CodeOfConductIcon } from '@/assets/icons/termIcons/codeOfConductIcon.svg';
import { ReactComponent as ExtraPaymentDaysIcon } from '@/assets/icons/termIcons/extraPaymentDaysIcon.svg';
import { ReactComponent as IsoIcon } from '@/assets/icons/termIcons/isoIcon.svg';
import { ReactComponent as LockedPricesIcon } from '@/assets/icons/termIcons/lockedPricesIcon.svg';
import { ReactComponent as SavingsIcon } from '@/assets/icons/termIcons/savingsIcon.svg';
import { ReactComponent as StandardAgreementIcon } from '@/assets/icons/termIcons/standardAgreementIcon.svg';
import { ReactComponent as SustainabilityQuestionnaireIcon } from '@/assets/icons/termIcons/sustainabilityQuestionnaireIcon.svg';
import { NegotiationType } from '@/modules/negotiation/types/negotiationTypes';

export const termRoleToIconMapper = {
  [NegotiationType.EXTRA_PAYMENT_DAYS]: ExtraPaymentDaysIcon,
  [NegotiationType.IMPROVED_PRICES]: SavingsIcon,
  [NegotiationType.LOCKED_PRICES]: LockedPricesIcon,
  [NegotiationType.CODE_OF_CONDUCT]: CodeOfConductIcon,
  [NegotiationType.SUPPLIER_QUESTIONNAIRE]: SustainabilityQuestionnaireIcon,
  [NegotiationType.STANDARD_AGREEMENT]: StandardAgreementIcon,
  [NegotiationType.ISO]: IsoIcon,
  [NegotiationType.PROLONG]: StandardAgreementIcon,
  [NegotiationType.SUPPLIER_REGISTRATION]: StandardAgreementIcon,
} as const;

export const termRoleImportanceOrder = {
  // technically we only care about OFFER_DISCOUNT & LOCK_PRICE
  // rest of them doesn't matter, but roles with 999 don't
  // have a meaning when it comes to graphical representation
  [NegotiationType.IMPROVED_PRICES]: 1,
  [NegotiationType.LOCKED_PRICES]: 2,
  [NegotiationType.CODE_OF_CONDUCT]: 3,
  [NegotiationType.EXTRA_PAYMENT_DAYS]: 4,
  [NegotiationType.SUPPLIER_QUESTIONNAIRE]: 5,
  [NegotiationType.STANDARD_AGREEMENT]: 6,
  [NegotiationType.ISO]: 7,
  [NegotiationType.PROLONG]: 8,
  [NegotiationType.SUPPLIER_REGISTRATION]: 9,
} as const;
