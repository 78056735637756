import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationQueryV2 } from '@/modules/negotiation/api/queries/getNegotiationQueryV2';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

import { GetNegotiationsQueryOptions } from '../api/resources/negotiation/negotiationResourceConsts';

export type GetNegotiationQueryParamsV2 = {
  negotiationId: string;
};

export const useGetNegotiationQueryV2 = (
  params: GetNegotiationQueryParamsV2,
  options?: GetNegotiationsQueryOptions
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetNegotiationQuery');
  return useQuery({
    queryKey: [queryKeys.negotiations.getNegotiationV2, params.negotiationId],
    queryFn: getNegotiationQueryV2(jsonApi, params),
    ...options,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiation"),
        },
      },
    }),
  });
};
