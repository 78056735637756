import { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as sup from 'superstruct';

export function createJsonApiV2Client(axios: AxiosInstance) {
  return {
    async get<Response>({
      path,
      queryParams,
      axiosConfig,
      responseStruct,
    }: {
      path: string;
      responseStruct: sup.Struct<Response>;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.get<unknown>(path, {
        params: queryParams,
        ...axiosConfig,
      });

      const result = responseStruct.mask({ data });

      return result;
    },
    async post({
      path,
      queryParams,
      attributes,
      axiosConfig,
    }: {
      path: string;
      attributes?: Record<string, unknown>;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.post<unknown>(path, {
        params: queryParams,
        ...attributes,
        ...axiosConfig,
      });

      return data;
    },
    async update({
      path,
      updateData,
      axiosConfig,
    }: {
      path: string;
      updateData: object;
      queryParams?: Record<string, unknown>;
      axiosConfig?: AxiosRequestConfig;
    }) {
      const { data } = await axios.patch<unknown>(path, updateData, {
        ...axiosConfig,
      });
      return data;
    },
  };
}
export type JsonApiV2Client = ReturnType<typeof createJsonApiV2Client>;
