import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';
import { GetNegotiationsQueryParamsV2 } from '@/pages/AnalysisAndOfferPage/tabs/SuggestedNegotiationsPage/hooks/useGetNegotiationsV2Query';

import { getNegotiationsResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';
import { negotiationResourceStructV2 } from '../resources/negotiation/negotiationResourceStructV2';

export const getNegotiationsQueryV2 = (
  jsonApi: JsonApiV2Client,
  params: GetNegotiationsQueryParamsV2
) => {
  return async () => {
    const data = await jsonApi.get({
      path: getNegotiationsResourcePathV2(),
      queryParams: {
        ...params,
        states: params.states?.join(','),
      },
      responseStruct: getNegotiationsResponseStructV2,
    });
    return getNegotiationsResponseStructV2.mask(data);
  };
};

export type GetNegotiationsResponseTypeV2 = sup.Infer<
  typeof getNegotiationsResponseStructV2
>;

export type GetNegotiationResponseTypeV2 = sup.Infer<
  typeof negotiationResourceStructV2
>;

const getNegotiationsResponseStructV2 = sup.object({
  data: sup.object({
    items: sup.array(negotiationResourceStructV2),
    pagination: sup.object({
      page: sup.number(),
      total: sup.number(),
    }),
  }),
});
