import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '@/modules/api/queryKeys';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useUpdateNegotiationMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationMutationV2';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Button } from '@/modules/theme/components/Button/Button';

import {
  ButtonsSkeletonStyled,
  ButtonsWrapperStyled,
  ContentWrapperStyled,
  ParagraphMtStyled,
  ParagraphStyled,
  SignetStyled,
} from './ActivateNegotiationSection.styled';

type ActivateNegotiationSectionProps = {
  supplierPreviewUrl: string | null | undefined;
  isLoading: boolean;
  negotiation: NegotiationResourceTypeV2 | undefined;
};

export const ActivateNegotiationSection = ({
  isLoading,
  negotiation,
}: ActivateNegotiationSectionProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('pages/NegotiationSetupPage');

  const { mutate: updateNegotiationMutation, isPending } =
    useUpdateNegotiationMutationV2();

  const finishSetup = () => {
    updateNegotiationMutation(
      {
        id: negotiation?.id || '',
        attributes: {
          state: NegotiationStateV2.TO_BE_APPROVED,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [
              queryKeys.negotiations.getNegotiationV2,
              negotiation?.id,
            ],
          });
        },
      }
    );
  };

  return (
    <ContentWrapperStyled>
      <ParagraphStyled>
        {t(
          'Before you activate your negotiation you can preview it from your suppliers point of view by clicking the button “Preview as supplier”.'
        )}
      </ParagraphStyled>
      <ParagraphStyled>
        {t(
          '*Note that you can still make changes to your negotiation after activating it (including the CPO-introduction letter) either manually or by contacting us.'
        )}
      </ParagraphStyled>
      {isLoading ? (
        <ButtonsSkeletonStyled />
      ) : (
        <>
          <ButtonsWrapperStyled>
            {/* {supplierPreviewUrl && (
              <ButtonLink
                variant="outline"
                linkType={LinkType.External}
                to={supplierPreviewUrl}
              >
                {t('Preview as supplier')}
              </ButtonLink>
            )} */}

            {negotiation?.attributes.state === NegotiationStateV2.IN_SETUP && (
              <Button onClick={finishSetup} loading={isPending}>
                {t('Finish setup')}
              </Button>
            )}
          </ButtonsWrapperStyled>
          {negotiation?.attributes.state ===
            NegotiationStateV2.TO_BE_APPROVED && (
            <ParagraphMtStyled>
              {t(
                'Negotiation is in waiting for approval. You can still make changes to it.'
              )}
            </ParagraphMtStyled>
          )}
        </>
      )}

      <SignetStyled />
    </ContentWrapperStyled>
  );
};
