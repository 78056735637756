import { ActiveNegotiationMetricsType } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';

export const calculateNegotiationsMetrics = (
  metrics: ActiveNegotiationMetricsType[]
): ActiveNegotiationMetricsType => {
  return {
    counts: {
      responses: metrics.reduce(
        (acc, metric) => acc + metric.counts.responses,
        0
      ),
      discount: metrics.reduce(
        (acc, metric) => acc + metric.counts.discount,
        0
      ),
      lockedPrice: metrics.reduce(
        (acc, metric) => acc + metric.counts.lockedPrice,
        0
      ),
      acceptedExtraPaymentDays: metrics.reduce(
        (acc, metric) => acc + metric.counts.acceptedExtraPaymentDays,
        0
      ),
      acceptedStandardAgreement: metrics.reduce(
        (acc, metric) => acc + metric.counts.acceptedStandardAgreement,
        0
      ),
      acceptedCoc: metrics.reduce(
        (acc, metric) => acc + metric.counts.acceptedCoc,
        0
      ),
      answeredQuestionnaire: metrics.reduce(
        (acc, metric) => acc + metric.counts.answeredQuestionnaire,
        0
      ),
      answeredIso: metrics.reduce(
        (acc, metric) => acc + metric.counts.answeredIso,
        0
      ),
      noResponseReason: metrics.reduce(
        (acc, metric) => acc + metric.counts.noResponseReason,
        0
      ),
    },
    averageDiscount:
      metrics.reduce((acc, metric) => acc + (metric.averageDiscount || 0), 0) /
      metrics.length,
    savedAmountTotal: metrics.reduce(
      (acc, metric) => acc + (metric.savedAmountTotal || 0),
      0
    ),
    savedByLockedPrices: metrics.reduce(
      (acc, metric) => acc + (metric.savedByLockedPrices || 0),
      0
    ),
    negotiatedSpend: metrics.reduce(
      (acc, metric) => acc + (metric.negotiatedSpend || 0),
      0
    ),
    supplierStatesCount: metrics.reduce(
      (acc, metric) => {
        const supplierStatesCount =
          metric.supplierStatesCount ||
          ({} as Record<NegotiationSupplierStateV2, number>);
        Object.entries(supplierStatesCount).forEach(([key, value]) => {
          acc[key as NegotiationSupplierStateV2] =
            (acc[key as NegotiationSupplierStateV2] || 0) + value;
        });
        return acc;
      },
      {} as Record<NegotiationSupplierStateV2, number>
    ),
  };
};
