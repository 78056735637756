import { detect } from 'detect-browser';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as sup from 'superstruct';

import { useForm } from '@/modules/form/components/Form/hooks/useForm/useForm';
import { isFutureDate, notEmpty } from '@/modules/form/utils/structs';
import { useGetNegotiationQueryV2 } from '@/modules/negotiation/hooks/useGetNegotiationV2Query';
import { useUpdateNegotiationMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationMutationV2';
import {
  DAYS_TO_NEGOTIATION_END_DATE,
  START_DATE_WEEK_DAY_INDEX,
} from '@/modules/negotiation/utils/consts';
import {
  calculateDate,
  countWeekDayRatio,
  mapDateToDateInputString,
} from '@/modules/negotiation/utils/dateUtils';
import { setupNegotiationRoutes } from '@/modules/router/onboardedRoutes';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { Heading, Paragraph } from '@/modules/theme/components/Typography';
import { dark } from '@/modules/theme/utils/colors';
import { recompose } from '@/utils/structs/recompose';

import { EndDate } from './components/EndDate';
import { SaveStateIndicator } from './components/SaveStateIndicator';
import { StartDateInputLoader } from './components/StartDateInputLoader';
import {
  ContentWrapperStyled,
  DebouncedDateInputStyled,
} from './StartDate.styled';
import { isValidWeekDay } from './structs';

const setStartDateStepStruct = sup.object({
  startDate: recompose(
    sup.string(),
    isValidWeekDay(START_DATE_WEEK_DAY_INDEX),
    isFutureDate,
    notEmpty
  ),
});

export const StartDate = () => {
  const { t } = useTranslation('pages/StartDate');

  const errorMessages = {
    startDate: {
      'string.notEmpty': t('Start date is required'),
      'string.isFutureDate': t('You can pick only future date'),
      'string.isValidWeekDay': t('You can pick only Wednesday'),
      'api.error': t('Something went wrong with your request, try again'),
    },
  };

  const { negotiationId } =
    setupNegotiationRoutes.useGetRouteParams('startDate');

  const {
    data: negotiation,
    isPending: isNegotiationLoading,
    refetch: refetchNegotiation,
  } = useGetNegotiationQueryV2({
    negotiationId,
  });

  const updateNegotiationMutation = useUpdateNegotiationMutationV2();

  const browser = detect();
  const isSafari = browser?.name === 'safari';

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: negotiation?.attributes.startDate
        ? mapDateToDateInputString(negotiation.attributes.startDate)
        : '',
    },
    struct: setStartDateStepStruct,
  });

  const {
    setValue,
    trigger,
    formState: { errors: formErrors },
    setError,
    watch,
  } = formProps;
  const { startDate: startDateError } = formErrors;

  const nextValidDay = useMemo(() => {
    const todayDate = new Date();
    // since we can't set a past date,
    // we need to add 7 (the total number of weekdays) for any weekdays
    // with an index greater than the starting date's weekday index
    const weekDaysRatio = countWeekDayRatio(todayDate);
    const newDate = calculateDate(todayDate, weekDaysRatio);

    return mapDateToDateInputString(newDate);
  }, []);

  const startDate = watch('startDate');
  const endDate = useMemo(() => {
    if (!startDate) return null;
    return calculateDate(new Date(startDate), DAYS_TO_NEGOTIATION_END_DATE);
  }, [startDate]);

  useEffect(() => {
    if (negotiation?.attributes.startDate) {
      setValue(
        'startDate',
        mapDateToDateInputString(negotiation?.attributes.startDate)
      );
      void trigger('startDate');
    } else if (isSafari) {
      // Safari browser always inserts today date so we need to set next valid week day
      setValue('startDate', nextValidDay);
    }
  }, [
    setValue,
    trigger,
    negotiation?.attributes.startDate,
    nextValidDay,
    isSafari,
  ]);

  const handleOnDebounce = async (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = await formProps.trigger();

    if (isValid && negotiation) {
      updateNegotiationMutation.mutate(
        {
          id: negotiation.id,
          attributes: {
            startDate: new Date(e.target.value),
          },
        },
        {
          onSuccess: () => refetchNegotiation(),
          onError: () => setError('startDate', { type: 'api.error' }),
        }
      );
    }
  };

  return (
    <>
      <Heading variant="h3" as="h2" mb={2}>
        {t('Set a start date for your negotiation')}
      </Heading>
      <Paragraph color={dark[400]}>
        {t(
          'The start date dictates when the negotiation will be activated and the communication goes out to the suppliers.'
        )}
      </Paragraph>
      <ContentWrapperStyled>
        {isNegotiationLoading ? (
          <StartDateInputLoader />
        ) : (
          <FormProvider {...formProps}>
            <FlexContainer gap={1} align="center">
              <DebouncedDateInputStyled
                waitTime={500}
                onDebounce={handleOnDebounce}
                {...formProps.register('startDate')}
                label={t('Start date')}
                placeholder={t('dd/mm/yyyy')}
                step={7}
                errorMessages={errorMessages.startDate}
                // The date input requires a default value,
                // in addition to using React Hook Form,
                // to correctly set the disabled values using the 'step' attribute
                defaultValue={nextValidDay}
                min={nextValidDay}
                max="9999-12-31"
              />
              <Box mt={2.5}>
                <SaveStateIndicator
                  isUpdating={updateNegotiationMutation.isPending}
                  isSaved={Boolean(
                    updateNegotiationMutation.isSuccess &&
                      negotiation?.attributes.startDate &&
                      startDate ===
                        mapDateToDateInputString(
                          negotiation?.attributes.startDate
                        )
                  )}
                />
              </Box>
            </FlexContainer>

            <EndDate
              isDatePickerError={Boolean(startDateError)}
              endDate={endDate}
            />
          </FormProvider>
        )}
      </ContentWrapperStyled>
    </>
  );
};
