import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { UpdateNegotiationKeywordStruct } from '../resources/negotiation/negotiationKeywordsResourceStructV2';
import { updateNegotiationKeywordResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

type UpdateNegotiationKeywordMutationPayload = {
  negotiationId: string;
  keywordId: string;
  value: UpdateNegotiationKeywordStruct;
};

export function updateNegotiationKeywordMutationV2(jsonApi: JsonApiV2Client) {
  return async ({
    negotiationId,
    keywordId,
    value,
  }: UpdateNegotiationKeywordMutationPayload) => {
    const response = await jsonApi.update({
      path: updateNegotiationKeywordResourcePathV2(negotiationId, keywordId),
      updateData: value,
    });
    if (!response)
      throw new Error(
        'missing response from `updateNegotiationKeywordMutation`'
      );

    return sup.boolean().mask(response);
  };
}
