import * as sup from 'superstruct';

import { JsonApiV2Client } from '@/modules/api/utils/createJsonApiV2Client';

import { GetNegotiationContractQueryParamsV2 } from '../../hooks/useGetNegotiationContractV2Query';
import { negotiationContractResourceStructV2 } from '../resources/negotiation/negotiationContractResourceStructV2';
import { getNegotiationContractResourcePathV2 } from '../resources/negotiation/negotiationResourceConsts';

export const getNegotiationContractQueryV2 = (
  jsonApi: JsonApiV2Client,
  queryParams: GetNegotiationContractQueryParamsV2
) => {
  return async () => {
    if (!queryParams.negotiationId) {
      throw new Error('negotiationId is required');
    }
    const result = await jsonApi.get({
      path: getNegotiationContractResourcePathV2(queryParams.negotiationId),
      queryParams,
      responseStruct: getNegotiationContractResponseStructV2,
    });
    return negotiationContractResourceStructV2.mask(result.data);
  };
};

export type GetNegotiationContractResponseTypeV2 = sup.Infer<
  typeof negotiationContractResourceStructV2
>;

const getNegotiationContractResponseStructV2 = sup.object({
  data: negotiationContractResourceStructV2,
});
