import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ApiProvider } from '@/modules/api/components/ApiProvider';
import { AuthContextProvider } from '@/modules/auth/components/AuthContextProvider';
import { firebaseFetchRemoteConfig } from '@/modules/firebase';
import { Toaster } from '@/modules/notify/Toaster';
import { PresentationContextProvider } from '@/modules/presentation/presentationContext/PresentationContextProvider';
import { ReactQueryProvider } from '@/modules/reactQuery/ReactQueryProvider';
import { GlobalStyles } from '@/modules/theme/components/GlobalStyles';
import { StyledComponentsStyleSheetManager } from '@/modules/theme/config/StyledComponentsStyleSheetManager';
import { createTheme } from '@/modules/theme/utils/createTheme';

import { AppVersion } from './AppVersion';

export interface AppProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: AppProvidersProps) {
  useEffect(() => {
    void firebaseFetchRemoteConfig();
  }, []);
  const theme = createTheme();

  const basename =
    document.head.querySelector('base')?.getAttribute('href') || '/';

  return (
    <Router basename={basename}>
      <ApiProvider>
        <ReactQueryProvider>
          <StyledComponentsStyleSheetManager>
            <ThemeProvider theme={theme}>
              <GlobalStyles theme={theme} />
              <Toaster />
              <CookiesProvider>
                <HelmetProvider>
                  <AuthContextProvider>
                    <PresentationContextProvider>
                      {children}
                    </PresentationContextProvider>
                    <AppVersion />
                  </AuthContextProvider>
                </HelmetProvider>
              </CookiesProvider>
            </ThemeProvider>
          </StyledComponentsStyleSheetManager>
          <ReactQueryDevtools initialIsOpen={false} />
        </ReactQueryProvider>
      </ApiProvider>
    </Router>
  );
}
