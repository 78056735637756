import { useTranslation } from 'react-i18next';

import { ReactComponent as LockedPricesIcon } from '@/assets/icons/termIcons/lockedPricesIcon.svg';
import { ReactComponent as SavingsIcon } from '@/assets/icons/termIcons/savingsIcon.svg';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationMetricsResourceTypeV2 } from '@/modules/customer/api/resources/metrics/negotiationMetricsResourceStructV2';
import { Box } from '@/modules/theme/components/Box';
import { FlexContainer } from '@/modules/theme/components/FlexContainer';
import { blue } from '@/modules/theme/utils/colors';
import { formatAmount } from '@/utils/stringUtils';

import { AnalysisMetric } from '../AnalysisMetric/AnalysisMetric';
import { AnalysisNegotiationMetricErrorError } from '../AnalysisNegotiationMetricError/AnalysisNegotiationMetricError';
import { AnalysisNegotiationMetricSkeleton } from '../AnalysisNegotiationMetricSkeleton/AnalysisNegotiationMetricSkeleton';
import {
  BottomMetricsText,
  IconWrapperStyled,
  MetricsCardWrapper,
  MetricsContainer,
  TermDescriptionStyled,
} from '../common.styled';
import { AnalysisTermCounters } from '../TermCounters/AnalysisTermCounters';

type SuggestedNegotiationMetricsV2Props = {
  metrics: NegotiationMetricsResourceTypeV2 | undefined;
  isFetching: boolean;
  isError: boolean;
};

export const SuggestedNegotiationMetrics = ({
  isError,
  isFetching,
  metrics,
}: SuggestedNegotiationMetricsV2Props) => {
  const { t } = useTranslation('pages/SuggestedNegotiationsPage');
  const { customer } = useSessionContext();

  if (isFetching) return <AnalysisNegotiationMetricSkeleton highlight />;
  if (isError || !metrics)
    return <AnalysisNegotiationMetricErrorError highlight />;

  const {
    suggestedPotentialSavings: potentialSavings,
    suggestedPotentialCostAvoidance: potentialCostAvoidance,
    suggestedSpend: currentSpend,
    suggestedSuppliers: suppliersCount,
    uniqueSuppliersPerNegotiationType: terms,
  } = metrics;

  return (
    <MetricsCardWrapper highlight>
      <MetricsContainer>
        <FlexContainer gap={9} wrap="wrap">
          <AnalysisMetric
            data={potentialSavings}
            heading={t('Total potential savings ({{currency}})', {
              currency: customer.currency,
            })}
            variant="suggested"
          >
            <TermDescriptionStyled highlight>
              <IconWrapperStyled>
                <SavingsIcon />
              </IconWrapperStyled>
              {t('Improved prices')}
            </TermDescriptionStyled>
          </AnalysisMetric>
          <AnalysisMetric
            data={potentialCostAvoidance}
            heading={t('Total potential cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            variant="suggested"
          >
            <TermDescriptionStyled highlight>
              <IconWrapperStyled>
                <LockedPricesIcon />
              </IconWrapperStyled>
              {t('Locked prices')}
            </TermDescriptionStyled>
          </AnalysisMetric>
        </FlexContainer>
        <FlexContainer direction="column" mt={5}>
          <BottomMetricsText color={blue[800]}>
            {t('Spend ({{currency}}) {{amount}}', {
              currency: customer.currency,
              amount: formatAmount(currentSpend),
            })}
          </BottomMetricsText>
          <BottomMetricsText color={blue[800]}>
            {t('No. of suppliers {{amount}}', {
              amount: formatAmount(suppliersCount),
            })}
          </BottomMetricsText>
        </FlexContainer>
      </MetricsContainer>
      <Box pt={2}>
        <AnalysisTermCounters terms={terms} />
      </Box>
    </MetricsCardWrapper>
  );
};
