import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import { LoadedSessionContext } from 'supertokens-auth-react/lib/build/recipe/session/types';
import Session from 'supertokens-auth-react/recipe/session';
import { AccessDeniedScreen } from 'supertokens-auth-react/recipe/session/prebuiltui';

import { anonymousRoutes } from '@/modules/router/anonymousRoutes';
import { PageLoader } from '@/modules/theme/components/PageLoader';

import { authContext } from '../contexts/AuthContext';
import { SignOutPayload } from '../contexts/types';
import { useGetCustomerDataQuery } from '../hooks/useGetCustomerDataQuery';
import { useGetUserDataQuery } from '../hooks/useGetUserDataQuery';

export const lastSignedInLocalStorageKey = 'last-signed-in';

type AuthContentProviderProps = {
  children: ReactNode;
};

type RedirectLocationState = {
  redirectTo: Location;
};

export const AuthContextProvider = ({ children }: AuthContentProviderProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showUnauthorized, setShowUnauthorized] = useState(false);
  const signInHref = useHref(anonymousRoutes.buildUrl('signIn'));

  const sessionContext = Session.useSessionContext() as LoadedSessionContext;

  const {
    data: userData,
    refetch: getUserData,
    isLoading: isUserDataLoading,
    isError: isUserError,
  } = useGetUserDataQuery();

  const {
    data: customerData,
    refetch: getCustomerData,
    isLoading: isCustomerDataLoading,
  } = useGetCustomerDataQuery({
    customerId: userData?.relationId || undefined,
  });

  useEffect(() => {
    const getAuthData = async () => {
      try {
        await getUserData();
        await getCustomerData();

        if (locationState) {
          // state is any by default
          const { redirectTo } = locationState as RedirectLocationState;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
          setShowUnauthorized(false);
          setIsAuthenticating(false);
        }
      } catch (e) {
        setShowUnauthorized(true);
        setIsAuthenticating(false);
      }
    };

    if (
      sessionContext.doesSessionExist &&
      !sessionContext.loading &&
      !isAuthenticating &&
      !userData
    ) {
      try {
        setIsAuthenticating(true);

        void getAuthData();
      } catch (error) {
        setShowUnauthorized(true);
        setIsAuthenticating(false);
      }
    }
  }, [
    sessionContext.loading,
    sessionContext.doesSessionExist,
    isAuthenticating,
    userData,
    getUserData,
    getCustomerData,
    locationState,
    navigate,
  ]);

  useEffect(() => {
    setShowUnauthorized(isUserError);
  }, [isUserError]);

  const signOut = useCallback(
    (options: SignOutPayload = { hardRedirect: true }) => {
      localStorage.clear();
      sessionStorage.clear();
      queryClient.clear();
      if (options.hardRedirect) {
        window.location.href = signInHref;
      }
    },
    [queryClient, signInHref]
  );

  if (showUnauthorized) {
    return <AccessDeniedScreen>{children}</AccessDeniedScreen>;
  }

  if (userData && customerData) {
    return (
      <authContext.Provider
        value={{
          user: userData || null,
          customer: customerData || null,
          isAuthenticating,
          signOut,
        }}
      >
        {children}
      </authContext.Provider>
    );
  }

  if (isAuthenticating || isUserDataLoading || isCustomerDataLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};
