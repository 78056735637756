import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { queryKeys } from '@/modules/api/queryKeys';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { useUpdateNegotiationMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationMutationV2';
import {
  NegotiationStateV2,
  NegotiationType,
} from '@/modules/negotiation/types/negotiationTypes';
import { calculateNegotiationMetrics } from '@/utils/calculate-negotiation-metrics';
import { useDialogState } from '@/utils/useDialogState';

import { useGetNegotiationsQueryV2 } from '../../../../hooks/useGetNegotiationsV2Query';
import { periodNotSelectedState } from '../../../NegotiationsDnD/constants';
import {
  NegotiationsDnD,
  UpdatePeriodIndexParams,
} from '../../../NegotiationsDnD/NegotiationsDnD';
import { SelectedSuppliersDialog } from '../../../SelectedSuppliersDialog/SelectedSuppliersDialog';
import { DnDNegotiation } from '../../../types';

export const SuggestedNegotiationsDnD = () => {
  const {
    customer: { id: customerId },
  } = useSessionContext();
  const queryClient = useQueryClient();
  // We need local copy of negotations,
  // because we need to mutate it later on drag events
  const [items, setItems] = useState<DnDNegotiation[]>([]);

  const updateNegotiationMutation = useUpdateNegotiationMutationV2();
  const selectedSuppliersDialogState = useDialogState();
  const [selectedNegotiation, setSelectedNegotiation] =
    useState<DnDNegotiation>();

  const {
    data: negotiations,
    isPending,
    isRefetching,
  } = useGetNegotiationsQueryV2({
    page: 1,
    limit: 100,
    customerId,
    states: [
      NegotiationStateV2.SUGGESTED,
      NegotiationStateV2.DEMO,
      NegotiationStateV2.IN_SETUP,
      NegotiationStateV2.TO_BE_APPROVED,
    ],
  });

  const updatePeriodIndex = ({
    negotiationId,
    destinationIndex,
    periodOrder,
    originIndex,
  }: UpdatePeriodIndexParams) => {
    updateNegotiationMutation.mutate(
      {
        id: negotiationId,
        attributes: {
          suggestedPeriodIndex: periodOrder,
          suggestedPeriod: destinationIndex,
        },
      },
      {
        onSuccess: async () => {
          // we should refetch metrics only when card was selected
          // or removed from periods. If it switches periods
          // it's not necessary to refetch them
          if (
            periodNotSelectedState.some(
              (state) =>
                state && [destinationIndex, originIndex].includes(state)
            )
          ) {
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getSuggestedNegotiationMetricsV2,
              ],
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (isPending) return;

    if (negotiations) {
      const dndNegotiations: DnDNegotiation[] = negotiations.data.items.map(
        (negotiation) => {
          const { attributes } = negotiation;
          const {
            name,
            type,
            negotiationSuppliers,
            suggestedPeriod,
            suggestedPeriodIndex,
            createdAt,
            totalSpend,
          } = attributes;

          const metrics = calculateNegotiationMetrics(negotiation);

          return {
            id: negotiation.id,
            title: name,
            negotiationTypes: type as NegotiationType[],
            totalSpend: totalSpend,
            metrics,
            periodIndex: suggestedPeriod ?? -1,
            periodOrder: suggestedPeriodIndex ?? -1,
            negotiationSupplierIds: negotiationSuppliers?.map(
              (supplier) => supplier.id
            ),
            createdAt: createdAt,
          };
        }
      );
      setItems(dndNegotiations || []);
    }
  }, [negotiations, isPending]);

  const handleDialogClose = () => {
    selectedSuppliersDialogState.close();
    setSelectedNegotiation(undefined);
  };

  const handleNegotiationCardClick = (negotiation: DnDNegotiation) => {
    setSelectedNegotiation(negotiation);
    selectedSuppliersDialogState.open();
  };

  return (
    <>
      <NegotiationsDnD
        items={items}
        updatePeriodIndex={updatePeriodIndex}
        setItems={setItems}
        isRefetching={isRefetching}
        isLoading={isPending}
        onNegotiationCardClick={handleNegotiationCardClick}
      />
      {selectedNegotiation && (
        <SelectedSuppliersDialog
          isOpen={selectedSuppliersDialogState.isOpen}
          onClose={handleDialogClose}
          negotiationId={selectedNegotiation.id}
        />
      )}
    </>
  );
};
