import * as sup from 'superstruct';

import { launchedNegotiationStateFilters } from '@/modules/negotiation/utils/consts';
import {
  Order,
  SupplierTableColumn,
} from '@/modules/suppliers/types/supplierTypes';
import { intQuery } from '@/utils/structs/structs';

import { createRouting, defineRoute, nestRoute } from './config/createRouting';
import {
  negotiationWithProgressSuppliersQueryStruct,
  queryParamsSetStruct,
  suppliersQueryParamsStruct,
} from './utils/structs';

export const onboardedRoutes = createRouting({
  dashboard: defineRoute({
    path: '/dashboard' as const,
    queryStruct: sup.object({
      state: sup.optional(
        queryParamsSetStruct(sup.enums(launchedNegotiationStateFilters))
      ),
    }),
  }),
  profile: defineRoute({
    path: '/profile' as const,
  }),
  support: defineRoute({
    path: '/support' as const,
  }),
  supplier: defineRoute({
    path: '/suppliers/:supplierId' as const,
  }),
  suppliers: defineRoute({
    path: '/suppliers' as const,
    queryStruct: sup.object({
      page: sup.size(intQuery, 1, 100),
      size: sup.size(intQuery, 1, 100),
      sortBy: sup.optional(sup.enums(Object.values(SupplierTableColumn))),
      order: sup.optional(sup.enums(Object.values(Order))),
    }),
  }),
  addSupplier: defineRoute({
    path: '/suppliers/add' as const,
  }),
  editSupplier: defineRoute({
    path: '/suppliers/:supplierId/edit' as const,
  }),
  negotiationSetup: defineRoute({
    path: '/negotiation-setup/:negotiationId' as const,
  }),
  plannedNegotiation: defineRoute({
    path: '/planned-negotiation/:negotiationId' as const,
  }),
  activeNegotiation: defineRoute({
    path: '/active-negotiation/:negotiationId' as const,
    queryStruct: negotiationWithProgressSuppliersQueryStruct,
  }),
  completedNegotiation: defineRoute({
    path: '/completed-negotiation/:negotiationId' as const,
    queryStruct: negotiationWithProgressSuppliersQueryStruct,
  }),
  cpoLetter: defineRoute({
    path: '/cpo-letter/:negotiationId' as const,
  }),
});

export const setupNegotiationRoutes = createRouting({
  startDate: nestRoute(
    onboardedRoutes.config.negotiationSetup,
    defineRoute({
      path: 'start-date' as const,
    })
  ),
  invitationLetter: nestRoute(
    onboardedRoutes.config.negotiationSetup,
    defineRoute({
      path: 'invitation-letter' as const,
    })
  ),
  terms: nestRoute(
    onboardedRoutes.config.negotiationSetup,
    defineRoute({
      path: 'terms' as const,
    })
  ),
  suppliers: nestRoute(
    onboardedRoutes.config.negotiationSetup,
    defineRoute({
      path: 'suppliers' as const,
      queryStruct: suppliersQueryParamsStruct,
    })
  ),
});

export const plannedNegotiationRoutes = createRouting({
  suppliers: nestRoute(
    onboardedRoutes.config.plannedNegotiation,
    defineRoute({
      path: 'suppliers' as const,
      queryStruct: suppliersQueryParamsStruct,
    })
  ),
  terms: nestRoute(
    onboardedRoutes.config.plannedNegotiation,
    defineRoute({
      path: 'terms' as const,
    })
  ),
});
