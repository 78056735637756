import styled from 'styled-components';

import { SkeletonLoader } from '@/modules/theme/components/SkeletonLoader/SkeletonLoader';
import { Heading } from '@/modules/theme/components/Typography';

export const ProgressOfNegotiationSkeleton = ({ title }: { title: string }) => {
  return (
    <>
      <Heading variant="h3">{title}</Heading>
      <WrapperStyled>
        <SkeletonLoaderStyled height={300} />
      </WrapperStyled>
    </>
  );
};

const SkeletonLoaderStyled = styled(SkeletonLoader)({
  marginBottom: 0,
});

const WrapperStyled = styled('div')(({ theme: { spacing } }) => ({
  width: '100%',
  margin: spacing([4, 0, 7]),
}));
