import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationMetric } from '@/modules/negotiation/components/NegotiationMetric';
import { negotiationMetrics } from '@/modules/negotiation/metrics/consts';
import { getNegotiationCardMetricsV2 } from '@/pages/DashboardPage/components/NegotiationCards/NegotiationCard/getNegotiationCardMetricsV2';

type ActiveNegotiationMetricsProps = {
  negotiation: NegotiationResourceTypeV2;
};

export const ActiveNegotiationMetrics = ({
  negotiation,
}: ActiveNegotiationMetricsProps) => {
  const { t } = useTranslation('pages/ActiveNegotiationPage');
  const { customer } = useSessionContext();

  const metrics = negotiation.attributes.negotiationMetrics;

  const metricsToDisplay = getNegotiationCardMetricsV2(negotiation);

  if (!metrics) {
    return null;
  }

  return (
    <ActiveNegotiationMetricsWrapperStyled>
      <FinancialMetricsWrapperStyled>
        {metricsToDisplay.has(negotiationMetrics.negotiatedSavings) && (
          <NegotiationMetric
            heading={t('Negotiated savings ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.savedAmountTotal || 0}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedCostAvoidance) && (
          <NegotiationMetric
            heading={t('Negotiated cost avoidance ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.savedByLockedPrices || 0}
          />
        )}

        {metricsToDisplay.has(negotiationMetrics.negotiatedSpend) && (
          <NegotiationMetric
            heading={t('Negotiated spend ({{currency}})', {
              currency: customer.currency,
            })}
            data={metrics.negotiatedSpend || 0}
          />
        )}
      </FinancialMetricsWrapperStyled>
      <CounterMetricsWrapper>
        {metricsToDisplay.has(negotiationMetrics.discountCount) && (
          <NegotiationMetric
            heading={t('Discounts')}
            data={metrics.counts.discount}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.lockPrice
        ) && (
          <NegotiationMetric
            heading={t('Locked prices')}
            data={metrics.counts.lockedPrice}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.signCodeOfConduct
        ) && (
          <NegotiationMetric
            heading={t('Accepted Code of Conduct')}
            data={metrics.counts.acceptedCoc}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.answerSustainabilityQuestion
        ) && (
          <NegotiationMetric
            heading={t('Answered questionnaires')}
            data={metrics.counts.answeredQuestionnaire}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendInvoiceDueDate
        ) && (
          <NegotiationMetric
            heading={t('Accepted payment days')}
            data={metrics.counts.acceptedExtraPaymentDays}
            variant="counter"
          />
        )}

        {metricsToDisplay.has(
          negotiationMetrics.acceptedTermCounts.extendContractPeriod
        ) && (
          <NegotiationMetric
            heading={t('Accepted prolongation')}
            data={metrics.counts.acceptedExtraPaymentDays}
            variant="counter"
          />
        )}
      </CounterMetricsWrapper>
    </ActiveNegotiationMetricsWrapperStyled>
  );
};

const ActiveNegotiationMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    flexGrow: 1,
    display: 'flex',
    gap: spacing(11),
    minWidth: 250,
  })
);

const CounterMetricsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 0.3fr))',
  gridAutoRows: 94,
  columnGap: spacing(2),
  flex: 1,
}));

const FinancialMetricsWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    minWidth: 240,
    '&:empty': {
      minWidth: 0,
      marginRight: spacing(-11),
    },
  })
);
