import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useJsonApiV2 } from '@/modules/api/hooks/useJsonApiV2';
import { queryKeys } from '@/modules/api/queryKeys';
import { getNegotiationsQueryV2 } from '@/modules/negotiation/api/queries/getNegotiationsQueryV2';
import { GetNegotiationsQueryOptions } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceConsts';
import { NegotiationStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { constructMeta } from '@/modules/reactQuery/constructMeta';

export type GetNegotiationsQueryParamsV2 = {
  page: number;
  limit: number;
  customerId: string;
  states?: NegotiationStateV2[];
  includeNegotiationMetrics?: boolean;
};

export const useGetNegotiationsQueryV2 = (
  params: GetNegotiationsQueryParamsV2,
  options?: GetNegotiationsQueryOptions
) => {
  const jsonApi = useJsonApiV2();
  const { t } = useTranslation('apiMessages/useGetNegotiationsQuery');
  return useQuery({
    queryKey: [
      queryKeys.negotiations.getNegotiationsV2,
      params.page,
      params.limit,
      params.states,
    ],
    queryFn: getNegotiationsQueryV2(jsonApi, params),
    ...options,
    meta: constructMeta({
      errorMessages: {
        401: {
          UNAUTHENTICATED: t('Your session has expired. Please sign in'),
        },
        403: {
          FORBIDDEN: t("You don't have access to view negotiations"),
        },
      },
    }),
  });
};
