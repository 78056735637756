import * as sup from 'superstruct';

export const customerResourceStruct = sup.object({
  id: sup.string(),
  type: sup.literal('CUSTOMER'),
  attributes: sup.object({
    name: sup.string(),
    currency: sup.string(),
    createdAt: sup.string(),
    imageUrl: sup.optional(sup.string()),
    wpId: sup.optional(sup.string()),
    wpRegistrationLink: sup.optional(sup.string()),
  }),
});
