import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NegotiationResourceTypeV2 } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Paragraph } from '@/modules/theme/components/Typography';

import { AddSupplierForm } from '../AddSupplierForm/AddSupplierForm';

import {
  CloseButtonStyled,
  ContentWrapperStyled,
  DialogStyled,
  HeadingStyled,
} from './AddSupplierDialog.styled';

type AddSupplierDialogProps = {
  negotiation: NegotiationResourceTypeV2;
  isOpen: boolean;
  onClose: () => void;
};

export const AddSupplierDialog = ({
  isOpen,
  negotiation,
  onClose,
}: AddSupplierDialogProps) => {
  const { t } = useTranslation('pages/SuppliersTab');

  const suppliers = useMemo(() => {
    return (
      negotiation.attributes.negotiationSuppliers?.filter(
        (supplier) =>
          supplier.attributes.negotiationSupplierState ===
            NegotiationSupplierStateV2.EXCLUDED ||
          supplier.attributes.negotiationSupplierState ===
            NegotiationSupplierStateV2.REMOVED
      ) || []
    );
  }, [negotiation]);

  if (!suppliers.length)
    return (
      <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
        <HeadingStyled variant="h2">{t('Add supplier')}</HeadingStyled>
        <ContentWrapperStyled direction="column">
          <>
            <Paragraph alignment="center">
              {t('All suppliers have already been selected')}
            </Paragraph>
            <CloseButtonStyled variant={'outline'} onClick={onClose}>
              {t('Close')}
            </CloseButtonStyled>
          </>
        </ContentWrapperStyled>
      </DialogStyled>
    );

  return (
    <DialogStyled isOpen={isOpen} hasCloseIcon onClose={onClose}>
      <HeadingStyled variant="h2">{t('Add supplier')}</HeadingStyled>
      <ContentWrapperStyled direction="column">
        <AddSupplierForm
          suppliers={suppliers}
          negotiationId={negotiation.id}
          onClose={onClose}
        />
      </ContentWrapperStyled>
    </DialogStyled>
  );
};
