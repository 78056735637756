import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import * as sup from 'superstruct';

import { queryKeys } from '@/modules/api/queryKeys';
import { useSessionContext } from '@/modules/auth/hooks/useSessionContext';
import { Checkbox } from '@/modules/form/components/Checkbox/Checkbox';
import { Form } from '@/modules/form/components/Form/Form';
import { NegotiationSupplier } from '@/modules/negotiation/api/resources/negotiation/negotiationResourceStructV2';
import { useUpdateNegotiationSupplierMutationV2 } from '@/modules/negotiation/hooks/useUpdateNegotiationSupplierMutationV2';
import { NegotiationSupplierStateV2 } from '@/modules/negotiation/types/negotiationTypes';
import { Button } from '@/modules/theme/components/Button/Button';
import { Scrollbar } from '@/modules/theme/components/Scrollbar';
import { Table } from '@/modules/theme/components/Table/Table';
import { TableCell } from '@/modules/theme/components/Table/TableCell';
import { TableRow } from '@/modules/theme/components/Table/TableRow';
import { Text } from '@/modules/theme/components/Typography';
import { formatAmount } from '@/utils/stringUtils';

type AddSupplierFormProps = {
  suppliers: NegotiationSupplier[];
  onClose: () => void;
  negotiationId: string;
};

const addSuppliersStruct = sup.object({
  selectedSuppliers: sup.array(sup.string()),
});

type AddSuppliersStructValues = sup.Infer<typeof addSuppliersStruct>;

export const AddSupplierForm = ({
  suppliers,
  onClose,
  negotiationId,
}: AddSupplierFormProps) => {
  const { t } = useTranslation('pages/SuppliersTab');
  const queryClient = useQueryClient();
  const {
    customer: { currency },
  } = useSessionContext();

  const nextPageSentinel = useInView();

  const { mutate: updateNegotiationSupplier, isPending } =
    useUpdateNegotiationSupplierMutationV2();

  const addSuppliers = ({ selectedSuppliers }: AddSuppliersStructValues) => {
    selectedSuppliers.forEach((supplierId) => {
      updateNegotiationSupplier(
        {
          negotiationId,
          negotiationSupplierId: supplierId,
          value: {
            negotiationSupplierState: NegotiationSupplierStateV2.SELECTED,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [
                queryKeys.negotiations.getNegotiationV2,
                negotiationId,
              ],
            });
            onClose();
          },
        }
      );
    });
  };

  const headers = {
    company: t('Company'),
    category: t('Category'),
    spend: t('Spend'),
    selected: t('Selected'),
  } as const;

  return (
    <Form
      defaultValues={{
        selectedSuppliers: [],
      }}
      struct={addSuppliersStruct}
      onValidSubmit={addSuppliers}
    >
      {({ register, watch }) => {
        const selectedSuppliers = watch('selectedSuppliers');

        return (
          <>
            <FormContentWrapperStyled>
              <Scrollbar>
                <TableStyled headers={Object.values(headers)}>
                  {suppliers.map((supplier) => (
                    <TableRow
                      key={supplier.id}
                      variant={
                        selectedSuppliers.includes(supplier.id)
                          ? 'default'
                          : 'transparent'
                      }
                    >
                      <TableCell mobileHeader={headers.company}>
                        <Text bold>
                          {supplier.attributes.freezedSupplierName ||
                            supplier.attributes.supplier?.name}
                        </Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.category}>
                        <Text>{supplier.attributes.supplier?.category}</Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.spend}>
                        <Text>
                          {`${formatAmount(
                            (supplier.attributes.freezedSupplierSpend ||
                              supplier.attributes.supplier?.spend) ??
                              0
                          )} ${currency}`}
                        </Text>
                      </TableCell>
                      <TableCell mobileHeader={headers.selected}>
                        <Checkbox
                          id={supplier.id}
                          value={supplier.id}
                          {...register('selectedSuppliers')}
                          hideError
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableStyled>
                <div ref={nextPageSentinel.ref} />
              </Scrollbar>
            </FormContentWrapperStyled>

            <ConfirmButtonStyled type="submit" loading={isPending}>
              {t('Confirm')}
            </ConfirmButtonStyled>
          </>
        );
      }}
    </Form>
  );
};

export const FormContentWrapperStyled = styled('div')(
  ({ theme: { spacing } }) => ({
    height: spacing(50),
  })
);

export const TableStyled = styled(Table)({
  gridTemplateColumns: 'repeat(3, 1fr) 0.4fr',
});

export const ConfirmButtonStyled = styled(Button)(({ theme: { spacing } }) => ({
  display: 'block',
  margin: `${spacing(7)} auto 0`,
}));
